<template>
  <div class="block" style="padding: 0 10%;">
    <div class="container" v-if="product">
      <section class="section mt-6">
        <div class="columns is-desktop has-text-primary">
          <div class="column">
            <img 
              :alt="product.category_name" 
              :src="product.image" />
          </div>
          <div class="column is-three-fifths ml-5 pl-5">
            <h5 class="is-size-5 mt-3 pt-5">
                <b>
                    {{ product.name }}<br />
                    ₦{{ formatNumber(product.unit_price) }}
                </b>
            </h5>
            <p class="my-4 has-text-black">{{ product.description }}</p>

            <div class="columns my-2">
              <div class="column">
                <b-button class="is-primary" @click="reduceQty">-</b-button>
                <b-button class="no-border">{{ qty }}</b-button>
                <b-button class="is-primary" @click="increaseQty">+</b-button>
              </div>
              <div class="column">
                <b-button rounded class="is-primary" @click="addToCart"><b>Add to Cart</b></b-button>
              </div>
              <div class="column" v-if="canCheckOut">
                <b-button rounded class="is-primary" tag="router-link" :to="{ path: '/shop/cart' }" ><b>Cart/Checkout</b></b-button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="section mt-6 has-text-primary">
        <RelatedPublications :productId="`${this.$route.params.item_id}`" />
      </section>
    </div>
  </div>
</template>

<script>
import RelatedPublications from './RelatedPublications'
import { mapActions } from "vuex";
export default {
  name: "OneItem",
  components: {
    RelatedPublications,
  },
  data() {
    return {
      product: null,
      qty: 1,
      ejsc_shop: null,
      canCheckOut: false,
    }
  },
  methods: {
    ...mapActions(['TotalCartItems']),
    increaseQty(){
      this.qty++;
    }, //end of increaseQty
    reduceQty(){
      if (this.qty > 1) {
        this.qty--;
        // this.canCheckOut = (!this.ejsc_shop) ? false : true;
      }
    }, //end of reduceQty
    addToCart(){
      this.product.quantity = this.qty;
      this.product.amount = this.product.quantity * this.product.unit_price;
      const cart = this.ejsc_shop;
      if (cart) {
        cart.push(this.product);
        localStorage.setItem("ejsc_shop", JSON.stringify(cart));
      } else {
        const tempCart = [];
        tempCart.push(this.product);
        localStorage.setItem("ejsc_shop", JSON.stringify(tempCart));
      }
      this.canCheckOut = true;
      this.TotalCartItems(JSON.parse(localStorage.getItem("ejsc_shop")).length);
    }, //end of addToCart
    formatNumber (num) {
      return `${new Intl.NumberFormat('en-US').format(num)}.00`;
    }, //end of formatNumber
  },
  mounted() {
    try {
      const getProduct = this.$route.params.product;
      if (!getProduct) this.$router.replace({path: "/shop"});
      const getShop = JSON.parse(localStorage.getItem("ejsc_shop"));
      this.ejsc_shop = getShop
      this.canCheckOut = (getShop.length > 0) ? true : false;
      getShop.forEach(prod => {
        if (prod.id === this.$route.params.item_id) {
          this.product = prod;
          this.qty = prod.quantity;
        } else {
          this.product = getProduct;
        }
      });
      if (getShop.length === 0) {
        this.product = this.$route.params.product;
        this.ejsc_shop = [];
      }
    } catch (error) {
      console.log(error.message);
      this.product = this.$route.params.product;
      this.ejsc_shop = [];
    }
    
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .no-border {
    border: none;
    background: none;
  }
</style>
