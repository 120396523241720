<template>
  <div class="block" style="padding: 0 10%;">
    <div class="container">
      <section class="section mt-6">
        <div class="columns is-desktop has-text-primary">
          <div class="column">
            <img alt="Team" src="../../assets/teams/lilian.png" />
          </div>
          <div class="column is-three-fifths ml-5 pl-5">
            <h3 class="is-size-3 bold-text mt-3 pt-5"><b>Nwamaka Lilian Cletus (Anyaegbunam)</b></h3>
            <p class="regular-text has-text-primary mt-5">
              Nwamaka Lilian Cletus (Anyaegbunam) is the Deputy Editor-in-Chief of Erudite Judgments of the Supreme Court, a law publication of Legal Jurisprudence Limited. She graduated from Madonna University where she obtained her LL.B and thereafter proceeded to the Nigerian Law School, Enugu Campus. She has been privileged to work alongside several legal luminaries following her call to the Nigerian Bar.
            </p>
            <p class="regular-text has-text-primary mt-3">
                Her areas of expertise include Family Law and Legal Drafting. Besides being a member of the Nigerian Bar Association, she is also a member of African Women Lawyers Association (Nigeria) AWLA, Ota branch; a member of the Dispute Resolution House and a published author.
            </p>
            <p class="regular-text has-text-primary mt-3">
              Amaka is happily married and blessed with two lovely children.
            </p>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "TeamInfo",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  @font-face {
    font-family: "MontBold";
    src: url("../../fonts/Mont/Mont-Bold.otf");
  }

  @font-face {
    font-family: "MontRegular";
    src: url("../../fonts/Mont/Mont-Regular.otf");
  }

  .bold-text {
    font-family: "MontBold";
  }

  .regular-text {
    font-family: "MontRegular";
  }
</style>
