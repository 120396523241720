<template>
  <div class="block" style="">
    <div class="container">
      <section class="section">
        <div class="columns is-desktop has-text-primary">
          <div class="column">
            <img alt="Lawyer" src="../../../assets/whoweare.png" width="280">
          </div>
          <div class="column mt-5 pr-6">
            <h3 class="is-size-4 bold-text has-text-info">Who We Are</h3>
            <p class="regular-text has-text-info has-text-left mt-5">
              Published by Legal Jurisprudence Limited emerged under the editorship of Isaac Ogbah, FICMC, in January 2015. Our mission is to provide the legal community with up to date judgments of the Supreme Court and to support legal research and study needs of students, law faculties and staff of various Nigerian Universities and Law Schools as well as other professional bodies.
            </p>
            <p class="regular-text has-text-info has-text-left mt-6">
              EJSC is a qualitative and timely publication with skilled and permanent staff in all sectors 
              of its reporting, editorial, production and distribution. Our mission is to promote the 
              doctrine of precedents and the rule of law for legal practitioners, scholars and laymen alike. 
              The report is sorted by volume, and with the passion and interest of the Editor-in-Chief for 
              careful planning towards sustainability; the number of volumes has greatly increased overtime 
              since the inception of the report in January 2015
            </p>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "WhoWeAre1",
  
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  @font-face {
    font-family: "MontBold";
    src: url("../../../fonts/Mont/Mont-Bold.otf");
  }

  @font-face {
    font-family: "MontRegular";
    src: url("../../../fonts/Mont/Mont-Regular.otf");
  }

  .bold-text {
    font-family: "MontBold";
  }

  .regular-text {
    font-family: "MontRegular";
  }

  .block {
    /*background-image: url("../../assets/erudite_bg.png");*/
  }
</style>
