<template>
  <div class="form-input-holder">
    <h3 class="is-size-3 has-text-primary heavy-text"><b>Welcome Back!</b></h3>
    <p class="content has-text-primary mt-5">
      Enter your credentials associated with EJSC to gain access
    </p>
    <div class="field mt-6">
      <label class="label your-email-label has-text-primary">Email</label>
      <div class="control">
        <input class="input is-rounded" type="text" v-model="form.email" />
      </div>
    </div>

    <div class="field mt-5">
      <b-field label="Password" custom-class="has-text-primary">
        <b-input type="password"
          rounded
          custom-class="input"
          v-model="form.password"
          password-reveal>
        </b-input>
      </b-field>
    </div>

    <div class="has-text-right has-text-primary">
      <router-link to="/forgot-password" class="has-text-primary"
        >Forgot Password?</router-link
      >
    </div>

    <b-button
      type="is-primary"
      size="is-medium"
      class="is-rounded mt-6 login-button"
      expanded
      @click="checkUp"
      >Submit</b-button
    >

    <p class="mt-6 pt-3 has-text-centered">
      Don't have account?
      <b><a class="has-text-primary" href="#/signup">Sign up</a></b>
    </p>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions } from "vuex";

export default {
  name: "LoginForm",
  data() {
    return {
      form: {
        email: null,
        password: null,
      },
      showError: false,
      isLoading: false,
      notification: {
        show: false,
        title: null,
        message: null,
        type: "info",
        loading: false,
      },
      setToast: null,
      showPasswordIcon: "fa-eye",
      passwordType: "password",
    };
  },
  methods: {
    ...mapActions(['LogIn', 'setupLoading']),
    checkUp(){
      this.form.email = this.form.email.trim();
      if (!this.form.email && !this.form.password) {
        this.closeToast();
        this.setToast = this.$buefy.toast.open({
          message: "Email and Password are both required",
          type: "is-warning",
          position: "is-bottom",
          duration: 3000,
        });
        return false;
      } else if (!this.form.email) {
        this.closeToast();
        this.setToast = this.$buefy.toast.open({
          message: "Email is required",
          type: "is-warning",
          position: "is-bottom",
          duration: 3000,
        });
        return false;
      } else if (!this.isValidEmail()) {
        this.closeToast();
        this.setToast = this.$buefy.toast.open({
          message: "Email address is not valid!<br />e.g: samplemail@mail.com",
          type: "is-warning",
          position: "is-bottom",
          duration: 3000,
        });
        return false
      } else if (!this.form.password) {
        this.closeToast();
        this.setToast = this.$buefy.toast.open({
          message: "Password is required!",
          type: "is-warning",
          position: "is-bottom",
          duration: 3000,
        });
        return false
      }
      this.submit();
    }, //end of checkUp
    isValidEmail(){
      const emailRegex = /^([\w-.]+@([\w-]+\.)+[\w-]{2,4})?$/;
      return emailRegex.test(this.form.email);
    }, //end of isValidEmail
    submit() {
      this.setupLoading(true);
      this.closeToast();
      this.setToast = this.$buefy.toast.open({
        message: "Authenticating user please wait . . . ",
        type: "is-success",
        position: "is-bottom",
      });

      axios
        .post("api/v_1/login", this.form)
        .then((response) => {
          this.setupLoading(false);
          this.closeToast();
          this.setToast = this.$buefy.toast.open({
            message: `Login Successful!`,
            type: "is-success",
            position: "is-bottom",
            duration: 3000,
          });
          this.LogIn({
            token: response.data.success.token,
            web_token: response.data.success.web_token
          });
          this.$router.push("/home");
        })
        .catch((err) => {
          this.setupLoading(false);
          if (err.response.status == "401") {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: "Action failed, authorization required . . . ",
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
            this.manageLogout();
          }
          if (err.response) {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: err.response.data.error.message,
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
          } else if (err.request) {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: JSON.stringify(err.request.data),
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
          } else {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: err.message,
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
          }
        });
    }, //end of submit
    manageLogout(){
      this.$store.dispatch("LogOut");
      this.$router.push("/login");
    }, //manageLogout
    closeToast(){
      if (this.setToast) {
        this.setToast.close();
        this.setToast = null;
      }
    }, //end of closeToast
    passwordVisibility(){
      if (this.showPasswordIcon.includes("slash")) {
        this.showPasswordIcon = "fa-eye";
        this.passwordType = "password";
      } else {
        this.showPasswordIcon = "fa-eye-slash";
        this.passwordType = "text";
      }
    }, //end of passwordVisibility
  },
  mounted() {
    if (this.$store.getters.isAuthenticated)
      this.$router.push({ name: "AuthHome" });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.form-input-holder {
  margin: 0 59px;
}

input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(13, 35, 51, 0.69);
  opacity: 1; /* Firefox */
}
input {
  background: rgba(196, 196, 196, 0.17);
  border: none;
}
.login-button:hover {
  filter: drop-shadow(0 0 10px #003b56);
}
.to-hover {
  cursor: pointer !important;
}
</style>
