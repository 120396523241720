var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.getViewPort() >= 1000)?_c('div',{staticClass:"block is-hidden-touch",staticStyle:{"padding":"0 10%","margin-bottom":"-2%"}},[_c('div',{staticClass:"container has-text-white"},[_c('section',{staticClass:"section"},[(true === false)?_c('div',{staticClass:"control has-icons-right mx-6 px-6"},[_c('input',{staticClass:"input is-primary has-text-primary is-rounded mx-6",attrs:{"type":"text","placeholder":"Search Books"}}),_vm._m(0)]):_vm._e(),_c('div',{staticClass:"columns my-6"},[_c('div',{staticClass:"column is-4"},[_vm._m(1),_c('p',{staticClass:"has-text-primary is-clickable regular-text pt-1",on:{"click":function($event){return _vm.categorizeProduct('all')}}},[_vm._v(" All Books ")]),_vm._l((_vm.categories),function(category,index){return _c('p',{key:index,staticClass:"has-text-primary is-clickable py-2 regular-text",on:{"click":function($event){return _vm.categorizeProduct(category.id)}}},[_vm._v(" "+_vm._s(category.name)+" ("+_vm._s(category.product_counts)+") ")])})],2),_c('div',{staticClass:"column is-8"},[_c('div',{staticClass:"columns"},_vm._l((_vm.productsA),function(product){return _c('div',{key:product.id,staticClass:"column has-text-left mx-3"},[_c('router-link',{attrs:{"to":{
                    name: 'OneItem',
                    params: {
                      product: product,
                      item_name: product.name,
                      item_id: product.id,
                    },
                  }}},[_c('figure',{staticClass:"image"},[_c('img',{attrs:{"src":product.image,"alt":product.category_name}})]),_c('p',{staticClass:"has-text-left has-text-primary mt-3"},[_c('span',{staticClass:"bold-text"},[_vm._v(_vm._s(product.name))]),_c('br'),_c('span',{staticClass:"heavy-text is-size-4"},[_vm._v("₦"+_vm._s(_vm.formatNumber(product.unit_price)))])])])],1)}),0)])]),_c('div',{staticClass:"columns my-6"},[_c('div',{staticClass:"column is-4"}),_c('div',{staticClass:"column is-8"},[_c('div',{staticClass:"columns"},_vm._l((_vm.productsB),function(product){return _c('div',{key:product.id,staticClass:"column has-text-left mx-3"},[_c('router-link',{attrs:{"to":{
                    name: 'OneItem',
                    params: {
                      product: product,
                      item_name: product.name,
                      item_id: product.id,
                    },
                  }}},[_c('figure',{staticClass:"image"},[_c('img',{attrs:{"src":product.image,"alt":product.category_name}})]),_c('p',{staticClass:"has-text-left has-text-primary mt-3"},[_c('span',{staticClass:"bold-text"},[_vm._v(_vm._s(product.name))]),_c('br'),_c('span',{staticClass:"heavy-text is-size-4"},[_vm._v("₦"+_vm._s(_vm.formatNumber(product.unit_price)))])])])],1)}),0)])]),_c('div',{staticClass:"columns my-6"},[_c('div',{staticClass:"column is-4"}),_c('div',{staticClass:"column is-8"},[_c('div',{staticClass:"columns"},_vm._l((_vm.productsC),function(product){return _c('div',{key:product.id,staticClass:"column has-text-left mx-3"},[_c('router-link',{attrs:{"to":{
                    name: 'OneItem',
                    params: {
                      product: product,
                      item_name: product.name,
                      item_id: product.id,
                    },
                  }}},[_c('figure',{staticClass:"image"},[_c('img',{attrs:{"src":product.image,"alt":product.category_name}})]),_c('p',{staticClass:"has-text-left has-text-primary mt-3"},[_c('span',{staticClass:"bold-text"},[_vm._v(_vm._s(product.name))]),_c('br'),_c('span',{staticClass:"heavy-text is-size-4"},[_vm._v("₦"+_vm._s(_vm.formatNumber(product.unit_price)))])])])],1)}),0)])]),_c('div',{staticClass:"columns my-6"},[_c('div',{staticClass:"column is-4"}),_c('div',{staticClass:"column is-8"},[_c('div',{staticClass:"columns"},_vm._l((_vm.productsD),function(product){return _c('div',{key:product.id,staticClass:"column has-text-left mx-3"},[_c('router-link',{attrs:{"to":{
                    name: 'OneItem',
                    params: {
                      product: product,
                      item_name: product.name,
                      item_id: product.id,
                    },
                  }}},[_c('figure',{staticClass:"image"},[_c('img',{attrs:{"src":product.image,"alt":product.category_name}})]),_c('p',{staticClass:"has-text-left has-text-primary mt-3"},[_c('span',{staticClass:"bold-text"},[_vm._v(_vm._s(product.name))]),_c('br'),_c('span',{staticClass:"heavy-text is-size-4"},[_vm._v("₦"+_vm._s(_vm.formatNumber(product.unit_price)))])])])],1)}),0)])]),_c('div',{staticClass:"columns my-6 py-6"},[_c('div',{staticClass:"column is-4"}),_c('div',{staticClass:"column is-8 mx-6 px-6"},[_c('div',{staticClass:"buttons mx-6 px-6"},[_c('button',{class:("button is-primary is-inverted mx-1 " + (_vm.presentPage == 1 ? 'btn-disabled' : '')),attrs:{"disabled":_vm.presentPage == 1 ? true : false},on:{"click":function($event){return _vm.managePagination(_vm.presentPage - 1)}}},[_vm._m(2),_c('span',[_vm._v("Prev")])]),_vm._l((_vm.totalPages),function(nums){return _c('button',{class:("button is-primary is-inverted " + (nums == _vm.presentPage ? 'btn-current-page' : '')),on:{"click":function($event){return _vm.managePagination(nums)}}},[_vm._v(" "+_vm._s(nums)+" ")])}),_c('button',{class:("button is-primary is-inverted mx-1 " + (_vm.presentPage == _vm.totalPages ? 'btn-disabled' : '')),attrs:{"disabled":_vm.presentPage == _vm.totalPages ? true : false},on:{"click":function($event){return _vm.managePagination(_vm.presentPage + 1)}}},[_c('span',[_vm._v("Next")]),_vm._m(3)])],2)])])])])]):_c('div',{staticClass:"is-hidden-desktop"},[_c('AllItemsMobile')],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"icon is-right"},[_c('i',{staticClass:"fas fa-search has-text-primary pr-6"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h5',{staticClass:"has-text-info is-size-5 bold-text"},[_c('span',{staticClass:"has-text-left"},[_vm._v(" Category ")]),_c('span',{staticClass:"has-text-right "},[_c('i',{staticClass:"ml-6 pl-6 fas fa-angle-down"})])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"icon"},[_c('i',{staticClass:"fas fa-arrow-left"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"icon"},[_c('i',{staticClass:"fas fa-arrow-right"})])}]

export { render, staticRenderFns }