<template>
  <div>
    <div class="is-hidden-touch" v-if="getViewPort() >= 1000">
      <div class="container my-6 mx-6">
        <Header />
        <b-progress
          v-if="!isPageReady"
          type="is-primary"
          class="mt-4"
        ></b-progress>
        <div class="columns has-text-primary" v-if="isPageReady">
          <div class="column">
            <div class="mb-5 bold-text is-size-6">Selected Payment Option</div>

            <div class="card" style="border-radius: 20px;">
              <div class="card-content">
                <div class="content has-text-centered py-5">
                  <div
                    class="card has-text-white m-6"
                    style="margin: 0 21% !important; border-radius: 20px;"
                  >
                    <div class="card-content has-background-primary">
                      <div>
                        <p class="regular-text">
                          {{
                            capitalizeFirstChar(
                              $store.getters.getSettings.plans[plan - 1].name
                            )
                          }}
                          Plan
                        </p>
                        <h1 class="is-size-2 heavy-text has-text-white">
                          <b
                            >₦{{
                              formatNumber(
                                $store.getters.getSettings.plans[plan - 1]
                                  .amount
                              )
                            }}</b
                          >
                        </h1>
                      </div>
                    </div>
                  </div>

                  <figure class="my-6 py-6">
                    <img class="image" src="../assets/verified.png" />
                  </figure>
                  <p class="has-text-primary bold-text mb-6 pb-6">
                    Congratulations <br />
                    you have successfully <br />
                    subscribe to the
                    {{
                      capitalizeFirstChar(
                        $store.getters.getSettings.plans[plan - 1].name
                      )
                    }}
                    Plan
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!--<div class="column is-4 mt-6 has-text-primary">
                        <div class="card mt-6">
                            <div class="card-content">
                                <div class="content has-text-centered py-5">
                                    <div class="has-text-left has-text-primary mb-6">
                                        Other Packages
                                    </div>
                                    <div class="card has-text-primary mb-5" style="border: 1px solid #003B56;">
                                        <div class="card-content has-background-white">
                                            <small>Bronze (Monthly Plan)</small>
                                            <h1 class="is-size-2 has-text-primary"><b>₦5,000</b></h1>
                                        </div>
                                    </div>

                                    <div class="card has-text-primary mb-5" style="border: 1px solid #003B56;">
                                        <div class="card-content has-background-white">
                                            <small>Silver (Quarterly Plan)</small>
                                            <h1 class="is-size-2 has-text-primary"><b>₦10,000</b></h1>
                                        </div>
                                    </div>

                                    <div class="card has-text-primary mb-5" style="border: 1px solid #003B56;">
                                        <div class="card-content has-background-white">
                                            <small>Gold (Bi-annually Plan)</small>
                                            <h1 class="is-size-2 has-text-primary"><b>₦5,000</b></h1>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>-->
        </div>
      </div>
    </div>
    <div class="is-hidden-desktop" v-else>
      <ConfirmedPaymentMobile />
    </div>
  </div>
</template>

<script>
import Header from "../components/DashboardComponent/Header";
import ConfirmedPaymentMobile from "../pages_mobile/ConfirmedPayment";
import axios from "axios";
import { mapActions } from "vuex";

export default {
  name: "ConfirmedPayment",
  components: {
    Header,
    ConfirmedPaymentMobile,
  },
  data() {
    return {
      plan: null,
      isPageReady: false,
    };
  },
  methods: {
    ...mapActions(["SetSubscription", "SetSubscriptionStatus", "MakeUser"]),
    getViewPort() {
      return window.innerWidth;
    }, //end of getViewPort
    getProfile() {
      this.closeToast();
      this.setToast = this.$buefy.toast.open({
        message: "Please wait . . . ",
        type: "is-success",
        position: "is-bottom",
        indefinite: false,
      });

      const config = {
        method: "get",
        url: "https://api.ejsconline.com:442/api/v_1/auth/profile",
        headers: {
          Authorization: localStorage.getItem("user_token"),
          Signature: localStorage.getItem("web_token"),
        },
      };
      axios(config)
        .then((res) => {
          this.MakeUser(res.data.success.data);
          const result = res.data.success;
          localStorage.setItem(
            "sub_message",
            `${result.data.subscription.message1}. ${result.data.subscription.message2}`
          );
          this.SetSubscription(
            `${result.data.subscription.message1}. ${result.data.subscription.message2}`
          );
          this.SetSubscriptionStatus(result.data.subscription_status);
          this.isPageReady = true;
        })
        .catch((err) => {
          if (err.response && err.response.status == "401") {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: "Action failed, authorization required . . . ",
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
            this.manageLogout();
          } else if (err.response && err.response.status != "401") {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: err.response.data.error.message,
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
          } else if (err.request) {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: JSON.stringify(err.request.data),
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
          } else {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: err.message,
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
          }
        });
    }, //end of get profile
    manageLogout() {
      this.$store.dispatch("LogOut");
      this.$router.push("/login");
    }, //manageLogout
    closeToast() {
      if (this.setToast) {
        this.setToast.close();
        this.setToast = null;
      }
    }, //end of closeToast
    capitalizeFirstChar(char) {
      return `${char.charAt(0).toUpperCase()}${char.slice(1)}`;
    }, //end of capitalizeFirstChar
    formatNumber(num) {
      return `${new Intl.NumberFormat("en-US").format(num)}.00`;
    }, //end of formatNumber
  },
  mounted() {
    const planId = this.$route.query.plan;
    this.plan = planId;
    if (!this.plan) {
      this.closeToast();
      this.setToast = this.$buefy.toast.open({
        message: "Plan ID is missing . . . ",
        type: "is-danger",
        position: "is-bottom",
        indefinite: false,
      });
      this.$router.push("/home");
    } else {
      this.getProfile();
    }
  },
};
</script>
