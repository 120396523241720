<template>
  <div class="form-input-holder">
    <h3 class="is-size-3 has-text-primary"><b>Welcome Back!</b></h3>
    <p class="content has-text-primary mt-5">
      Enter new password to reset your credentials associated with EJSC to gain access
    </p>
    <div class="field mt-5" v-if="isTokenValid">
      <label class="label your-email-label has-text-primary">Password</label>
      <div class="control">
        <input class="input is-rounded" type="password" v-model="form.password" />
      </div>
      <p class="has-text-danger" v-if="info">{{ info }}</p>
    </div>

    <div class="field mt-5" v-if="isTokenValid">
      <label class="label your-email-label has-text-primary">Confirm Password</label>
      <div class="control">
        <input class="input is-rounded" type="password" v-model="form.confirm_password" />
      </div>
    </div>

    <b-button
      type="is-primary"
      size="is-medium"
      class="is-rounded mt-6 login-button"
      expanded
      v-if="isTokenValid"
      @click="passwordCheck"
      >Submit</b-button
    >
  </div>
</template>

<script>

import axios from "axios";
import { mapActions } from "vuex";

export default {
  name: "UpdatePassword",
  data() {
    return {
      form: {
        token: null,
        password: null,
        confirm_password: null,
      },
      isTokenValid: false,
      info: null,
      message: null,
      canSubmit: false,
      showError: false,
      isLoading: false,
      notification: {
        show: false,
        title: null,
        message: null,
        type: "info",
        loading: false,
      },
      setToast: null,
    }
  },
  methods: {
    ...mapActions(['setupLoading']),
    passwordCheck(){
      if (!this.form.password) {
        this.closeToast();
        this.setToast = this.$buefy.toast.open({
          message: "Password is required!",
          type: "is-warning",
          position: "is-bottom",
          duration: 3000,
        });
        return false
      } else if (!this.form.confirm_password) {
        this.closeToast();
        this.setToast = this.$buefy.toast.open({
          message: "Confirm Password is required!",
          type: "is-warning",
          position: "is-bottom",
          duration: 3000,
        });
        return false
      } else if (this.form.password !== this.form.confirm_password) {
        this.closeToast();
        this.setToast = this.$buefy.toast.open({
          message: "Password is different from confirm password!",
          type: "is-warning",
          position: "is-bottom",
          duration: 3000,
        });
        return false
      }
      this.update()
    }, //end of passwordCheck
    async update() {
      this.setupLoading(true);
      this.closeToast();
      this.setToast = this.$buefy.toast.open({
        message: "Updating password please wait . . . ",
        type: "is-success",
        position: "is-bottom",
        indefinite: true,
      });
      axios
        .post("api/v_1/update-password", { 
            password: this.form.password,
            token: this.form.token
          })
        .then((res) => {
          this.setupLoading(false);
          this.closeToast();
          this.setToast = this.$buefy.toast.open({
            message: `${res.data.success.message}`,
            type: "is-success",
            position: "is-bottom",
            duration: 3000,
          });
          this.$router.push({name: "PasswordUpdated", params: {message: res.data.success.message}})
        })
        .catch((err) => {
          if (err.response.status == "401") {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: "Action failed, authorization required . . . ",
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
            this.manageLogout();
          }
          if (err.response) {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: err.response.data.error.message,
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
          } else if (err.request) {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: JSON.stringify(err.request.data),
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
          } else {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: err.message,
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
          }
          // this.setupLoading(false);
        });
    }, //end of update
    validateToken(){
      this.closeToast();
      this.setToast = this.$buefy.toast.open({
        message: "Validating token please wait . . . ",
        type: "is-success",
        position: "is-bottom",
        indefinite: true,
      });
      axios
        .post("api/v_1/validate-token", { token: this.form.token })
        .then((res) => {
          this.closeToast();
          this.setToast = this.$buefy.toast.open({
            message: `${res.data.success.message}`,
            type: "is-success",
            position: "is-bottom",
            duration: 3000,
          });
          this.isTokenValid = true;
        })
        .catch((err) => {
          // this.setupLoading(false);
          if (err.response.status == "401") {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: "Action failed, authorization required . . . ",
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
            this.manageLogout();
          }
          if (err.response) {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: err.response.data.error.message,
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
          } else if (err.request) {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: JSON.stringify(err.request.data),
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
          } else {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: err.message,
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
          }
          setTimeout(() => {
            this.$router.push("/forgot-password");
          }, 3000);
        });
    }, //end of validateToken
    manageLogout(){
      this.$store.dispatch("LogOut");
      this.$router.push("/login");
    }, //manageLogout
    closeToast(){
      if (this.setToast) {
        this.setToast.close();
        this.setToast = null;
      }
    }, //end of closeToast
  },
  created() {
    if (this.$store.getters.isAuthenticated) this.$router.push({name: "AuthHome"});
    this.form.token = this.$route.params.reset_token;
    this.validateToken();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.form-input-holder {
  margin: 0 59px;
}

input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(13, 35, 51, 0.69);
  opacity: 1; /* Firefox */
}
input {
  background: rgba(196, 196, 196, 0.17);
  border: none;
}
.login-button:hover {
  filter: drop-shadow(0 0 10px #003b56);
}
</style>
