<template>
  <div class="block" style="padding: 0 10%;">
    <div class="container">
      <section class="section mt-6">
        <div class="columns is-desktop has-text-primary">
          <div class="column">
            <img alt="Team" src="../../assets/teams/umana.png" />
          </div>
          <div class="column is-three-fifths ml-5 pl-5">
            <h3 class="is-size-3 bold-text mt-3 pt-5"><b>Kokoete Emmanuel Umana </b></h3>
            <p class="regular-text has-text-primary mt-5">
              Kokoete Emmanuel Umana is a graduate of the University of Uyo, Akwa Ibom State. From whence he immediately proceeded to the Nigerian Law School and was successfully called to the Nigerian Bar in 2015.
            </p>
            <p class="regular-text has-text-primary mt-3">
              He is currently the President of Young Lawyers Forum (YLF) Ota Branch. Kokoete is an associate counsel in the law firm of Ogbah Isaac and Co. where his area of specialization is in Corporate Law Practice.
              Kokoete is happily married.
            </p>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "TeamInfo",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  @font-face {
    font-family: "MontBold";
    src: url("../../fonts/Mont/Mont-Bold.otf");
  }

  @font-face {
    font-family: "MontRegular";
    src: url("../../fonts/Mont/Mont-Regular.otf");
  }

  .bold-text {
    font-family: "MontBold";
  }

  .regular-text {
    font-family: "MontRegular";
  }
</style>
