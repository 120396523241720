<template>
  <div>
    <div class="columns is-mobile" style="margin-top: -50px;">
      <div class="column mt-4">
        <router-link to="/home" class="has-text-info bold-text is-size-4">Dashboard</router-link>
      </div>
      <div class="column has-text-right">
        <span class="icon mt-4 pr-3"> <i class="far fa-bell is-size-5"></i> </span>
        <span class="is-size-6 mt-4 has-text-info">{{ username }}</span>
        <div :class="`dropdown ${dropdown} is-right mt-3`">
          <div class="dropdown-trigger">
            <div aria-haspopup="true" aria-controls="dropdown-menu">
              <figure class="image is-32x32 ml-3 is-clickable">
                <img @click="toggleDropdown" class="is-rounded" 
                  :src="$store.getters.getUser.image_url">
              </figure>
            </div>
          </div>
          <div class="dropdown-menu" id="dropdown-menu" role="menu" @click="toggleDropdown">
            <div class="dropdown-content">
              <router-link to="/" class="dropdown-item has-text-right has-text-primary">Home</router-link>
            </div>
            <div class="dropdown-content">
              <router-link to="/home/profile" class="dropdown-item has-text-right has-text-primary">My Account</router-link>
            </div>
            <div class="dropdown-content">
              <a to="/" class="dropdown-item has-text-right has-text-grey">How to Guide</a>
            </div>
            <div class="dropdown-content">
              <router-link to="/home/latest-publications" class="dropdown-item has-text-right has-text-primary">Latest Publications</router-link>
            </div>
            <div class="dropdown-content">
              <router-link to="/home/rules-of-court/rule" class="has-text-primary dropdown-item has-text-right">Rules of Court</router-link>
            </div>
            <div class="dropdown-content">
              <router-link to="/home/rules-of-court/law" class="has-text-primary dropdown-item has-text-right">Laws of The Federation</router-link>
            </div>
            <div class="dropdown-content">
              <router-link to="/shop" class="dropdown-item has-text-right has-text-primary">Buy a Book</router-link>
            </div>
            <div class="dropdown-content">
              <router-link to="/home/subscriptions" class="dropdown-item has-text-right has-text-primary">Subscription</router-link>
            </div>
            <div class="dropdown-content" @click="signOut">
              <router-link to="" class="dropdown-item has-text-right has-text-primary">Sign out</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <nav 
      v-if="true === false"
      style="background-color: #F5F5FD"
      class="navbar is-fixed-top is-hidden-desktop mx-5" 
      role="navigation" 
      aria-label="main navigation">
      <div class="navbar-brand has-text-right">
        <router-link to="/home" class="has-text-info bold-text is-size-4 mt-3">Dashboard</router-link>

        <!--separator-->
        <div style="width: 50px"></div>

        <span class="icon mt-4 pt-2 pr-3"> <i class="far fa-bell is-size-5"></i> </span>
        
        <span class="is-size-6 has-text-info mt-3 pt-2">{{ username }}</span>

        <div :class="`dropdown ${dropdown} is-right mt-3`">
          <div class="dropdown-trigger">
            <div aria-haspopup="true" aria-controls="dropdown-menu">
              <figure class="image is-32x32 ml-3 is-clickable">
                <img @click="toggleDropdown" class="is-rounded" 
                  :src="$store.getters.getUser.image_url">
              </figure>
            </div>
          </div>
          <div class="dropdown-menu" id="dropdown-menu" role="menu" @click="toggleDropdown">
            <div class="dropdown-content">
              <router-link to="/" class="dropdown-item has-text-right has-text-primary">Home</router-link>
            </div>
            <div class="dropdown-content">
              <router-link to="/home/profile" class="dropdown-item has-text-right has-text-primary">My Account</router-link>
            </div>
            <div class="dropdown-content">
              <a to="/" class="dropdown-item has-text-right has-text-grey">How to Guide</a>
            </div>
            <div class="dropdown-content">
              <router-link to="/home/latest-publications" class="dropdown-item has-text-right has-text-primary">Latest Publications</router-link>
            </div>
            <div class="dropdown-content">
              <router-link to="/home/rules-of-court/rule" class="has-text-primary dropdown-item has-text-right">Rules of Court</router-link>
            </div>
            <div class="dropdown-content">
              <router-link to="/home/rules-of-court/law" class="has-text-primary dropdown-item has-text-right">Laws of The Federation</router-link>
            </div>
            <div class="dropdown-content">
              <router-link to="/shop" class="dropdown-item has-text-right has-text-primary">Buy a Book</router-link>
            </div>
            <div class="dropdown-content">
              <router-link to="/home/subscriptions" class="dropdown-item has-text-right has-text-primary">Subscription</router-link>
            </div>
            <div class="dropdown-content" @click="signOut">
              <router-link to="" class="dropdown-item has-text-right has-text-primary">Sign out</router-link>
            </div>
          </div>
        </div>

        <a role="button" :class="`navbar-burger ${($store.getters.isBurgerOpened) ? 'is-active' : ''}`" aria-label="menu" aria-expanded="false" @click="openThisBurger">
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>
      <div :class="`navbar-menu has-background-grey-lighter ${($store.getters.isBurgerOpened) ? 'is-active' : ''}`">
        <div class="navbar-end">
          <router-link to="/home" :class="`has-text-primary navbar-item ${($route.name === 'AuthHome') ? 'my-active' : ''}`">
            <b><span class="icon mr-3"><i class="fa fa-home"></i></span> Dashboard</b>
          </router-link>

          <router-link to="/home/profile" :class="`has-text-primary navbar-item ${($route.name === 'AuthProfile') ? 'my-active' : ''}`">
            <b><span class="icon mr-3"><i class="fa fa-user"></i></span> My Account</b>
          </router-link>

          <a to="/home" class="has-text-grey navbar-item">
            <b><span class="icon mr-3"><i class="fa fa-question-circle"></i></span> How to Guide</b>
          </a>

          <router-link to="/home/latest-publications" class="has-text-primary navbar-item">
            <b><span class="icon mr-3"><i class="fa fa-save"></i></span> Latest Publications</b>
          </router-link>

          <router-link to="/home/rules-of-court/rule" class="has-text-primary navbar-item">
            <b><span class="icon mr-3"><i class="fa fa-balance-scale"></i></span> Rules of Court</b>
          </router-link>

          <router-link to="/home/rules-of-court/law" class="has-text-primary navbar-item">
            <b><span class="icon mr-3"><i class="fa fa-balance-scale"></i></span> Laws of The Federation</b>
          </router-link>

          <router-link to="/shop" class="has-text-primary navbar-item">
            <b><span class="icon mr-3"><i class="fa fa-shopping-cart"></i></span> Buy a Book</b>
          </router-link>

          <a href="" class="has-text-primary navbar-item"  @click="signOut">
            <b><span class="icon mr-3"><i class="fa fa-sign-out-alt"></i></span> Logout</b>
          </a>
        </div>
      </div>
    </nav>
    <div class="">
      <div class="columns is-mobile">
        <div class="column mt-2">
          <div class="control has-icons-right" v-if="$store.getters.getUser.subscription.subscription_status">
            <input
              class="input is-small is-rounded"
              style="border: none; background-color: rgba(196, 196, 196, 0.17);"
              type="text"
              placeholder="Jimi v. State"
              v-model="query"
              @focus="searchOutside = true"
              @blur="searchOutside = query ? true : false"
              @keyup.enter="searchCase"
            />
            <span class="icon is-right" v-if="searchOutside === false">
              <i class="fas fa-search has-text-info"></i>
            </span>
          </div> <br />
        </div>
        <div class="column is-2 mt-2" v-if="searchOutside === true">
          <button class="button is-small is-info is-outlined search-button" @click="searchCase">
            <span class="icon has-text-info search-hover">
              <i class="fas fa-search"></i>
            </span>
          </button>
        </div>
      </div>
      <div class="columns is-mobile">
        <div class="column">
          <div class="exact-phrase" v-if="searchOutside === true">
            <label class="checkbox has-text-primary">
              <input type="checkbox" class="mr-2" v-model="exactPhrase">
              Search using exact phrase
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "Header",
  data() {
    return {
      query: this.$route.query.query,
      username: null,
      searchOutside: false,
      dropdown: null,
      isBurgerOpened: false,
      exactPhrase: (this.$route.query.query_type == "exact") ? true : false,
    };
  },
  methods: {
    ...mapActions(["LogOut", "openBurger"]),
    signOut(){
      this.LogOut();
      this.$router.push("/login");
    }, //end of signOut
    getName() {
      const user = JSON.parse(
        atob(localStorage.getItem("user_token").split(".")[1])
      );
      this.username = user.firstname;
    }, //end of getName
    sendAlert(message) {
      alert(message);
    }, //end of sendAlert
    async searchCase() {
      if (this.query && this.$store.getters.getUser.subscription.subscription_status) this.$router.push({
        name: "AuthSearchResult",
        query: {
          query: this.query,
          query_type: (this.exactPhrase) ? "exact" : "best",
        },
      });
      this.$router.go();
    }, //end of searchCase
    toggleDropdown(){
      // console.log(this.dropdown)
      this.dropdown = (!this.dropdown) ? "is-active" : null;
    }, //end of toggleDropdown
    openThisBurger(){
      this.openBurger(!this.$store.getters.isBurgerOpened);
    }, //end of openBurger
  },
  mounted() {
    this.getName();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  @font-face {
    font-family: "MontBold";
    src: url("../../../fonts/Mont/Mont-Bold.otf");
  }

  .bold-text {
    font-family: "MontBold";
  }

  .search-button:hover .search-hover{
    color: white !important;
  }

  .exact-phrase {
    margin-top: -30px;
  }
</style>
