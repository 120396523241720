<template>
  <div class="block" style="padding: 0 10%;">
    <div class="container" v-if="ejsc_shop">
      <section class="section mt-6">
        <div class="columns is-desktop has-text-primary">
          <div class="column is-12 ml-5 pl-5">
            <div
              class="card my-6"
              v-for="(item, index) in ejsc_shop"
              :key="index"
            >
              <div class="card-content">
                <div class="columns">
                  <div class="column">
                    <figure class="image is-2by2">
                      <img :src="item.image" alt="Product" />
                    </figure>
                  </div>
                  <div class="column px-6 py-6">
                    <div class="content has-text-primary">
                      <h6 class="is-size-6 has-text-primary">
                        {{ item.name }}
                      </h6>
                      <p>{{ item.category_name }}</p>
                    </div>
                  </div>
                  <div class="column has-text-right py-6">
                    <div class="content">
                      <p>{{ item.quantity }}</p>
                    </div>
                  </div>
                  <div class="column">
                    <div class="content">
                      <b-button
                        class="is-primary"
                        size="is-small"
                        @click="increaseQty(index)"
                        >+</b-button
                      >
                    </div>
                    <div class="content">
                      <b-button
                        class="is-primary"
                        size="is-small"
                        @click="reduceQty(index)"
                        >-</b-button
                      >
                    </div>
                  </div>
                  <div class="column py-6">
                    <div class="content has-text-primary">
                      <p>₦{{ formatNumber(item.amount) }}</p>
                    </div>
                  </div>
                  <div class="column py-6">
                    <div class="content">
                      <b-button
                        type="is-danger"
                        size="is-small"
                        icon-right="delete"
                        @click="deleteItem(item.id)"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="">
              <div class="column">
                <div class="select is-primary mr-6 is-rounded">
                  <select class="has-text-primary" @change="selectOrderType">
                    <option value="" disabled selected
                      >Select Order Type</option
                    >
                    <option value="pickup">Pickup</option>
                    <option value="delivery">Delivery</option>
                  </select>
                </div>
                <div
                  class="select is-primary mr-6 is-rounded"
                  v-if="order_type === 'pickup'"
                >
                  <select class="has-text-primary" @change="selectBranchList">
                    <option value="" disabled selected
                      >Select Nearest Branch</option
                    >
                    <option
                      v-for="branch in branchList"
                      :value="branch.id"
                      :key="branch.id"
                      >{{ branch.name }}</option
                    >
                  </select>
                  <p></p>
                </div>
                <div
                  class="select is-primary mr-6 is-rounded"
                  v-if="order_type === 'delivery'"
                >
                  <select class="has-text-primary" @change="selectPriceList">
                    <option value="" disabled selected>Select State</option>
                    <option
                      v-for="price in priceList"
                      :value="price.id"
                      :key="price.id"
                      >{{ price.name }}</option
                    >
                  </select>
                  <P> </P>
                </div>
                <div
                  class="field is-inline"
                  v-if="order_type === 'delivery' && stateId"
                >
                  <div class="control is-inline">
                    <input
                      v-model="delivery_address"
                      class="input is-primary is-rounded mr-4 is-inline"
                      type="text"
                      placeholder="Enter Delivery Address"
                    />
                  </div>
                </div>
              </div>
              <div
                class="column has-text-right"
                v-if="order_type && (terminalId || stateId)"
              >
                <div class="is-primary is-light">
                  Sub Total: ₦{{ formatNumber(sub_total) }} <br />
                  Logistics: ₦{{ formatNumber(vat) }} <br />
                  Grand Total: ₦{{ formatNumber(total_sum) }} <br />
                </div>
                <br />
                <b-button
                  type="is-primary"
                  class=""
                  size="is-large"
                  outlined
                  @click="placeOrder"
                >
                  <img class="p-6" src="../../assets/paystack.png" />
                </b-button>
              </div>
            </div>
          </div>
        </div>
        <div class="columns">
          <div
            class="column has-text-primary has-text-right bold-text is-size-4 is-clickable"
          >
            <div @click="$router.push('/shop')">
              <i class="fa fa-arrow-left mr-4"></i> Back to Shop
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions } from "vuex";
// import Progress from "../GeneralComponent/Progress";
// import Notification from "../GeneralComponent/Notification";

export default {
  name: "Cart",
  // component: {
  //   Progress,
  //   Notification,
  // },
  data() {
    return {
      ejsc_shop: null,
      sub_total: null,
      vat: 0.0,
      total_sum: null,
      user: this.$store.getters.getUser,
      order_type: null,
      branchList: [],
      priceList: [],
      terminalId: null,
      stateId: null,
      delivery_address: null,
      isLoading: false,
      notification: {
        show: false,
        title: null,
        message: null,
        type: "info",
        loading: false,
      },
      setToast: null,
    };
  },
  methods: {
    ...mapActions(["TotalCartItems"]),
    increaseQty(index) {
      this.ejsc_shop[index].quantity++;
      this.ejsc_shop[index].amount =
        this.ejsc_shop[index].quantity * this.ejsc_shop[index].unit_price;
      localStorage.setItem("ejsc_shop", JSON.stringify(this.ejsc_shop));
      this.calculateTotalSum();
    }, //end of increaseQty
    reduceQty(index) {
      if (this.ejsc_shop[index].quantity > 1) {
        this.ejsc_shop[index].quantity--;
        this.ejsc_shop[index].amount =
          this.ejsc_shop[index].quantity * this.ejsc_shop[index].unit_price;
        localStorage.setItem("ejsc_shop", JSON.stringify(this.ejsc_shop));
        this.calculateTotalSum();
      }
    }, //end of reduceQty
    deleteItem(itemId) {
      this.ejsc_shop = this.ejsc_shop.filter((item) => item.id != +itemId);
      localStorage.setItem("ejsc_shop", JSON.stringify(this.ejsc_shop));
      this.calculateTotalSum();
      this.TotalCartItems(JSON.parse(localStorage.getItem("ejsc_shop")).length);
    }, //end of deleteItem
    calculateSubTotal() {
      this.sub_total = this.ejsc_shop.reduce(
        (a, b) => Number(a) + Number(b.amount),
        0
      );
    }, //end of calculateSubTotal
    calculateTotalSum() {
      this.calculateSubTotal();
      this.total_sum = this.sub_total + this.vat;
    }, //end of calculateTotalSum
    async placeOrder() {
      if (!this.delivery_address && this.order_type === "delivery") {
        this.closeToast();
        this.setToast = this.$buefy.toast.open({
          message: "Delivery address is required . . . ",
          type: "is-danger",
          position: "is-bottom",
          duration: 3000,
        });
        return false;
      }
      const mainOrder = this.ejsc_shop.map((item) => {
        return {
          product_id: item.id,
          units: item.quantity,
        };
      });
      const userOrder = {
        type: this.order_type,
        terminalId: this.terminalId ?? this.stateId,
        deliveryAddress: this.delivery_address,
        order: mainOrder,
      };
      const config = {
        method: "post",
        url: "https://api.ejsconline.com:442/api/v_1/shop/place-order",
        headers: {
          Signature: "2b72e1a-55d3-4afd-811f-63f24",
          Authorization: localStorage.getItem("user_token"),
        },
        data: userOrder,
      };
      axios(config)
        .then((res) => {
          const result = res.data;
          const order_number = result.orderNumber;
          const handler = PaystackPop.setup({
            key: "pk_live_1f38e452463a33329d0473d8b59316248ab90402",
            email: this.user.email,
            amount: this.total_sum * 100,
            currency: "NGN",
            ref: result.orderNumber,
            callback: async function(response) {
              const reference = response.reference;
              const config = {
                method: "post",
                url:
                  "https://api.ejsconline.com:442/api/v_1/shop/complete-order",
                headers: {
                  Authorization: localStorage.getItem("user_token"),
                  Signature: "2b72e1a-55d3-4afd-811f-63f24",
                },
                data: {
                  reference,
                  type: "paystack",
                  order_number,
                },
              };
              axios(config)
                .then(() => {
                  window.location.href = `${
                    window.location.href.split("#")[0]
                  }#/shop/confirmed-payment`;
                })
                .catch((err) => {
                  alert("Order could not be completed");
                  console.log(err);
                });
            },
            onClose: function() {},
          });
          handler.openIframe();
        })
        .catch((err) => {
          if (err.response.status == "401") {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: "Action failed, authorization required . . . ",
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
            this.manageLogout();
          }
          if (err.response) {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: err.response.data.error.message,
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
          } else if (err.request) {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: JSON.stringify(err.request.data),
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
          } else {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: err.message,
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
          }
        });
    }, //end of placeOrder
    getBranchList() {
      const config = {
        method: "get",
        url: "https://api.ejsconline.com:442/api/v_1/shop/branch-list",
        headers: {
          Signature: "2b72e1a-55d3-4afd-811f-63f24",
          Authorization: localStorage.getItem("user_token"),
        },
      };
      axios(config)
        .then((res) => {
          this.branchList = res.data.data;
        })
        .catch((err) => {
          if (err.response.status == "401") {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: "Action failed, authorization required . . . ",
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
            this.manageLogout();
          }
          if (err.response) {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: err.response.data.error.message,
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
          } else if (err.request) {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: JSON.stringify(err.request.data),
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
          } else {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: err.message,
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
          }
        });
    }, //end of getBranchList
    getPriceList() {
      const config = {
        method: "get",
        url: "https://api.ejsconline.com:442/api/v_1/shop/price-list",
        headers: {
          Signature: "2b72e1a-55d3-4afd-811f-63f24",
          Authorization: localStorage.getItem("user_token"),
        },
      };
      axios(config)
        .then((res) => {
          this.priceList = res.data.data;
        })
        .catch((err) => {
          if (err.response.status == "401") {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: "Action failed, authorization required . . . ",
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
            this.manageLogout();
          }
          if (err.response) {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: err.response.data.error.message,
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
          } else if (err.request) {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: JSON.stringify(err.request.data),
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
          } else {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: err.message,
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
          }
        });
    }, //end of getPriceList
    manageLogout() {
      this.$store.dispatch("LogOut");
      this.$router.push("/login");
    }, //manageLogout
    closeToast() {
      if (this.setToast) {
        this.setToast.close();
        this.setToast = null;
      }
    }, //end of closeToast
    selectOrderType(event) {
      this.order_type = event.target.value;
    }, //end of selectOrderType
    selectBranchList(event) {
      this.terminalId = event.target.value;
      this.stateId = null;
      this.vat = 0;
      this.calculateTotalSum();
    }, //end of selectBranchList
    selectPriceList(event) {
      this.stateId = event.target.value;
      this.terminalId = null;
      const selected = this.priceList.filter(
        (price) => price.id == this.stateId
      );
      const filtered = selected[0];
      this.vat = Number(filtered.logistic);
      this.calculateTotalSum();
    }, //end of selectBranchList
    formatNumber(num) {
      return `${new Intl.NumberFormat("en-US").format(num)}.00`;
    }, //end of formatNumber
  },
  mounted() {
    const getShop = JSON.parse(localStorage.getItem("ejsc_shop"));
    if (!getShop || getShop.length === 0)
      this.$router.replace({ path: "/shop" });
    this.ejsc_shop = getShop;
    this.getPriceList();
    this.getBranchList();
    // this.calculateTotalSum();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.no-border {
  border: none;
  background: none;
}

.my-select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}
</style>
