<template>
  <div style="margin: 0 10%; ">
    <nav class="navbar" role="navigation" aria-label="main navigation" style="background-color: #F7F8FA;">
      <div class="navbar-brand" style="margin-top: 15.88px">
        <router-link class="" to="/">
          <img src="../../assets/EJSClogo2_new.png" alt="EJSC Logo" width="65" height="82">
        </router-link>

        <a role="button" :class="`navbar-burger ${mobileMenu}`" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample" @click="toggleMenu">
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div id="navbarBasicExample" :class="`navbar-menu ${mobileMenu}`">
        <div class="navbar-end px-6">
          <router-link class="navbar-item has-text-primary mx-1" to="/">
            Home
          </router-link>

          <router-link class="navbar-item has-text-primary mx-1" to="/about">
            About
          </router-link>

          <router-link class="navbar-item has-text-primary mx-1" to="/team">
            Meet our Team
          </router-link>

          <router-link class="navbar-item has-text-primary mx-1" to="/shop">
            Shop
          </router-link>

          <router-link class="navbar-item has-text-primary mx-1" to="/contact">
            Contact
          </router-link>

          <router-link class="navbar-item has-text-primary mx-1" to="/login" v-if="!isAuth">
            Log in
          </router-link>
        </div>

        <div class="navbar-end">
          <div class="navbar-item mx-1" v-if="!isAuth">
            <div class="buttons">
              <router-link class="button is-primary is-rounded" to="/signup">
                Sign up
              </router-link>
            </div>
          </div>

          <div class="navbar-item mx-1" v-if="isAuth">
            <div class="buttons">
              <router-link class="button is-primary is-rounded" to="/home">
                Dashboard
              </router-link>
            </div>
          </div>

          <div class="navbar-item mx-1">
            <div class="buttons">
              <router-link class="button is-white" to="/shop/cart" style="background-color: #F7F8FA">
                <i class="fas fa-shopping-bag fa-2x has-text-primary"></i>
                <sup v-if="$store.getters.getCartItems > 0" class="is-primary span-sub is-size-5">{{ $store.getters.getCartItems }}</sup>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: "Navbar2",
  data() {
    return {
      isAuth: false,
      mobileMenu: null,
      cartItems: 0,
    }
  },
  methods: {
    toggleMenu () {
      if (!this.mobileMenu) {
        this.mobileMenu = "is-active";
      } else {
        this.mobileMenu = null;
      }
    }, //end of toggleMenu
  },
  mounted() {
    this.isAuth = this.$store.getters.isAuthenticated;
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  .nav-menu-item {
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
  }

  .span-sub {
    margin-top: -10px;
    margin-left: 5px;
  }
</style>
