var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.getViewPort() >= 1000)?_c('div',{staticClass:"is-hidden-touch"},[_c('div',{staticClass:"container my-6 mx-6"},[_c('Header'),(!_vm.isPageReady)?_c('b-progress',{staticClass:"mt-4",attrs:{"type":"is-primary"}}):_vm._e(),(_vm.isPageReady)?_c('div',{staticClass:"columns has-text-primary"},[_c('div',{staticClass:"column"},[(_vm.$store.getters.getUser)?_c('div',{staticClass:"mb-5 bold-text is-size-4"},[_vm._v(" Welcome, "+_vm._s(_vm.$store.getters.getUser.firstname)+" "+_vm._s(_vm.$store.getters.getUser.lastname)+" ")]):_c('div',{staticClass:"mb-5 bold-text is-size-4"},[_vm._v(" Welcome, Precious Jack ")]),(_vm.$store.getters.getUser.subscription.subscription_status)?_c('div',[_c('div',{staticClass:"has-text-primary"},[_c('p',[_vm._v(" "+_vm._s(_vm.$store.getters.getUser.subscription.message1)+" "),_c('br'),_vm._v(" "+_vm._s(_vm.formatSubscriptionMessage( _vm.$store.getters.getUser.subscription.message2 ))+" ")])]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-two-thirds"},[_c('div',{staticClass:"card mt-6"},[_c('div',{staticClass:"card-content"},[_vm._m(0),_vm._l((_vm.$store.getters.getUser.recent_cases),function(cases){return _c('div',{key:cases.id,staticClass:"columns px-5 py-2 separator"},[_c('div',{staticClass:"column is-8"},[_c('router-link',{staticClass:"has-text-primary",attrs:{"to":("/home/load-case/" + (_vm.adjustCitation(
                              cases.case_name,
                              cases.part,
                              cases.page_start,
                              cases.page_end,
                              cases.part
                            ).trim()) + "/" + (cases.PiDxpln))}},[_c('p',{staticClass:"has-text-left regular-text"},[_vm._v(" "+_vm._s(_vm.adjustCitation( cases.case_name, cases.part, cases.page_start, cases.page_end, cases.part ))+" ")])])],1),_c('div',{staticClass:"column is-4"},[_c('router-link',{staticClass:"has-text-primary",attrs:{"to":("/home/load-case/" + (cases.case_name.trim()) + "/" + (cases.PiDxpln))}},[_c('p',{staticClass:"has-text-right regular-text"},[_vm._v(" "+_vm._s(_vm.formatDate(new Date(cases.published_date)))+" ")])])],1)])}),_c('div',{staticClass:"has-text-right mr-6 pr-5 my-5"},[_c('b-button',{staticClass:"is-rounded",attrs:{"type":"is-primary"}},[_vm._v("View More")])],1)],2)])]),_c('div',{staticClass:"column"},[_c('div',{staticClass:"card mt-6"},[_c('div',{staticClass:"card-content"},[_vm._m(1),_vm._l((_vm.$store.getters.getUser
                        .recent_activities),function(activity,index){return _c('div',{key:index,staticClass:"columns px-5 py-2 separator-t"},[_c('div',{staticClass:"column is-12"},[_c('a',{staticClass:"has-text-primary",attrs:{"to":""}},[_c('p',{staticClass:"has-text-left"},[_vm._v(" "+_vm._s(activity.description)+" on "+_vm._s(_vm.formatDate(activity.createdAt))+" ")])])])])})],2)])])]),_c('div',{staticClass:"columns",staticStyle:{"margin-top":"-50px"}},[_c('div',{staticClass:"column is-two-thirds"},[_c('div',{staticClass:"card mt-6"},[_c('div',{staticClass:"card-content"},[_vm._m(2),_vm._l((_vm.$store.getters.getUser
                        .recent_search),function(search,index){return _c('div',{key:index,staticClass:"columns px-5 py-2 separator"},[_c('div',{staticClass:"column is-8"},[_c('router-link',{staticClass:"has-text-primary",attrs:{"to":("/home/search-result?query=" + (search.query))}},[_c('p',{staticClass:"has-text-left regular-text"},[_vm._v(" "+_vm._s(search.query)+" ")])])],1),_c('div',{staticClass:"column is-4"},[_c('router-link',{staticClass:"has-text-primary",attrs:{"to":("/home/search-result?query=" + (search.query))}},[_c('p',{staticClass:"has-text-right regular-text"},[_vm._v(" "+_vm._s(_vm.formatDate(search.created_at))+" ")])])],1)])}),_c('div',{staticClass:"has-text-right mr-6 pr-5 my-5"},[_c('b-button',{staticClass:"is-rounded",attrs:{"type":"is-primary"}},[_vm._v("View More")])],1)],2)])]),_c('div',{staticClass:"column"},[_c('div',{staticClass:"card mt-6"},[_c('div',{staticClass:"card-content"},[_vm._m(3),_vm._l((_vm.$store.getters.getUser
                        .promotions),function(promo,index){return _c('div',{key:index,staticClass:"columns px-5 py-2 separator-t"},[_c('div',{staticClass:"column is-8"},[_c('a',{staticClass:"has-text-primary",attrs:{"to":""}},[_c('p',{staticClass:"has-text-left"},[_vm._v(_vm._s(promo))])])])])})],2)])])])]):_c('div',[_vm._m(4),_c('div',{staticClass:"columns"},_vm._l((_vm.plans),function(plan,index){return (index < 3)?_c('div',{key:index,staticClass:"column"},[_c('div',{staticClass:"card mt-6"},[_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"content has-text-centered py-5"},[_c('p',{staticClass:"has-text-primary regular-text"},[_vm._v(" "+_vm._s(_vm.capitalizeFirstChar( plan.name ))+" "),(!plan.name.toLowerCase().includes('promo'))?_c('span',[_vm._v("Plan")]):_vm._e()]),_c('h1',{staticClass:"is-size-1 has-text-primary heavy-text"},[_vm._v(" ₦"+_vm._s(_vm.formatNumber( plan.amount ))+" ")]),_c('b-button',{staticClass:"regular-text",attrs:{"type":"is-primary","rounded":"","outlined":""},on:{"click":function($event){return _vm.initSubscription(
                            plan.id,
                            plan.amount
                          )}}},[_vm._v("Subscribe")])],1)])])]):_vm._e()}),0),_c('div',{staticClass:"columns"},_vm._l((_vm.plans),function(plan,index){return (index >= 3 && index < 6)?_c('div',{key:index,staticClass:"column"},[_c('div',{staticClass:"card mt-6"},[_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"content has-text-centered py-5"},[_c('p',{staticClass:"has-text-primary regular-text"},[_vm._v(" "+_vm._s(_vm.capitalizeFirstChar( plan.name ))+" "),(!plan.name.toLowerCase().includes('promo'))?_c('span',[_vm._v("Plan")]):_vm._e()]),_c('h1',{staticClass:"is-size-1 has-text-primary heavy-text"},[_vm._v(" ₦"+_vm._s(_vm.formatNumber( plan.amount ))+" ")]),_c('b-button',{staticClass:"regular-text",attrs:{"type":"is-primary","rounded":"","outlined":""},on:{"click":function($event){return _vm.initSubscription(
                            plan.id,
                            plan.amount
                          )}}},[_vm._v("Subscribe")])],1)])])]):_vm._e()}),0)])])]):_vm._e()],1)]):_c('div',{staticClass:"is-hidden-desktop"},[_c('HomeMobile')],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"columns mb-2 py-2 separator"},[_c('div',{staticClass:"column"},[_c('p',{staticClass:"has-text-primary bold-text"},[_vm._v("Recent Cases")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"columns mb-2 py-2 separator-t"},[_c('div',{staticClass:"column"},[_c('p',{staticClass:"has-text-primary bold-text"},[_vm._v(" Recent Activities ")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"columns mb-2 py-2 separator"},[_c('div',{staticClass:"column"},[_c('p',{staticClass:"has-text-primary bold-text"},[_vm._v(" Recent Search ")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"columns mb-2 py-2 separator-t"},[_c('div',{staticClass:"column"},[_c('p',{staticClass:"has-text-primary bold-text"},[_vm._v("Promos")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"has-text-primary"},[_c('p',[_vm._v(" You don’t have an active subscription. "),_c('br'),_vm._v(" Choose a package that suits you below; ")])])}]

export { render, staticRenderFns }