<template>
  <div class="signup">
    <div class="is-hidden-touch" v-if="getViewPort() >= 1000">
      <navbar />
      <AuthCard page="signup" v-if="!$store.getters.getLoading" />
      <Footer />
    </div>
    <div class="is-hidden-desktop" v-else>
      <NavMobile />
      <AuthCard page="signup" v-if="!$store.getters.getLoading" />
      <FooterMobile />
    </div>
  </div>
</template>

<script>
// import HelloWorld from '../components/HelloWorld'
import Navbar from "../components/GeneralComponent/Navbar";
import NavMobile from "../components/mobile/GeneralComponent/Navbar";
import AuthCard from "../components/GeneralComponent/AuthCard";
import Footer from "../components/GeneralComponent/Footer";
import FooterMobile from "../components/mobile/GeneralComponent/Footer";

export default {
  name: "Signup",

  components: {
    // HelloWorld,
    Navbar,
    NavMobile,
    AuthCard,
    Footer,
    FooterMobile,
  },
  methods: {
    getViewPort() {
      return (window.innerWidth);
    }, //end of getViewPort
  },
};
</script>
