<template>
  <div class="block mb-6" style="">
    <div class="container has-text-white">
      <section class="section">
        <div class="columns ">
          <div class="column mx-4" v-if="true === false">
            <div class="control has-icons-right">
              <input
                class="input is-primary has-text-primary is-rounded"
                type="text"
                placeholder="Search Books"
              />
              <span class="icon is-right">
                <i class="fas fa-search has-text-primary pr-6"></i>
              </span>
            </div>
          </div>
          <div class="column my-4">
            <label class="label"> Category</label>
            <div class="select is-primary is-rounded">
              <select class="has-text-primary" @change="categorizeProduct">
                <option value="" disabled selected
                  >Select Product Category</option
                >
                <option value="all">All Product</option>
                <option
                  v-for="(category, index) in categories"
                  :key="index"
                  :value="category.id"
                  >{{ category.name }} ({{ category.product_counts }})</option
                >
              </select>
            </div>
          </div>
          <div class="column is-8">
            <div class="columns is-mobile">
              <!--prodA-->
              <div
                class="column has-text-centered mx-3"
                v-for="product in productsA"
                :key="product.id"
              >
                <router-link
                  :to="{
                    name: 'OneItem',
                    params: {
                      product: product,
                      item_name: product.name,
                      item_id: product.id,
                    },
                  }"
                >
                  <figure class="image">
                    <img :src="product.image" :alt="product.category_name" />
                  </figure>
                  <p class="has-text-centered has-text-primary mt-3">
                    <span class="bold-text">{{ product.name }}</span
                    ><br />
                    <span class="heavy-text is-size-4"
                      >₦{{ formatNumber(product.unit_price) }}</span
                    >
                  </p>
                </router-link>
              </div>
            </div>

            <div class="columns is-mobile">
              <!--prodB-->
              <div
                class="column has-text-centered mx-3"
                v-for="product in productsB"
                :key="product.id"
              >
                <router-link
                  :to="{
                    name: 'OneItem',
                    params: {
                      product: product,
                      item_name: product.name,
                      item_id: product.id,
                    },
                  }"
                >
                  <figure class="image">
                    <img :src="product.image" :alt="product.category_name" />
                  </figure>
                  <p class="has-text-centered has-text-primary mt-3">
                    <span class="bold-text">{{ product.name }}</span
                    ><br />
                    <span class="heavy-text is-size-4"
                      >₦{{ formatNumber(product.unit_price) }}</span
                    >
                  </p>
                </router-link>
              </div>
            </div>

            <div class="columns is-mobile">
              <!--prodC-->
              <div
                class="column has-text-centered mx-3"
                v-for="product in productsC"
                :key="product.id"
              >
                <router-link
                  :to="{
                    name: 'OneItem',
                    params: {
                      product: product,
                      item_name: product.name,
                      item_id: product.id,
                    },
                  }"
                >
                  <figure class="image">
                    <img :src="product.image" :alt="product.category_name" />
                  </figure>
                  <p class="has-text-centered has-text-primary mt-3">
                    <span class="bold-text">{{ product.name }}</span
                    ><br />
                    <span class="heavy-text is-size-4"
                      >₦{{ formatNumber(product.unit_price) }}</span
                    >
                  </p>
                </router-link>
              </div>
            </div>

            <div class="columns is-mobile">
              <!--prodD-->
              <div
                class="column has-text-centered mx-3"
                v-for="product in productsD"
                :key="product.id"
              >
                <router-link
                  :to="{
                    name: 'OneItem',
                    params: {
                      product: product,
                      item_name: product.name,
                      item_id: product.id,
                    },
                  }"
                >
                  <figure class="image">
                    <img :src="product.image" :alt="product.category_name" />
                  </figure>
                  <p class="has-text-centered has-text-primary mt-3">
                    <span class="bold-text">{{ product.name }}</span
                    ><br />
                    <span class="heavy-text is-size-4"
                      >₦{{ formatNumber(product.unit_price) }}</span
                    >
                  </p>
                </router-link>
              </div>
            </div>

            <div class="columns is-mobile">
              <!--prodE-->
              <div
                class="column has-text-centered mx-3"
                v-for="product in productsE"
                :key="product.id"
              >
                <router-link
                  :to="{
                    name: 'OneItem',
                    params: {
                      product: product,
                      item_name: product.name,
                      item_id: product.id,
                    },
                  }"
                >
                  <figure class="image">
                    <img :src="product.image" :alt="product.category_name" />
                  </figure>
                  <p class="has-text-centered has-text-primary mt-3">
                    <span class="bold-text">{{ product.name }}</span
                    ><br />
                    <span class="heavy-text is-size-4"
                      >₦{{ formatNumber(product.unit_price) }}</span
                    >
                  </p>
                </router-link>
              </div>
            </div>
          </div>
        </div>

        <div class="columns my-6 py-6">
          <div class="column is-4">
            <!--cat rem-->
          </div>
          <div class="column is-8 mx-6 px-6">
            <div class="buttons mx-6 px-6">
              <button
                :class="
                  `button is-primary is-inverted mx-1 ${
                    presentPage == 1 ? 'btn-disabled' : ''
                  }`
                "
                :disabled="presentPage == 1 ? true : false"
                @click="managePagination(presentPage - 1)"
              >
                <span class="icon">
                  <i class="fas fa-arrow-left"></i>
                </span>
                <span>Prev</span>
              </button>
              <button
                :class="
                  `button is-primary is-inverted ${
                    nums == presentPage ? 'btn-current-page' : ''
                  }`
                "
                v-for="nums in totalPages"
                @click="managePagination(nums)"
              >
                {{ nums }}
              </button>
              <button
                :class="
                  `button is-primary is-inverted mx-1 ${
                    presentPage == totalPages ? 'btn-disabled' : ''
                  }`
                "
                :disabled="presentPage == totalPages ? true : false"
                @click="managePagination(presentPage + 1)"
              >
                <span>Next</span>
                <span class="icon">
                  <i class="fas fa-arrow-right"></i>
                </span>
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "AllItems",
  data() {
    return {
      nextPage: null,
      previousPage: null,
      currentPage: null,
      categories: [],
      allProducts: [],
      productsA: [],
      productsB: [],
      productsC: [],
      productsD: [],
      productsE: [],
      tempImages: [
        "../../../assets/book1.png",
        "../../../assets/book2.png",
        "../../../assets/book3.png",
      ],
      setToast: null,
      selectedCategory: null,
      totalPages: 1,
      presentPage: 1,
      gotoPage: "1",
    };
  },
  methods: {
    managePagination(pageNo) {
      if (pageNo != this.presentPage) {
        this.gotoPage = pageNo;
        this.getProducts();
      }
    }, //end of managePagination
    getProducts() {
      const config = {
        method: "get",
        url: `https://api.ejsconline.com:442/api/v_1/shop/products?page=${this.gotoPage}`,
      };
      axios(config)
        .then((response) => {
          const result = response.data;
          this.allProducts = result.data;
          this.presentPage = result.current_page;
          this.totalPages = Math.ceil(result.count / result.per_page);
          this.adjustProductColumns(this.allProducts);
        })
        .catch((err) => {
          if (err.response.status == "401") {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: "Action failed, authorization required . . . ",
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
            this.manageLogout();
          }
          if (err.response) {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: err.response.data.error.message,
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
          } else if (err.request) {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: JSON.stringify(err.request.data),
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
          } else {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: err.message,
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
          }
        });
    }, //end of getProduct
    getCategories() {
      const config = {
        method: "get",
        url: "https://api.ejsconline.com:442/api/v_1/shop/categories",
      };
      axios(config)
        .then((response) => {
          const result = response.data;
          this.categories = result.data;
        })
        .catch((err) => {
          if (err.response.status == "401") {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: "Action failed, authorization required . . . ",
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
            this.manageLogout();
          }
          if (err.response) {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: err.response.data.error.message,
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
          } else if (err.request) {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: JSON.stringify(err.request.data),
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
          } else {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: err.message,
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
          }
        });
    }, //end of getCategories

    getRandomImage() {
      const index = Math.floor(Math.random() * 3);
      return this.tempImages[index];
    }, //end of getRandomImage

    categorizeProduct(event) {
      const selected = event.target.value;
      if (selected === "all") {
        this.adjustProductColumns(this.allProducts);
      } else {
        const myProducts = this.allProducts.filter(
          (prod) => prod.category_id == selected
        );
        this.adjustProductColumns(myProducts);
      }
    }, //end of categorizeProduct

    adjustProductColumns(myProducts) {
      const total = myProducts.length;
      if (total < 2) {
        this.productsA = this.allProducts.filter(
          (prod, index) => index < total
        );
        this.productsB = [];
        this.productsC = [];
        this.productsD = [];
        this.productsE = [];
      } else if (total >= 2 && total < 4) {
        this.productsA = this.allProducts.filter((prod, index) => index < 2);
        this.productsB = this.allProducts.filter(
          (prod, index) => index >= 2 && index < total
        );
        this.productsC = [];
        this.productsD = [];
        this.productsE = [];
      } else if (total >= 4 && total < 6) {
        this.productsA = this.allProducts.filter((prod, index) => index < 2);
        this.productsB = this.allProducts.filter(
          (prod, index) => index >= 2 && index < 4
        );
        this.productsC = this.allProducts.filter(
          (prod, index) => index >= 4 && index < total
        );
        this.productsD = [];
        this.productsE = [];
      } else if (total >= 6 && total < 8) {
        this.productsA = this.allProducts.filter((prod, index) => index < 2);
        this.productsB = this.allProducts.filter(
          (prod, index) => index >= 2 && index < 4
        );
        this.productsC = this.allProducts.filter(
          (prod, index) => index >= 4 && index < 6
        );
        this.productsD = this.allProducts.filter(
          (prod, index) => index >= 6 && index < total
        );
        this.productsE = [];
      } else {
        this.productsA = this.allProducts.filter((prod, index) => index < 2);
        this.productsB = this.allProducts.filter(
          (prod, index) => index >= 2 && index < 4
        );
        this.productsC = this.allProducts.filter(
          (prod, index) => index >= 4 && index < 6
        );
        this.productsD = this.allProducts.filter(
          (prod, index) => index >= 6 && index < 8
        );
        this.productsE = this.allProducts.filter(
          (prod, index) => index >= 8 && index < total
        );
      }
    }, //end of adjustProductColumns
    manageLogout() {
      this.$store.dispatch("LogOut");
      this.$router.push("/login");
    }, //manageLogout
    test_() {
      const config = {
        method: "get",
        url: "https://api.ejsconline.com:442/api/v_1/shop/price-list",
        headers: {
          Signature: "2b72e1a-55d3-4afd-811f-63f24",
          Authorization: localStorage.getItem("user_token"),
        },
      };
      axios(config)
        .then((res) => {
          console.log(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }, //end of test_
    closeToast() {
      if (this.setToast) {
        this.setToast.close();
        this.setToast = null;
      }
    }, //end of closeToast
    formatNumber(num) {
      return `${new Intl.NumberFormat("en-US").format(num)}.00`;
    }, //end of formatNumber
  },
  mounted() {
    // this.test_()
    this.getCategories();
    this.getProducts();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@font-face {
  font-family: "MontBold";
  src: url("../../../fonts/Mont/Mont-Bold.otf");
}

@font-face {
  font-family: "MontHeavy";
  src: url("../../../fonts/Mont/Mont-Heavy.otf");
}

.bold-text {
  font-family: "MontBold";
}

.heavy-text {
  font-family: "MontHeavy";
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #003b56;
  opacity: 1; /* Firefox */
}
</style>
