<template>
  <div class="form-input-holder">
    <div class="has-text-centered">
      <img src="../../assets/EJSClogo2_new.png" width="65" class="mt-6" alt="EJSC Logo" />
      <h3 class="is-size-4 has-text-primary mt-5 heavy-text"><b>Hello there!</b></h3>
      <p class="content has-text-primary">
        Enter your email address to recieve password reset link
      </p>
    </div>
    <div class="field mt-6">
      <label class="label your-email-label has-text-primary">Email</label>
      <div class="control">
        <input class="input is-rounded" type="text" v-model="form.email" />
      </div>
    </div>

    <b-button
      type="is-primary"
      size="is-medium"
      class="is-rounded mt-6 login-button"
      expanded
      @click="checkUp"
      >Submit</b-button
    >
  </div>
</template>

<script>
import axios from "axios";
import { mapActions } from "vuex";

export default {
  name: "ResetPassword",
  data() {
    return {
      form: {
        email: null,
      },
      message: null,
      showError: false,
      isLoading: false,
      notification: {
        show: false,
        title: null,
        message: null,
        type: "info",
        loading: false,
      },
      setToast: null,
    };
  },
  methods: {
    ...mapActions(['setupLoading']),
    checkUp(){
      if (!this.form.email) {
        this.closeToast();
        this.setToast = this.$buefy.toast.open({
          message: "Email is required",
          type: "is-warning",
          position: "is-bottom",
          duration: 3000,
        });
        return false;
      } else if (!this.isValidEmail()) {
        this.closeToast();
        this.setToast = this.$buefy.toast.open({
          message: "Email address is not valid!<br />e.g: samplemail@mail.com",
          type: "is-warning",
          position: "is-bottom",
          duration: 3000,
        });
        return false
      }
      this.submit();
    }, //end of checkUp
    isValidEmail(){
      const emailRegex = /^([\w-.]+@([\w-]+\.)+[\w-]{2,4})?$/;
      return emailRegex.test(this.form.email);
    }, //end of isValidEmail
    async submit() {
      this.setupLoading(true);
      this.closeToast();
      this.setToast = this.$buefy.toast.open({
        message: `Sending password reset link to ${this.form.email} . . . `,
        type: "is-success",
        position: "is-bottom",
        indefinite: true,
      });
      axios
        .post("api/v_1/password-reset", { email: this.form.email })
        .then((res) => {
          this.setupLoading(false);
          this.closeToast();
          this.setToast = this.$buefy.toast.open({
            message: `${res.data.success.message}`,
            type: "is-success",
            position: "is-bottom",
            duration: 3000,
          });
          this.$router.push({name: "ResetSuccess", params: {message: "check mail"}});
        })
        .catch((err) => {
          this.setupLoading(false);
          if (err.response.status == "401") {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: "Action failed, authorization required . . . ",
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
            this.manageLogout();
          }
          if (err.response) {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: err.response.data.error.message,
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
          } else if (err.request) {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: JSON.stringify(err.request.data),
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
          } else {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: err.message,
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
          }
        });
    }, //end og submit
    manageLogout() {
      this.$store.dispatch("LogOut");
      this.$router.push("/login");
    }, //manageLogout
    closeToast(){
      if (this.setToast) {
        this.setToast.close();
        this.setToast = null;
      }
    }, //end of closeToast
  },
  mounted() {
    if (this.$store.getters.isAuthenticated)
      this.$router.push({ name: "AuthHome" });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.form-input-holder {
  margin: 0 59px;
}

input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(13, 35, 51, 0.69);
  opacity: 1; /* Firefox */
}
input {
  background: rgba(196, 196, 196, 0.17);
  border: none;
}
.login-button:hover {
  filter: drop-shadow(0 0 10px #003b56);
}
</style>
