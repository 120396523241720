<template>
  <div class="block" style="background-color: #F8F9FD;">
    <div class="container">
      <section class="section">
        <div class="columns is-desktop has-text-primary">
          <div class="column">
            <h3 class="is-size-4 heavy-text has-text-primary">The Erudite Judgments of the Supreme Court (EJSC)</h3>
            <p class="is-size-6 has-text-primary mt-5 regular-text has-text-info has-text-justified" style="color: #7C8087;">
              Published by Legal Jurisprudence Limited emerged under the editorship of Isaac Ogbah, FICMC, in January 2015. Our mission is to provide the legal community with up to date judgments of the Supreme Court and to support legal research and study needs of students, law faculties and staff of various Nigerian Universities and Law Schools as well as other professional bodies.
            </p>
            <b-button rounded class="is-primary mt-6" @click="$router.push('/about')">Learn More</b-button>
          </div>
          <div class="column mt-5">
            <img alt="Lawyer" src="../../../assets/whoweare_mobile.png">
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "WhoWeAre",
  
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .block {
    /*background-image: url("../../assets/erudite_bg.png");*/
  }
</style>
