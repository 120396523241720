<template>
  <div class="block" style="padding: 0 10%;">
    <div class="container">
      <section class="section mt-6">
        <div class="columns is-desktop has-text-primary">
          <div class="column">
            <img alt="Team" src="../../assets/teams/hauwa.png" />
          </div>
          <div class="column is-three-fifths ml-5 pl-5">
            <h3 class="is-size-3 bold-text mt-3 pt-5"><b>Atere Hauwa Abiola</b></h3>
            <p class="regular-text has-text-primary mt-5">
              Atere Hauwa Abiola, a graduate of law from the University of Ibadan (UI), Ibadan, Oyo State with a Bachelor of Law Degree (LLB) Hons is part of the workforce in the prestigious Law Firm of Ogbah Isaac &amp; Co. whose principal Barrister Ogbah Issac and Head of Chambers Barrister Fumi Oleyede specialize in trademarks infringement. Engaging as a Reportorial Jurist at Legal Jurisprudence Ltd, the law firm core area of Law practice is Property Law, Corporate Law Practice, Civil and Criminal Litigation, Intellectual Property, Family Law and also but not limited to Human Right.
            </p>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "TeamInfo",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  @font-face {
    font-family: "MontBold";
    src: url("../../fonts/Mont/Mont-Bold.otf");
  }

  @font-face {
    font-family: "MontRegular";
    src: url("../../fonts/Mont/Mont-Regular.otf");
  }

  .bold-text {
    font-family: "MontBold";
  }

  .regular-text {
    font-family: "MontRegular";
  }
</style>
