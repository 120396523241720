<template>
  <div class="block" style="padding: 0 10%;">
    <div class="container mb-4">
      <section class="section mt-6">
        <div class="columns is-desktop has-text-primary">
          <div class="column">
            <img
              alt="Lawyer"
              class="flip-image"
              src="../../assets/confidentleader1_new.png"
            />
          </div>
          <div class="column">
            <h3 class="is-size-3 bold-text has-text-info">
              <b>Get in Touch</b>
            </h3>
            <p class="regular-text has-text-primary mt-5">
              Please give us a call, drop us an email or fill out the contact
              form and we’ll get back to you.
            </p>
            <section>
              <div class="columns my-5">
                <div class="column">
                  <div class="field">
                    <label class="label first-name-label" style="display: none;"
                      >First Name *</label
                    >
                    <div class="control">
                      <input
                        class="input is-rounded regular-text"
                        type="text"
                        placeholder="First Name *"
                        v-model="contact.firstname"
                      />
                    </div>
                  </div>
                </div>

                <div class="column">
                  <div class="field">
                    <label class="label last-name-label" style="display: none;"
                      >Last Name *</label
                    >
                    <div class="control">
                      <input
                        class="input is-rounded regular-text"
                        type="text"
                        placeholder="Last Name *"
                        v-model="contact.lastname"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="columns my-5">
                <div class="column">
                  <div class="field">
                    <label class="label your-email-label" style="display: none;"
                      >Your Email *</label
                    >
                    <div class="control">
                      <input
                        class="input is-rounded regular-text"
                        type="text"
                        placeholder="Your Email *"
                        v-model="contact.email"
                      />
                    </div>
                  </div>
                </div>

                <div class="column">
                  <div class="field">
                    <label
                      class="label phone-number-label"
                      style="display: none;"
                      >Phone Number *</label
                    >
                    <div class="control">
                      <input
                        class="input is-rounded regular-text"
                        type="number"
                        placeholder="Phone Number *"
                        v-model="contact.phoneNumber"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="field mt-6">
                <label class="label your-message-label" style="display: none;"
                  >Your Message</label
                >
                <div class="control">
                  <textarea
                    style="border-radius: 20px;"
                    class="textarea is-rounded regular-text"
                    placeholder="Your Message"
                    rows="9"
                    v-model="contact.message"
                  ></textarea>
                </div>
              </div>

              <b-button
                @click="checkUp"
                type="is-primary"
                size="is-medium"
                class="is-rounded mt-6 submit-contact"
                style="padding: 0 25%;"
                >Submit</b-button
              >
            </section>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions } from "vuex";

export default {
  name: "ContactForm",
  data() {
    return {
      contact: {
        firstname: null,
        lastname: null,
        email: null,
        phoneNumber: null,
        message: null,
      },
      setToast: null,
    };
  },
  methods: {
    ...mapActions(["setupLoading"]),
    checkUp() {
      if (!this.contact.firstname) {
        this.closeToast();
        this.setToast = this.$buefy.toast.open({
          message: "First name is required",
          type: "is-warning",
          position: "is-bottom",
          duration: 3000,
        });
        return false;
      } else if (!this.contact.lastname) {
        this.closeToast();
        this.setToast = this.$buefy.toast.open({
          message: "Last name is required",
          type: "is-warning",
          position: "is-bottom",
          duration: 3000,
        });
        return false;
      } else if (!this.contact.email) {
        this.closeToast();
        this.setToast = this.$buefy.toast.open({
          message: "Email is required",
          type: "is-warning",
          position: "is-bottom",
          duration: 3000,
        });
        return false;
      } else if (!this.isValidEmail()) {
        this.closeToast();
        this.setToast = this.$buefy.toast.open({
          message: "Email address is not valid!<br />e.g: samplemail@mail.com",
          type: "is-warning",
          position: "is-bottom",
          duration: 3000,
        });
        return false;
      } else if (!this.contact.phoneNumber) {
        this.closeToast();
        this.setToast = this.$buefy.toast.open({
          message: "Phone number is required",
          type: "is-warning",
          position: "is-bottom",
          duration: 3000,
        });
        return false;
      } else if (!this.isValidNumber()) {
        this.closeToast();
        this.setToast = this.$buefy.toast.open({
          message: "Phone Number is not valid!<br />e.g: 08123456789",
          type: "is-warning",
          position: "is-bottom",
          duration: 3000,
        });
        return false;
      } else if (!this.contact.message) {
        this.closeToast();
        this.setToast = this.$buefy.toast.open({
          message: "message is required",
          type: "is-warning",
          position: "is-bottom",
          duration: 3000,
        });
        return false;
      }
      this.sendMessage();
    }, //end of checkUp
    isValidEmail() {
      const emailRegex = /^([\w-.]+@([\w-]+\.)+[\w-]{2,4})?$/;
      return emailRegex.test(this.contact.email);
    }, //end of isValidEmail
    isValidNumber() {
      const numberRegex = /^\d{11}$/;
      return numberRegex.test(this.contact.phoneNumber);
    }, //end of isValidNumber
    sendMessage() {
      this.setupLoading(true);
      this.closeToast();
      this.setToast = this.$buefy.toast.open({
        message: "Sending message please wait . . . ",
        type: "is-success",
        position: "is-bottom",
        indefinite: true,
      });
      const config = {
        method: "post",
        url: "https://api.ejsconline.com:442/api/v_1/send-contact",
        data: this.contact,
      };
      axios(config)
        .then((response) => {
          this.setupLoading(false);
          this.closeToast();
          this.setToast = this.$buefy.toast.open({
            message: `${response.data.success.message}`,
            type: "is-success",
            position: "is-bottom",
            duration: 3000,
          });
        })
        .catch((err) => {
          this.setupLoading(false);
          if (err.response) {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: err.response.data.error.message,
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
          } else if (err.request) {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: JSON.stringify(err.request.data),
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
          } else {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: err.message,
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
          }
        });
    }, //end of sendMessage
    closeToast() {
      if (this.setToast) {
        this.setToast.close();
        this.setToast = null;
      }
    }, //end of closeToast
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@font-face {
  font-family: "MontBold";
  src: url("../../fonts/Mont/Mont-Bold.otf");
}

@font-face {
  font-family: "MontRegular";
  src: url("../../fonts/Mont/Mont-Regular.otf");
}

.bold-text {
  font-family: "MontBold";
}

.regular-text {
  font-family: "MontRegular";
}

input::placeholder,
textarea::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(13, 35, 51, 0.69);
  opacity: 1; /* Firefox */
}
input,
textarea {
  background: rgba(196, 196, 196, 0.17);
  border: none;
}
.submit-contact:hover {
  filter: drop-shadow(0 0 10px #003b56);
}

.flip-image {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
</style>
