<template>
  <div class="block" style="margin-bottom: -2%; background-color: #0D2333;">
    <div class="container has-text-white">
      <section class="section">
        <div class="content columns ">
          <div class="column">
            <div class="columns is-mobile my-5">
              <div class="column is-3">
                <img src="../../../assets/gps.svg" />
              </div>

              <div class="column mt-4">
                <h3 class="is-size-4 has-text-white">Address</h3>
                <p class="regular-text">
                  Head Office:<br/>
                  {{ $store.getters.getSettings.address }}
                </p>

                <!--<p class="content">
                  Branch Office:<br/>
                  Suite Z01, Owah Unik Plaza, 157, Jakpa Road, Effurun,
                  Delta State.

                </p>-->
              </div>

            </div>
          </div>

          <div class="column">
            <div class="columns is-mobile my-5">
              <div class="column is-3">
                <img src="../../../assets/mail.svg" />
              </div>

              <div class="column mt-4">
                <h3 class="is-size-4 has-text-white">Email us</h3>
                <p class="regular-text">
                  {{ $store.getters.getSettings.email }}
                </p>

                <!--<p class="content">
                  isaacogbah@ejsconline.com
                </p>-->
              </div>

            </div>
          </div>

          <div class="column">
            <div class="columns is-mobile my-5">
              <div class="column is-3">
                <img src="../../../assets/phone.svg" />
              </div>

              <div class="column mt-4">
                <h3 class="is-size-4 has-text-white">Telephone</h3>
                <p class="regular-text">
                  {{ $store.getters.getSettings.mobile.replace(/09066211036/g, '') }}
                </p>

                <!--<p class="content">
                  09033665845
                </p>-->
              </div>

            </div>
          </div>

        </div>

      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactInfo",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  @font-face {
    font-family: "MontRegular";
    src: url("../../../fonts/Mont/Mont-Regular.otf");
  }

  .regular-text {
    font-family: "MontRegular";
  }
</style>
