<template>
  <div class="shop">
    <div class="is-hidden-touch" v-if="getViewPort() >= 1000">
      <navbar />
      <GeneralHero page="Shop" />
      <router-view v-if="!$store.getters.getLoading"></router-view>
      <Footer />
    </div>
    <div class="is-hidden-desktop" v-else>
      <NavMobile />
      <GeneralHeroMobile page="Shop" />
      <router-view v-if="!$store.getters.getLoading"></router-view>
      <FooterMobile />
    </div>
  </div>
</template>

<script>
// import HelloWorld from '../components/HelloWorld'
import Navbar from "../components/GeneralComponent/Navbar";
import NavMobile from "../components/mobile/GeneralComponent/Navbar";
import GeneralHero from "../components/GeneralComponent/GeneralHero";
import GeneralHeroMobile from "../components/mobile/GeneralComponent/GeneralHero";
import Footer from "../components/GeneralComponent/Footer";
import FooterMobile from "../components/mobile/GeneralComponent/Footer";

export default {
  name: "Shop",

  components: {
    // HelloWorld,
    Navbar,
    NavMobile,
    GeneralHero,
    GeneralHeroMobile,
    Footer,
    FooterMobile,
  },
  methods: {
    getViewPort() {
      return (window.innerWidth);
    }, //end of getViewPort
  },
};
</script>
