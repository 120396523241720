<template>
  <div>
    <div class="is-hidden-touch" v-if="getViewPort() >= 1000">
      <div class="container my-6 mx-6">
        <Header />
        <div class="columns has-text-primary">
          <div class="column">
            <div class="columns">
              <div class="column bold-text is-size-4">Profile</div>
              <div class="column has-text-right mr-6">
                <b-button
                  type="is-info"
                  class="is-rounded mr-6"
                  @click="goToEditProfile"
                  >Edit Profile</b-button
                >
              </div>
            </div>

            <div class="columns">
              <div class="column">
                <div class="card pb-6">
                  <div class="card-content pb-6 has-text-info">
                    <div class="columns mb-2 py-2 separator">
                      <div class="column">
                        <p class="has-text-primary bold-text">&nbsp;</p>
                      </div>
                    </div>

                    <div
                      class="columns px-5 py-2 separator"
                      v-if="!isUpdatePassword"
                    >
                      <div class="column is-4">
                        <p class="has-text-left bold-text">Full Name</p>
                      </div>
                      <div class="column is-8">
                        <p class="has-text-left regular-text">
                          {{ $store.getters.getUser.firstname }}
                          {{ $store.getters.getUser.lastname }}
                        </p>
                      </div>
                    </div>

                    <div
                      class="columns px-5 py-2 separator"
                      v-if="!isUpdatePassword"
                    >
                      <div class="column is-4">
                        <p class="has-text-left bold-text">Email</p>
                      </div>
                      <div class="column is-8">
                        <p class="has-text-left regular-text">
                          {{ $store.getters.getUser.email }}
                        </p>
                      </div>
                    </div>

                    <div
                      class="columns px-5 py-2 separator"
                      v-if="!isUpdatePassword"
                    >
                      <div class="column is-4">
                        <p class="has-text-left bold-text">Mobile</p>
                      </div>
                      <div class="column is-8">
                        <p class="has-text-left regular-text">
                          {{ $store.getters.getUser.mobile }}
                        </p>
                      </div>
                    </div>

                    <div
                      class="columns px-5 py-2 separator"
                      v-if="!isUpdatePassword"
                    >
                      <div class="column is-4">
                        <p class="has-text-left bold-text">Address</p>
                      </div>
                      <div class="column is-8">
                        <p class="has-text-left regular-text">
                          {{ $store.getters.getUser.address }}
                        </p>
                        <small v-if="!$store.getters.getUser.address" class="has-text-danger">Please update profile and set address</small>
                      </div>
                    </div>

                    <div
                      class="columns px-5 py-2 separator"
                      v-if="!isUpdatePassword"
                    >
                      <div class="column is-4">
                        <p class="has-text-left bold-text">Password</p>
                      </div>
                      <div class="column is-4">
                        <p class="has-text-left regular-text">
                          **********************
                        </p>
                      </div>
                      <div class="column is-4">
                        <b-button
                          type="is-primary"
                          class="is-rounded mt-6 submit-contact"
                          @click="isUpdatePassword = true"
                        >
                          Change Password
                        </b-button>
                      </div>
                    </div>

                    <div
                      class="columns px-5 py-2 separator"
                      v-if="isUpdatePassword"
                    >
                      <div class="column">
                        <div class="field mb-4">
                          <label class="label first-name-label"
                            >Old Password
                          </label>
                          <div class="control">
                            <input
                              class="input is-rounded"
                              type="password"
                              placeholder="Enter old password"
                              v-model="password.old_password"
                            />
                          </div>
                        </div>
                        <div class="field my-4">
                          <label class="label first-name-label"
                            >New Password
                          </label>
                          <div class="control">
                            <input
                              class="input is-rounded"
                              type="password"
                              placeholder="Enter new password"
                              v-model="password.new_password"
                            />
                          </div>
                        </div>
                        <div class="field my-4">
                          <label class="label first-name-label"
                            >Confirm New Password
                          </label>
                          <div class="control">
                            <input
                              class="input is-rounded"
                              type="password"
                              placeholder="Enter new password again"
                              v-model="password.c_new_password"
                            />
                          </div>
                        </div>
                        <b-button
                          type="is-primary"
                          class="is-rounded submit-contact"
                          @click="passwordCheck"
                        >
                          Save Changes
                        </b-button>
                        <b-button
                          type="is-primary"
                          class="is-rounded ml-4 submit-contact"
                          @click="isUpdatePassword = false"
                        >
                          Cancel
                        </b-button>
                      </div>
                    </div>

                    <div
                      class="columns px-5 py-2 separator"
                      v-if="!isUpdatePassword && $store.getters.getUser.address"
                    >
                      <div class="column is-4">
                        <p class="has-text-left bold-text">Profile Photo</p>
                      </div>
                      <div class="column is-4">
                        <figure class="image is-128x128 my-4">
                          <img
                            class="is-rounded user-image"
                            :src="$store.getters.getUser.image_url"
                          />
                        </figure>
                      </div>
                      <div class="column is-4">
                        <b-button
                          type="is-primary"
                          class="is-rounded mt-6 submit-contact"
                          v-if="!this.image.file"
                          @click="uploadPicture"
                        >
                          Change Picture
                        </b-button>
                        <br />
                        <b-button
                          type="is-primary"
                          class="is-rounded mt-6 submit-contact"
                          v-if="this.image.file && !isUpdatePassword"
                          @click="saveImage"
                        >
                          Upload
                        </b-button>
                        <div class="field mb-4" style="display: none;">
                          <label class="label first-name-label"
                            >Upload file
                          </label>
                          <div class="control">
                            <input
                              ref="profilePic"
                              accept="image/*"
                              @change="loadImage"
                              class="input is-rounded"
                              type="file"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="is-hidden-desktop" v-else>
      <ProfileMobile />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Header from "../components/DashboardComponent/Header";
import ProfileMobile from "../pages_mobile/Profile";

export default {
  name: "Profile",
  components: {
    Header,
    ProfileMobile,
  },
  data() {
    return {
      imgSet: "a",
      profile: {
        firstname: null,
        lastname: null,
        email: null,
        mobile: null,
        address: null,
        avatar: null,
      },
      image: {
        data: null,
        file: null,
      },
      isUpdatePassword: false,
      password: {
        old_password: null,
        new_password: null,
        c_new_password: null,
      },
      isLoading: false,
      notification: {
        show: false,
        title: null,
        message: null,
        type: "info",
        loading: false,
      },
      setToast: null,
    };
  },
  methods: {
    getViewPort() {
      return window.innerWidth;
    }, //end of getViewPort
    manageLogout() {
      this.$store.dispatch("LogOut");
      this.$router.push("/login");
    }, //manageLogout

    goToEditProfile() {
      this.$router.push({
        name: "AuthEditProfile",
        params: { profile: this.profile },
      });
    }, //end of goToEditProfile

    uploadPicture() {
      const fileInput = this.$refs.profilePic;
      fileInput.click();
    }, //end of uploadPicture

    loadImage(event) {
      // console.log(event.target.files[0]);
      const userImage = document.getElementsByClassName("user-image")[0];
      if (event.target.files && event.target.files[0]) {
        const readImage = new FileReader();
        readImage.onload = function(e) {
          this.imgSet = e.target.result;
          userImage.setAttribute("src", this.imgSet);
        };
        readImage.readAsDataURL(event.target.files[0]);
        this.image.file = event.target.files[0];
      }
    }, //end of loadImage

    passwordCheck() {
      if (!this.password.old_password) {
        this.closeToast();
        this.setToast = this.$buefy.toast.open({
          message: "Old password is required ",
          type: "is-warning",
          position: "is-bottom",
          duration: 3000,
        });
        return false;
      } else if (!this.password.new_password) {
        this.closeToast();
        this.setToast = this.$buefy.toast.open({
          message: "New password is required ",
          type: "is-warning",
          position: "is-bottom",
          duration: 3000,
        });
        return false;
      } else if (!this.password.c_new_password) {
        this.closeToast();
        this.setToast = this.$buefy.toast.open({
          message: "Confirm new password is required ",
          type: "is-warning",
          position: "is-bottom",
          duration: 3000,
        });
        return false;
      } else if (this.password.new_password !== this.password.c_new_password) {
        this.closeToast();
        this.setToast = this.$buefy.toast.open({
          message: "New password and Confirm new password must be the same ",
          type: "is-warning",
          position: "is-bottom",
          duration: 3000,
        });
        return false;
      }
      this.updatePassword();
    }, //end of passwordCheck

    async updatePassword() {
      this.closeToast();
      this.setToast = this.$buefy.toast.open({
        message: "Updating user password . . . ",
        type: "is-success",
        position: "is-bottom",
        indefinite: true,
      });
      const config = {
        method: "post",
        url: "https://api.ejsconline.com:442/api/v_1/auth/update-password",
        headers: {
          Authorization: localStorage.getItem("user_token"),
          Signature: localStorage.getItem("web_token"),
        },
        data: this.password,
      };
      axios(config)
        .then(() => {
          this.closeToast();
          this.setToast = this.$buefy.toast.open({
            message: "Password updated successfully!",
            type: "is-success",
            position: "is-bottom",
            duration: 3000,
          });
          this.isUpdatePassword = false;
        })
        .catch((err) => {
          if (err.response && err.response.status == "401") {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: "Action failed, authorization required . . . ",
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
            this.manageLogout();
          } else if (err.response && err.response.status != "401") {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: err.response.data.error.message,
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
          } else if (err.request) {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: JSON.stringify(err.request.data),
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
          } else {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: err.message,
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
          }
        });
    }, //end of updatePassword
    saveImage() {
      this.closeToast();
      this.setToast = this.$buefy.toast.open({
        message: "Uploading please wait . . . ",
        type: "is-success",
        position: "is-bottom",
        indefinite: true,
      });
      const formData = new FormData();
      formData.append("upload_preset", "owxkrpba");
      // formData.append('folder', 'ejsc_dev');
      formData.append("file", this.image.file);
      const cloudName = "mastercraft-ict-solutions";
      fetch(`https://api.cloudinary.com/v1_1/${cloudName}/image/upload`, {
        method: "post",
        // mode: 'no-cors',
        body: formData,
      })
        .then((res) => res.json())
        .then((data) => {
          document
            .getElementsByClassName("user-image")[0]
            .setAttribute("src", data.url);
          this.saveProfile(data.url);
        })
        .catch((err) => {
          console.log(err);
          this.closeToast();
          this.setToast = this.$buefy.toast.open({
            message: `Upload failed: ${err}`,
            type: "is-danger",
            position: "is-bottom",
            duration: 3000,
          });
        });
    }, //end of saveImage
    saveProfile(imgUrl) {
      this.profile = { ...this.$store.getters.getUser };
      this.profile.image_url = imgUrl;
      this.closeToast();
      this.setToast = this.$buefy.toast.open({
        message: "Rounding up image upload . . . ",
        type: "is-success",
        position: "is-bottom",
        indefinite: true,
      });
      const config = {
        method: "post",
        url: "https://api.ejsconline.com:442/api/v_1/auth/profile",
        headers: {
          Authorization: localStorage.getItem("user_token"),
          Signature: localStorage.getItem("web_token"),
        },
        data: this.profile,
      };
      axios(config)
        .then(() => {
          this.closeToast();
          this.setToast = this.$buefy.toast.open({
            message: "Image updated successfully!",
            type: "is-success",
            position: "is-bottom",
            duration: 3000,
          });
          this.$store.commit("setUser", this.profile);
        })
        .catch((err) => {
          if (err.response && err.response.status == "401") {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: "Action failed, authorization required . . . ",
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
            this.manageLogout();
          } else if (err.response && err.response.status != "401") {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: (err.response.data.error.message.toLowerCase().includes("address")) ? "Action cannot be completed without setting address" : err.response.data.error.message,
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
          } else if (err.request) {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: JSON.stringify(err.request.data),
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
          } else {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: err.message,
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
          }
        });
    }, //end of saveProfile
    closeToast() {
      if (this.setToast) {
        this.setToast.close();
        this.setToast = null;
      }
    }, //end of closeToast
  },
  mounted() {
    //call
  },
};
</script>
<style scoped>
@font-face {
  font-family: "MontBold";
  src: url("../fonts/Mont/Mont-Bold.otf");
}

@font-face {
  font-family: "MontRegular";
  src: url("../fonts/Mont/Mont-Regular.otf");
}

@font-face {
  font-family: "MontLight";
  src: url("../fonts/Mont/Mont-Light.otf");
}

.bold-text {
  font-family: "MontBold";
}

.regular-text {
  font-family: "MontRegular";
}

.light-text {
  font-family: "MontLight";
}

.card {
  border-radius: 20px;
}

.card-content {
  padding-left: 0;
  padding-right: 0;
}
.separator {
  border-bottom: 1px solid #dfe0eb;
  padding-left: 85px !important;
  padding-right: 85px !important;
  margin: 0;
}
</style>
