<template>
  <div id="app" style="background-color: #F7F8FA;">
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
    <Notification />
    <b-progress v-if="!isPageReady" type="is-primary" class="mt-4"></b-progress>
    <router-view v-if="isPageReady" />
  </div>
</template>

<script>
import Notification from "./components/GeneralComponent/Notification";
import axios from "axios";
import { mapActions } from "vuex";

export default {
  components: {
    Notification,
  },
  data() {
    return {
      isPageReady: false,
      setToast: null,
    };
  },
  methods: {
    ...mapActions(["GlobalSettings", "AppDetected"]),
    getSettings() {
      this.closeToast();
      this.setToast = this.$buefy.toast.open({
        message: "Getting site information please wait . . . ",
        type: "is-success",
        position: "is-bottom",
        indefinite: true,
      });
      const config = {
        method: "get",
        url: "https://api.ejsconline.com:442/api/v_1/settings",
      };
      axios(config)
        .then((response) => {
          this.GlobalSettings(response.data.success.data);
          this.closeToast();
          this.setToast = this.$buefy.toast.open({
            message: `Ready!`,
            type: "is-success",
            position: "is-bottom",
            duration: 3000,
          });
          this.isPageReady = true;
        })
        .catch((err) => {
          if (err.response) {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: err.response.data.error.message,
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
          } else if (err.request) {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: JSON.stringify(err.request.data),
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
          } else {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: err.message,
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
          }
        });
    }, //end of getSettings
    closeToast() {
      if (this.setToast) {
        this.setToast.close();
        this.setToast = null;
      }
    }, //end of closeToast
    isMobile() {
      return window.location !== window.parent.location ? true : false;
    }, //end of isMobile
  },
  mounted() {
    this.getSettings();
    console.log(this.$route.query.is_mobile);
    if (this.isMobile() || this.$route.query.is_mobile == "yes") {
      this.AppDetected(true);
    } else {
      this.AppDetected(false);
    }
  },
};
</script>
<style></style>
