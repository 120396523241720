<template>
  <div class="contact">
    <div class="is-hidden-touch" v-if="getViewPort() >= 1000">
      <navbar />
      <GeneralHero page="Contact" />
      <div v-if="!$store.getters.getLoading">
        <ContactForm />
      </div>
      <ContactInfo />
      <Footer />
    </div>
    <div class="is-hidden-desktop" v-else>
      <NavMobile />
      <GeneralHeroMobile page="Contact" />
      <div v-if="!$store.getters.getLoading">
        <ContactFormMobile />
      </div>
      <ContactInfoMobile />
      <FooterMobile />
    </div>
  </div>
</template>

<script>
// import HelloWorld from '../components/HelloWorld'
import Navbar from "../components/GeneralComponent/Navbar";
import NavMobile from "../components/mobile/GeneralComponent/Navbar";
import GeneralHero from "../components/GeneralComponent/GeneralHero";
import GeneralHeroMobile from "../components/mobile/GeneralComponent/GeneralHero";
import ContactForm from "../components/ContactComponent/ContactForm";
import ContactFormMobile from "../components/mobile/ContactComponent/ContactForm";
import ContactInfo from "../components/GeneralComponent/ContactInfo";
import ContactInfoMobile from "../components/mobile/GeneralComponent/ContactInfo";
import Footer from "../components/GeneralComponent/Footer";
import FooterMobile from "../components/mobile/GeneralComponent/Footer";

export default {
  name: "Contact",

  components: {
    // HelloWorld,
    Navbar,
    NavMobile,
    GeneralHero,
    GeneralHeroMobile,
    ContactForm,
    ContactFormMobile,
    ContactInfo,
    ContactInfoMobile,
    Footer,
    FooterMobile,
  },
  methods: {
    getViewPort() {
      return (window.innerWidth);
    }, //end of getViewPort
  },
};
</script>
