<template>
  <div class="block" style="padding: 0 10%;">
    <div class="container">
      <section class="section mt-6">
        <div class="columns is-desktop has-text-primary">
          <div class="column">
            <img alt="Team" src="../../assets/teams/dotun.png" />
          </div>
          <div class="column is-three-fifths ml-5 pl-5">
            <h3 class="is-size-3 bold-text mt-3 pt-5"><b>Oludotun Adeyemi Olusiji</b></h3>
            <p class="regular-text has-text-primary mt-5">
              Oludotun Adeyemi Olusiji is a is also a Reportorial Jurist at Legal Jurisprudence Ltd. He graduated from the prestigious Obafemi Awolowo University, Ile-Ife, Osun State with a Bachelor of Law Degree (LLB)Hons. He was called to the Nigerian Bar (BL) and currently an associate Lawyer in the Law Firm of Ogbah Isaac & Co. Dotun is a brilliant lawyer whose area of legal practice is Property Law, Corporate Law, Intellectual Property Law and Family Law.
            </p>
            <p class="regular-text has-text-primary mt-3">
                He is a member of Nigeria Bar Association (Ota Branch); Christian Lawyers Fellowship of  Nigeria (CLASFON) and also a member of Young Lawyers Forum (YLF).
            </p>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "TeamInfo",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  @font-face {
    font-family: "MontBold";
    src: url("../../fonts/Mont/Mont-Bold.otf");
  }

  @font-face {
    font-family: "MontRegular";
    src: url("../../fonts/Mont/Mont-Regular.otf");
  }

  .bold-text {
    font-family: "MontBold";
  }

  .regular-text {
    font-family: "MontRegular";
  }
</style>
