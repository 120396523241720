<template>
  <div class="container my-6 mx-5">
    <Header />
    <b-progress v-if="!isPageReady" type="is-primary" class="mt-4"></b-progress>
    <div class="columns has-text-primary" v-if="isPageReady">
      <div class="column">
        <div class="columns is-mobile mb-5">
          <div class="column is-9 has-text-left bold-text is-size-6">
            {{ caseInfo }}
          </div>
          <div class="column has-text-right bold-text is-size-6 is-clickable">
            <div @click="$router.go(-1)">
              <i class="fa fa-arrow-left"></i>
              <span class="is-hidden-touch">Back to Search Result</span>
            </div>
          </div>
        </div>

        <div class="columns">
          <div class="column is-9">
            <div class="card">
              <div class="card-content">
                <!-- remove width attribute from all table -->
                <iframe
                  class=""
                  style="min-width: 100% !important;"
                  height="600"
                  :srcdoc="caseFileDoc"
                  allowfullscreen="true"
                ></iframe>
                <p
                  class="has-text-info"
                  style="overflow-wrap: break-word !important;"
                  v-html="caseFileDoc"
                  v-if="true === false"
                ></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../components/mobile/DashboardComponent/Header";
import axios from "axios";

export default {
  name: "SearchResult",
  components: {
    Header,
  },
  data() {
    return {
      caseId: null,
      caseFileDoc: "<html><body><h1>Hey there!</h1></body></html>",
      caseInfo: null,
      setToast: null,
      isPageReady: false,
    };
  },
  methods: {
    getCase() {
      this.closeToast();
      this.setToast = this.$buefy.toast.open({
        message: `Loading ${this.caseInfo} please wait . . . `,
        type: "is-success",
        position: "is-bottom",
        indefinite: false,
      });
      const config = {
        method: "get",
        url: `https://api.ejsconline.com:442/api/v_1/auth/load-case/${this.caseId}`,
        headers: {
          Authorization: localStorage.getItem("user_token"),
          Signature: localStorage.getItem("web_token"),
        },
      };
      axios(config)
        .then((res) => {
          setTimeout(() => {
            this.isPageReady = true;
          }, 1000);
          this.closeToast();
          const result = res.data;
          this.caseFileDoc = this.adjustCaseLoad(result);
        })
        .catch((err) => {
          setTimeout(() => {
            this.isPageReady = true;
          }, 1000);
          if (err.response && err.response.status == "401") {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: "Action failed, authorization required . . . ",
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
            this.manageLogout();
          } else if (err.response && err.response.status != "401") {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: err.response.data.error.message,
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
          } else if (err.request) {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: JSON.stringify(err.request.data),
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
          } else {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: err.message,
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
          }
        });
    }, //end of getCase
    manageLogout() {
      this.$store.dispatch("LogOut");
      this.$router.push("/login");
    }, //manageLogout
    closeToast() {
      if (this.setToast) {
        this.setToast.close();
        this.setToast = null;
      }
    }, //end of closeToast
    adjustCaseLoad(htmlString) {
      // const allT = document.getElementsByTagName("table");
      // const allImg = document.querySelectorAll("table img");
      // for (let i = 0; i < allT.length; i++) {
      //     allT[i].removeAttribute("width");
      // }
      // for (let i = 0; i < allImg.length; i++) {
      //     allImg[i].remove();
      // }
      return htmlString
        .replace(/<img .*?>/g, "")
        .replace(/width="600"/g, "width='100%'")
        .replace(/width:40%/g, "min-width: 100%");
    }, //end of adjustCaseLoad
  },
  mounted() {
    this.caseId = this.$route.params.case_id;
    this.caseInfo = this.$route.params.case_title;
    this.getCase();
  },
};
</script>
<style scoped>
@font-face {
  font-family: "MontBold";
  src: url("../fonts/Mont/Mont-Bold.otf");
}

@font-face {
  font-family: "MontHeavy";
  src: url("../fonts/Mont/Mont-Heavy.otf");
}

@font-face {
  font-family: "MontRegular";
  src: url("../fonts/Mont/Mont-Regular.otf");
}

@font-face {
  font-family: "MontLight";
  src: url("../fonts/Mont/Mont-Light.otf");
}

.heavy-text {
  font-family: "MontHeavy";
}

.bold-text {
  font-family: "MontBold";
}

.regular-text {
  font-family: "MontRegular";
}

.light-text {
  font-family: "MontLight";
}

.card {
  border-radius: 0 20px 20px 20px;
}

.resp-container {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;
}

.resp-iframe {
  margin-top: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
</style>
