// import axios from "axios";

const state = {
  user_token: null,
  web_token: null,
  user: null,
  subscription: null,
  subscription_status: null,
  isBurgerOpened: false,
  isLoading: true,
  isToast: null,
  cartItems: 0,
  isApp: false,
  signup: {
    firstname: null,
    lastname: null,
    email: null,
    mobile: null,
    password: null,
  },
  settings: {
    plan: [
      {
        name: null,
        amount: null,
      },
      {
        name: null,
        amount: null,
      },
      {
        name: null,
        amount: null,
      },
    ],
  },
};

const getters = {
  isAuthenticated: (state) => !!state.user_token,
  StateUser: (state) => state.user_token,
  webToken: (state) => state.web_token,
  userToken: (state) => state.user_token,
  subscriptionInfo: (state) => state.subscription,
  subscriptionStatus: (state) => state.subscription_status,
  isBurgerOpened: (state) => state.isBurgerOpened,
  getLoading: (state) => state.isLoading,
  getSignup: (state) => state.signup,
  getToast: (state) => state.isToast,
  getUser: (state) => state.user,
  getSettings: (state) => state.settings,
  getCartItems: (state) => state.cartItems,
  getIsApp: (state) => state.isApp,
};

const actions = {
  async Register(form) {
    console.log(form);
    // await axios.post("api/v_1/register", form);
    // await dispatch("LogIn", UserForm);
  },

  async LogIn({ commit }, user) {
    // await commit("setUser", user.get("email"));
    localStorage.setItem("user_token", `Bearer ${user.token}`)
    localStorage.setItem("web_token", user.web_token)
    await commit("setUserToken", `Bearer ${user.token}`)
    await commit("setWebToken", user.web_token)
  },

  async LogOut({ commit }) {
    const token = null;
    localStorage.setItem("user_token", token)
    localStorage.setItem("web_token", token)
    localStorage.setItem("sub_message", token);
    commit("logout", token);
    commit("setUser", token);
  },

  SetSubscription({ commit }, info) {
    commit("subscription", info)
  },

  SetSubscriptionStatus({ commit }, info) {
    commit("subscriptionStatus", info)
  },

  openBurger({ commit }, info = false) {
    commit("burger", info)
  },

  setupLoading({ commit }, loadingState = false) {
    commit("loader", loadingState)
  },

  SignUp({ commit }, signup) {
    commit("setSignup", signup)
  },

  Toaster({ commit }, toastObject) {
    commit("setToast", toastObject)
  },

  MakeUser ({ commit }, userObject) {
    commit("setUser", userObject)
  },

  GlobalSettings ({ commit }, settingsObject) {
    commit("setSettings", settingsObject)
  },
  
  TotalCartItems({ commit }, cartItem) {
    commit("setCartItem", cartItem);
  },
  
  AppDetected({ commit }, isItApp) {
    commit("setIsApp", isItApp)
  }
};

const mutations = {
  setUserToken(state, user_token) {
    state.user_token = user_token;
  },

  setWebToken(state, web_token) {
    state.web_token = web_token;
  },

  logout(state, token) {
    state.user_token = token;
    state.web_token = token;
    state.user = token;
  },

  subscription(state, info) {
    state.subscription = info;
  },

  subscriptionStatus(state, info) {
    state.subscription_status = info;
  },

  burger(state, info = false) {
    state.isBurgerOpened = info;
  },

  loader (state, loadingState = false) {
    state.isLoading = loadingState;
  },

  setSignup (state, signup) {
    state.signup = signup;
  },

  setToast (state, toastObject) {
    state.isToast = toastObject;
  },

  setUser (state, userObject) {
    state.user = userObject;
  },

  setSettings (state, settingsObject) {
    state.settings = settingsObject;
  },

  setCartItem(state, totalItems) {
    state.cartItems = totalItems;
  },

  setIsApp(state, isItApp) {
    state.isApp = isItApp;
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
};
