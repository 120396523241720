<template>
  <div class="block" style="padding: 0 10%;">
    <div class="container">
      <section class="section mt-6">
        <div class="columns is-desktop has-text-primary">
          <div class="column">
            <img alt="Team" src="../../assets/teams/janet.png" />
          </div>
          <div class="column is-three-fifths ml-5 pl-5">
            <h3 class="is-size-3 bold-text mt-3 pt-5"><b>Janet Bassey Ekpe</b></h3>
            <p class="regular-text has-text-primary mt-5">
              Janet Bassey Ekpe is the store manager in the office of Legal Jurisprudence Limited. She is a native of Cross River State. She lives in Ota, Ogun State with her family. She loves travelling and listening to music.
            </p>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "TeamInfo",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  @font-face {
    font-family: "MontBold";
    src: url("../../fonts/Mont/Mont-Bold.otf");
  }

  @font-face {
    font-family: "MontRegular";
    src: url("../../fonts/Mont/Mont-Regular.otf");
  }

  .bold-text {
    font-family: "MontBold";
  }

  .regular-text {
    font-family: "MontRegular";
  }
</style>
