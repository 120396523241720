<template>
  <div class="container my-6 mx-5">
    <Header />
    <b-progress v-if="!isPageReady" type="is-primary" class="mt-4"></b-progress>
    <div class="columns has-text-primary" v-if="isPageReady">
      <div class="column">
        <h3 class="has-text-primary bold-text is-size-5">
          Latest Publications
        </h3>
        <div class="container">
          <section class="section">
            <div class="columns mb-6">
              <div
                class="column p-6 mx-5"
                style="border: 1px solid #ECEFF4;"
                v-for="product in allProducts"
                :key="product.id"
              >
                <div class="">
                  <router-link
                    :to="{
                      name: 'OneItem',
                      params: {
                        product: product,
                        item_name: product.name,
                        item_id: product.id,
                      },
                    }"
                  >
                    <figure class="image" @click="$router.push()">
                      <img :src="product.image" :alt="product.category_name" />
                    </figure>
                    <p class="has-text-primary bold-text has-text-left my-2">
                      {{ product.name }} <br />
                      <span class="regular-text" style="color: #7C8087;"
                        >₦{{ formatNumber(product.unit_price) }}</span
                      >
                    </p>
                  </router-link>
                </div>
              </div>
            </div>
            <div class="columns has-text-centered">
              <div class="column">
                <b-button
                  rounded
                  class="is-primary px-6"
                  @click="$router.push('/shop')"
                  >Shop More</b-button
                >
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../components/mobile/DashboardComponent/Header";
import axios from "axios";

export default {
  name: "LatestPublications",
  components: {
    Header,
  },
  data() {
    return {
      allProducts: [],
      setToast: null,
      isPageReady: false,
    };
  },
  methods: {
    getProducts() {
      const config = {
        method: "get",
        url:
          "https://api.ejsconline.com:442/api/v_1/shop/recent-products?size=4",
        headers: {
          Authorization: localStorage.getItem("user_token"),
          Signature: "2b72e1a-55d3-4afd-811f-63f24",
        },
      };
      axios(config)
        .then((response) => {
          const result = response.data;
          this.allProducts = result.data;
          this.isPageReady = true;
          // this.allProducts = this.allProducts.filter((prod, index) => index < 5);
        })
        .catch((err) => {
          if (err.response.status == "401") {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: "Action failed, authorization required . . . ",
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
            this.manageLogout();
          }
          if (err.response) {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: err.response.data.error.message,
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
          } else if (err.request) {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: JSON.stringify(err.request.data),
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
          } else {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: err.message,
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
          }
        });
    }, //end of getProduct
    manageLogout() {
      this.$store.dispatch("LogOut");
      this.$router.push("/login");
    }, //manageLogout
    closeToast() {
      if (this.setToast) {
        this.setToast.close();
        this.setToast = null;
      }
    }, //end of closeToast
    formatNumber(num) {
      return `${new Intl.NumberFormat("en-US").format(num)}.00`;
    }, //end of formatNumber
  },
  mounted() {
    this.getProducts();
  },
};
</script>
<style scoped>
@font-face {
  font-family: "MontBold";
  src: url("../fonts/Mont/Mont-Bold.otf");
}

@font-face {
  font-family: "MontHeavy";
  src: url("../fonts/Mont/Mont-Heavy.otf");
}

@font-face {
  font-family: "MontRegular";
  src: url("../fonts/Mont/Mont-Regular.otf");
}

@font-face {
  font-family: "MontLight";
  src: url("../fonts/Mont/Mont-Light.otf");
}

.heavy-text {
  font-family: "MontHeavy";
}

.bold-text {
  font-family: "MontBold";
}

.regular-text {
  font-family: "MontRegular";
}

.light-text {
  font-family: "MontLight";
}

.card {
  border-radius: 0 20px 20px 20px;
}

.resp-container {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;
}

.resp-iframe {
  margin-top: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
</style>
