<template>
  <div class="block" :style="`padding: 0 10%; background-image: url(${require('../../assets/hero_desktop.png')})`">
    <div class="container">
      <section class="section">
        <h2 class="is-size-3 has-text-white has-text-centered my-6 py-6 heavy-text">
          Providing legal assistance to the judiciary, bar and other members of the legal community 
        </h2>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "Hero",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  @font-face {
    font-family: "MontHeavy";
    src: url("../../fonts/Mont/Mont-Heavy.otf");
  }

  .heavy-text {
    font-family: "MontHeavy";
  }
</style>
