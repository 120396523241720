<template>
  <div class="block" style="padding: 0 10%;">
    <div class="container">
      <section class="section mt-6">
        <div class="columns is-desktop has-text-primary">
          <div class="column">
            <img alt="Team" src="../../assets/teams/faith.png" />
          </div>
          <div class="column is-three-fifths ml-5 pl-5">
            <h3 class="is-size-3 bold-text mt-3 pt-5"><b>Faith Goodness Akpan (Mrs)</b></h3>
            <p class="regular-text has-text-primary mt-5">
              Faith Goodness Akpan (Mrs), a native of Essien Udim Local Government Area, of Akwa Ibom State is the head of computer transcriptionists of Erudite Judgments of the Supreme Court. She graduated from Wolex Polytechnic, Lagos State where she obtained her (ND) in Accountancy. Faith Akpan lives in Ogun State with her family.
            </p>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "TeamInfo",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  @font-face {
    font-family: "MontBold";
    src: url("../../fonts/Mont/Mont-Bold.otf");
  }

  @font-face {
    font-family: "MontRegular";
    src: url("../../fonts/Mont/Mont-Regular.otf");
  }

  .bold-text {
    font-family: "MontBold";
  }

  .regular-text {
    font-family: "MontRegular";
  }
</style>
