<template>
  <div class="block" style="padding: 0">
    <div class="container" style="margin-bottom: -2%;">
      <section class="section mt-6 main-section">
        <!-- login div-->
        <div class="login-container has-background-white my-6 is-hidden-touch">
          <!-- condition to determine which form/component to display -->
          <LoginForm v-if="page === 'login'" />
          <ResetPassword v-if="page === 'reset'" />
          <ResetPasswordSuccess v-if="page === 'reset-success'" />
          <UpdatePassword v-if="page === 'update'" />
          <PasswordUpdated v-if="page === 'updated'" />
          <SignupForm v-if="page === 'signup'" />
        </div>
        <div class="is-hidden-desktop has-background-white my-6 py-6">
          <!-- condition to determine which form/component to display -->
          <LoginForm v-if="page === 'login'" />
          <ResetPassword v-if="page === 'reset'" />
          <ResetPasswordSuccess v-if="page === 'reset-success'" />
          <UpdatePassword v-if="page === 'update'" />
          <PasswordUpdated v-if="page === 'updated'" />
          <SignupForm v-if="page === 'signup'" />
        </div>
      </section>
    </div>
  </div>
</template>

<script>

  import LoginForm from '../AuthComponent/LoginForm'
  import ResetPassword from '../AuthComponent/ResetPassword'
  import ResetPasswordSuccess from '../AuthComponent/PasswordResetSuccess'
  import UpdatePassword from '../AuthComponent/UpdatePassword'
  import PasswordUpdated from '../AuthComponent/PasswordUpdated'
  import SignupForm from '../AuthComponent/SignupForm'

  export default {
    name: "AuthCard",
    components: {
      LoginForm,
      ResetPassword,
      ResetPasswordSuccess,
      UpdatePassword,
      PasswordUpdated,
      SignupForm,
    },
    props: {
      page: {type: String, default: "login"},
    },
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.main-section {
  background-image: url("../../assets/auth_bg.png");
}

.login-container {
  width: calc(100vw / 3);
  margin: 0 auto;
  padding: 79px 0;
  margin-bottom: -2%;
}
</style>
