<template>
  <div class="sidebar-page">
    <!--<nav class="navbar is-fixed-top is-hidden-desktop mx-4" role="navigation" aria-label="main navigation">
      <div class="navbar-brand">
        <router-link class="navbar-item" to="/">
          <img
            :src="require('../../assets/EJSClogo2_new.png')"
            alt="EJSC Logo"
            style="max-width: 50%"
          />
        </router-link>

        <a role="button" :class="`navbar-burger ${($store.getters.isBurgerOpened) ? 'is-active' : ''}`" aria-label="menu" aria-expanded="false" @click="openThisBurger">
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>
      <div :class="`navbar-menu has-background-grey-lighter ${($store.getters.isBurgerOpened) ? 'is-active' : ''}`">
        <div class="navbar-end">
          <router-link to="/home" :class="`has-text-primary navbar-item ${($route.name === 'AuthHome') ? 'my-active' : ''}`">
            <b><span class="icon mr-3"><i class="fa fa-home"></i></span> Dashboard</b>
          </router-link>

          <router-link to="/home/profile" :class="`has-text-primary navbar-item ${($route.name === 'AuthProfile') ? 'my-active' : ''}`">
            <b><span class="icon mr-3"><i class="fa fa-user"></i></span> My Account</b>
          </router-link>

          <a to="/home" class="has-text-grey navbar-item">
            <b><span class="icon mr-3"><i class="fa fa-question-circle"></i></span> How to Guide</b>
          </a>

          <a to="/home" class="has-text-grey navbar-item">
            <b><span class="icon mr-3"><i class="fa fa-save"></i></span> Latest Publications</b>
          </a>

          <a to="/home" class="has-text-grey navbar-item">
            <b><span class="icon mr-3"><i class="fa fa-balance-scale"></i></span> Rules of Court</b>
          </a>

          <router-link to="/shop" class="has-text-primary navbar-item">
            <b><span class="icon mr-3"><i class="fa fa-shopping-cart"></i></span> Buy a Book</b>
          </router-link>

          <a href="" class="has-text-primary navbar-item"  @click="signOut">
            <b><span class="icon mr-3"><i class="fa fa-sign-out-alt"></i></span> Logout</b>
          </a>
        </div>
      </div>
    </nav>-->

    <section class="main-content columns is-fullheight">
      <aside
        style="background-color: #EBEBF5 !important; width: 290px; padding: 0;"
        class="has-background-grey-lighter pt-6 column is-2 is-narrow-mobile is-fullheight section is-hidden-touch"
      >
        <div class="mt-6">
          <div class="has-text-centered">
            <router-link to="/">
              <img
                :src="require('../../assets/EJSClogo2_new_alt.png')"
                alt="EJSC Logo"
                style="max-width: 50%"
              />
            </router-link>
          </div>
          <ul class="menu-list">
            <li
              :class="
                `my-3 pl-6 ${$route.name === 'AuthHome' ? 'my-active' : ''}`
              "
            >
              <router-link to="/home">
                <span class="icon mr-3 has-text-primary"
                  ><i class="fa fa-home"></i
                ></span>
                <span :class="`nav-text has-text-primary`">Dashboard</span>
              </router-link>
            </li>

            <li
              :class="
                `my-3 pl-6 ${$route.name === 'AuthProfile' ? 'my-active' : ''}`
              "
            >
              <router-link to="/home/profile">
                <span class="icon mr-3 has-text-primary"
                  ><i class="fa fa-user"></i
                ></span>
                <span :class="`nav-text has-text-primary`">My Account</span>
              </router-link>
            </li>

            <li class="my-3 pl-6">
              <a to="" class="has-text-grey">
                <span class="icon mr-3"
                  ><i class="fa fa-question-circle"></i
                ></span>
                <span :class="`nav-text `">How to Guide</span>
              </a>
            </li>

            <li class="my-3 pl-6">
              <router-link to="/home/latest-publications" class="has-text-primary">
                <span class="icon mr-3"><i class="fa fa-save"></i></span>
                <span :class="`nav-text `">Latest Publications</span>
              </router-link>
            </li>

            <li class="my-3 pl-6">
              <router-link to="/home/rules-of-court/rule" class="has-text-primary ">
                <span class="icon mr-3"
                  ><i class="fa fa-balance-scale"></i
                ></span>
                <span :class="`nav-text `">Rules of Court</span>
              </router-link>
            </li>

            <li class="my-3 pl-6">
              <router-link to="/home/rules-of-court/law" class="has-text-primary ">
                <span class="icon mr-3"
                  ><i class="fa fa-balance-scale"></i
                ></span>
                <span :class="`nav-text `">Laws of The Federation</span>
              </router-link>
            </li>

            <li class="my-3 pl-6">
              <router-link to="/shop" class="has-text-primary">
                <span class="icon mr-3"
                  ><i class="fa fa-shopping-cart"></i
                ></span>
                <span :class="`nav-text `">Buy a Book</span>
              </router-link>
            </li>

            <li class="my-3 pl-6" @click="signOut">
              <router-link to="" class="has-text-primary">
                <span class="icon mr-3"
                  ><i class="fa fa-sign-out-alt"></i
                ></span>
                <span :class="`nav-text has-text-primary`">Logout</span>
              </router-link>
            </li>
          </ul>

          <div
            class="card mx-6 is-boxed has-background-primary mt-6"
            style="border-radius: 20px;"
          >
            <div class="card-content">
              <div class="content">
                <img
                  src="../../assets/refresh.png"
                  alt="refresh"
                  class="my-4"
                />
                <p class="has-text-white light-text">
                  <!--you are currently subscribe to <b>Bronze plan</b> which expires on 3rd July 2021.-->
                  {{
                    formatSubscriptionMessage($store.getters.subscriptionInfo)
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </aside>

      <div class="container column" style="max-width: 100% !important;">
        <router-view v-if="!$store.getters.getLoading"></router-view>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "Sidebar",
  data() {
    return {
      expandOnHover: false,
      mobile: "reduce",
      reduce: false,
      sub_message: localStorage.getItem("sub_message"),
      isBurgerOpened: false,
    };
  },
  methods: {
    ...mapActions(["LogOut", "openBurger"]),
    signOut() {
      this.LogOut();
      this.$router.push("/login");
    }, //end of signOut
    formatSubscriptionMessage(str) {
      try {
        const splitted = str.split(" on ");
        const initial = `${splitted[0]} on`;
        const datePart = splitted[1].split(" ");
        const result = `${initial} ${datePart[0]} ${datePart[1]} ${datePart[2]}, ${datePart[3]}.`;
        return result;
      } catch (error) {
        console.log(error.message);
        return str;
      }
    }, //end of formatSubscriptionMessage
    openThisBurger() {
      this.openBurger(!this.$store.getters.isBurgerOpened);
    }, //end of openBurger
  },
};
</script>

<style scoped>
@font-face {
  font-family: "MontLight";
  src: url("../../fonts/Mont/Mont-Light.otf");
}

.light-text {
  font-family: "MontLight";
}

.my-active {
  background-color: white;
  /*border-bottom: 1px solid #003B56;
    padding-left: 10px;
    padding-right: 10px;
    /*padding-bottom: 5px;*/
}

.nav-text {
  font-size: 14px !important;
}
</style>
