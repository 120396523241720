<template>
  <div class="container">
    <div class="columns is-v-centered">
      <div class="column is-3">
        <h3 class="is-size-3 has-text-primary ">
          <router-link to="/home" class="has-text-info bold-text">Dashboard</router-link>
          
        </h3>
      </div>
      <div class="column is-4 mt-1">
        <div class="control has-icons-right" v-if="$store.getters.getUser.subscription.subscription_status">
          <input
            class="input is-small is-rounded"
            style="border: none; background-color: rgba(196, 196, 196, 0.17);"
            type="text"
            placeholder="Jimi v. State"
            v-model="query"
            @focus="searchOutside = true"
            @blur="searchOutside = query ? true : false"
            @keyup.enter="searchCase"
          />
          <span class="icon is-right" v-if="searchOutside === false">
            <i class="fas fa-search has-text-info pr-6"></i>
          </span>
        </div> <br />
        <div class="exact-phrase" v-if="searchOutside === true">
          <label class="checkbox has-text-primary">
            <input type="checkbox" class="mr-2" v-model="exactPhrase">
            Search using exact phrase
          </label>
        </div>
      </div>
      <div class="column is-1 mt-1" v-if="searchOutside === true">
        <button class="button is-small is-info is-outlined search-button" @click="searchCase">
          <span class="icon has-text-info search-hover">
            <i class="fas fa-search"></i>
          </span>
        </button>
      </div>
      <div class="column has-text-right has-text-info mt-1">
        <span class="icon"> <i class="far fa-bell pr-6 is-size-5"></i> </span
        >
        <span class="bold-text is-size-5 has-text-info">{{ username }}</span>
        <div :class="`dropdown ${dropdown} is-right`">
          <div class="dropdown-trigger">
            <div aria-haspopup="true" aria-controls="dropdown-menu">
              <figure class="image is-32x32 ml-3 is-clickable">
                <img @click="toggleDropdown" class="is-rounded" 
                  :src="$store.getters.getUser.image_url">
              </figure>
            </div>
          </div>
          <div class="dropdown-menu" id="dropdown-menu" role="menu" @click="toggleDropdown">
            <div class="dropdown-content">
              <router-link to="/home/subscriptions" class="dropdown-item has-text-primary">Subscription</router-link>
            </div>
            <div class="dropdown-content">
              <router-link to="/home/profile" class="dropdown-item has-text-primary">My Account</router-link>
            </div>
            <div class="dropdown-content" @click="signOut">
              <router-link to="" class="dropdown-item has-text-right has-text-primary">Sign out</router-link>
            </div>
          </div>
        </div>
        <!-- <img class="inline is-rounded" src="../../assets/profile_icon.png"> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "Header",
  data() {
    return {
      query: this.$route.query.query,
      username: null,
      searchOutside: false,
      dropdown: null,
      exactPhrase: (this.$route.query.query_type == "exact") ? true : false,
    };
  },
  methods: {
    ...mapActions(["LogOut"]),
    signOut(){
      this.LogOut();
      this.$router.push("/login");
    }, //end of signOut
    getName() {
      const user = JSON.parse(
        atob(localStorage.getItem("user_token").split(".")[1])
      );
      this.username = user.firstname;
    }, //end of getName
    sendAlert(message) {
      alert(message);
    }, //end of sendAlert
    async searchCase() {
      if (this.query && this.$store.getters.getUser.subscription.subscription_status) this.$router.push({
        name: "AuthSearchResult",
        query: {
          query: this.query,
          query_type: (this.exactPhrase) ? "exact" : "best",
        },
      });
      this.$router.go();
    }, //end of searchCase
    toggleDropdown(){
      console.log(this.dropdown)
      this.dropdown = (!this.dropdown) ? "is-active" : null;
    }, //end of toggleDropdown
  },
  mounted() {
    this.getName();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  @font-face {
    font-family: "MontBold";
    src: url("../../fonts/Mont/Mont-Bold.otf");
  }

  .bold-text {
    font-family: "MontBold";
  }

  .search-button:hover .search-hover{
    color: white !important;
  }

  .exact-phrase {
    margin-top: -10px;
  }
</style>
