<template>
  <div class="">
    <router-view></router-view>
  </div>
</template>

<script>

  export default {
    name: "SignupForm",
    mounted() {
      if (this.$store.getters.isAuthenticated) this.$router.push({name: "AuthHome"});
    },
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
