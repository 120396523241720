<template>
  <div class="block has-background-info" style="padding: 0 10%;">
    <div class="">
      <section class="p-5 has-text-white has-text-centered">
        New updates to EJSC online ...
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "Notice",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.learn-more:hover {
  filter: drop-shadow(0 0 10px #003B56);
}

</style>
