<template>
  <section class="container my-6 mx-6">
    <Header />
    <div class="columns">
        <div class="column">
            <div class="card">
                <div class="card-content">
                    <div class="content has-text-centered py-5">
                        <div class="has-text-primary">
                            <p>
                                {{ $store.getters.getUser.subscription.message1 }} <br />
                                {{ $store.getters.getUser.subscription.message2 }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="columns">
      <div class="column" v-for="(plan, index) in plans" :key="index" v-if="index < 3">
        <div class="card">
          <div class="card-content">
            <div class="content has-text-centered py-5">
              <p class="has-text-primary">
                {{
                  capitalizeFirstChar(plan.name)
                }}
                Plan
              </p>
              <h1 class="is-size-1 has-text-primary">
                ₦{{ formatNumber(plan.amount) }}
              </h1>
              <b-button
                type="is-primary"
                rounded
                outlined
                @click="
                  initSubscription(
                    plan.id,
                    plan.amount
                  )
                "
                >Subscribe</b-button
              >
            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="columns">
      <div class="column" v-for="(plan, index) in plans" :key="index" v-if="index >= 3 && index < 6">
        <div class="card">
          <div class="card-content">
            <div class="content has-text-centered py-5">
              <p class="has-text-primary">
                {{
                  capitalizeFirstChar(plan.name)
                }}
                <span v-if="!plan.name.toLowerCase().includes('promo')">Plan</span>
              </p>
              <h1 class="is-size-1 has-text-primary">
                ₦{{ formatNumber(plan.amount) }}
              </h1>
              <b-button
                type="is-primary"
                rounded
                outlined
                @click="
                  initSubscription(
                    plan.id,
                    plan.amount
                  )
                "
                >Subscribe</b-button
              >
            </div>
          </div>
        </div>
      </div>

    </div>
  </section>
</template>


<script>
import Header from "../components/DashboardComponent/Header";

export default {
    name: "Subscriptions",
    components: {
        Header,
    },
    data() {
        return {
          plans: null,
        }
    },
    methods: {
        initSubscription(id, amount) {
            this.$router.push({
                name: "AuthPayment",
                params: { id: id, amount: amount },
            });
        }, //end of initSubscription
        capitalizeFirstChar(char) {
            return `${char.charAt(0).toUpperCase()}${char.slice(1)}`;
        }, //end of capitalizeFirstChar
        formatNumber (num) {
          return `${new Intl.NumberFormat('en-US').format(num)}.00`;
        }, //end of formatNumber
        adjustPackage () {
          const allPlans = this.$store.getters.getSettings.plans;
          const noPromo = allPlans.filter(plan => !plan.name.toLowerCase().includes("promo"));
          const withPromo = allPlans.filter(plan => plan.name.toLowerCase().includes("promo"));
          this.plans = [...withPromo, ...noPromo];
        }, //end of adjustPackage
    },
    mounted() {
      this.adjustPackage();
    },
}
</script>
<style scoped>
@font-face {
  font-family: "MontBold";
  src: url("../fonts/Mont/Mont-Bold.otf");
}

@font-face {
  font-family: "MontRegular";
  src: url("../fonts/Mont/Mont-Regular.otf");
}

@font-face {
  font-family: "MontLight";
  src: url("../fonts/Mont/Mont-Light.otf");
}

.bold-text {
  font-family: "MontBold";
}

.regular-text {
  font-family: "MontRegular";
}
</style>