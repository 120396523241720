import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Buefy from "buefy";
import "buefy/dist/buefy.css";
import "./css/mystyles.css";
import "./css/font-family.css";
import axios from "axios";
import "./registerServiceWorker";
import VueMobileDetection from "vue-mobile-detection";
// import VueAxios from 'vue-axios'

// axios.defaults.withCredentials = true;
axios.defaults.baseURL = "https://api.ejsconline.com:442/";
axios.defaults.headers.common["Signature"] = "2b72e1a-55d3-4afd-811f-63f24"; //also in /pages/cart.vue
// axios.defaults.headers.common['Authorization'] = `Bearer ${store.getters.userToken}` ?? "";
axios.defaults.headers.common["Authorization"] = localStorage.getItem(
  "user_token"
);

// axios.interceptors.response.use(undefined, function(error) {
//   if (error) {
//     const originalRequest = error.config;
//     if (error.response.status === 401 && !originalRequest._retry) {
//       originalRequest._retry = true;
//       store.dispatch("LogOut");
//       return router.push("/login");
//     }
//   }
// });

// if (store.getters.webToken && store.getters.userToken) {
//   // axios.defaults.headers.common['Signature'] = store.getters.webToken;
//   axios.defaults.headers.common['Authorization'] = `Bearer ${store.getters.userToken}`;
// }

// Vue.use(Buefy)

Vue.config.productionTip = false;
Vue.use(Buefy);
Vue.use(VueMobileDetection);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
