<template>
  <div class="form-input-holder">
    <div class="has-text-centered">
      <img src="../../assets/EJSClogo2_new.png" class="mt-6" alt="EJSC Logo" />
      <h3 class="is-size-3 has-text-primary mt-5 heavy-text"><b>Congratulations<br />your purchase was successful</b></h3>
      <img src="../../assets/verified.png" class="my-6" alt="Verified Icon" /> <br/>
      <router-link to="/home" class="button is-primary is-outlined is-size-3 bold-text has-text-black my-6"><b>Go to Dashboard</b></router-link>
    </div>

  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "ShoppingConfirmed",
  methods: {
    ...mapActions(['TotalCartItems']),
  },
  mounted() {
    const getShop = JSON.parse(localStorage.getItem("ejsc_shop"));
    localStorage.setItem("ejsc_shop", JSON.stringify([]));
    this.TotalCartItems(JSON.parse(localStorage.getItem("ejsc_shop")).length);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
