import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "../store";
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Policy from '../views/Policy.vue'
import Team from '../views/Team.vue'
import Teams from '../components/TeamComponent/Teams.vue'
// import TeamInfo from '../components/TeamComponent/TeamInfo.vue'
import Isaac from '../components/TeamComponent/Isaac.vue'
import Amaechi from '../components/TeamComponent/Amaechi.vue'
import Blessing from '../components/TeamComponent/Blessing.vue'
import Dotun from '../components/TeamComponent/Dotun.vue'
import Ebere from '../components/TeamComponent/Ebere.vue'
import Faith from '../components/TeamComponent/Faith.vue'
import Feyi from '../components/TeamComponent/Feyi.vue'
import Funmi from '../components/TeamComponent/Funmi.vue'
import Hauwa from '../components/TeamComponent/Hauwa.vue'
import Ibiyinka from '../components/TeamComponent/Ibiyinka.vue'
import Janet from '../components/TeamComponent/Janet.vue'
import Lilian from '../components/TeamComponent/Lilian.vue'
import Olamide from '../components/TeamComponent/Olamide.vue'
import Onwumah from '../components/TeamComponent/Onwumah.vue'
import Samson from '../components/TeamComponent/Samson.vue'
import Uche from '../components/TeamComponent/Uche.vue'
import Umana from '../components/TeamComponent/Umana.vue'
import Shop from '../views/Shop.vue'
import AllItems from '../components/ShopComponent/AllItems.vue'
import OneItem from '../components/ShopComponent/OneItem.vue'
import Cart from '../components/ShopComponent/Cart.vue'
import ShoppingConfirmed from '../components/ShopComponent/ShoppingConfirmed.vue'
import Login from '../views/Login.vue'
import Reset from '../views/Reset.vue'
import ResetSuccess from "../views/ResetSuccess.vue";
import Update from '../views/Update.vue'
import Updated from '../views/Updated.vue'
import Signup from '../views/Signup.vue'
import OnboardingName from '../components/AuthComponent/SignupComponent/OnboardingName'
import OnboardingContact from '../components/AuthComponent/SignupComponent/OnboardingContact'
import OnboardingPassword from '../components/AuthComponent/SignupComponent/OnboardingPassword'
import OnboardingContinue from '../components/AuthComponent/SignupComponent/OnboardingContinue'
import OnboardingVerify from '../components/AuthComponent/SignupComponent/OnboardingVerify'
import OnboardingVerified from '../components/AuthComponent/SignupComponent/OnboardingVerified'
import Contact from '../views/Contact.vue'

// Dashboard ---
import Dashboard from '../views/UserAccount.vue'
import HomeDashboard from '../pages/Home.vue'
import PaymentOptions from '../pages/PaymentOptions.vue'
import ConfirmedPayment from '../pages/ConfirmedPayment.vue'
import SearchResult from '../pages/SearchResult.vue'
import LoadCase from '../pages/LoadCase.vue'
import Profile from '../pages/Profile.vue'
import EditProfile from '../pages/EditProfile.vue'
import Subscriptions from '../pages/Subscriptions.vue'
import RulesOfCourt from "../pages/RulesOfCourt.vue";
import LatestPublications from "../pages/LatestPublications.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      guest: true,
      title: "EJSC — Home",
    },
  },
  {
    path: "/about",
    name: "About",
    component: About,
    meta: {
      guest: true,
      title: "EJSC — About",
    },
  },

  {
    path: "/privacy-policy",
    name: "Privacy",
    component: Policy,
    meta: {
      guest: true,
      title: "EJSC — Privacy",
    },
  },
  {
    path: "/team",
    name: "Team",
    component: Team,
    meta: {
      guest: true,
      title: "EJSC — Team",
    },
    children: [
      {
        path: "/",
        component: Teams,
        meta: { guest: true },
      },
      /*
      {
        path: ":team_member",
        component: TeamInfo,
        meta: { guest: true },
      },
      */
      {
        path: "isaac",
        name: "Isaac",
        component: Isaac,
        meta: { guest: true },
      },
      {
        path: "amaechi",
        name: "Amaechi",
        component: Amaechi,
        meta: { guest: true },
      },
      {
        path: "blessing",
        name: "Blessing",
        component: Blessing,
        meta: { guest: true },
      },
      {
        path: "dotun",
        name: "Dotun",
        component: Dotun,
        meta: { guest: true },
      },
      {
        path: "ebere",
        name: "Ebere",
        component: Ebere,
        meta: { guest: true },
      },
      {
        path: "faith",
        name: "Faith",
        component: Faith,
        meta: { guest: true },
      },
      {
        path: "feyi",
        name: "Feyi",
        component: Feyi,
        meta: { guest: true },
      },
      {
        path: "funmi",
        name: "Funmi",
        component: Funmi,
        meta: { guest: true },
      },
      {
        path: "hauwa",
        name: "Hauwa",
        component: Hauwa,
        meta: { guest: true },
      },
      {
        path: "ibiyinka",
        name: "Ibiyinka",
        component: Ibiyinka,
        meta: { guest: true },
      },
      {
        path: "janet",
        name: "Janet",
        component: Janet,
        meta: { guest: true },
      },
      {
        path: "lilian",
        name: "Lilian",
        component: Lilian,
        meta: { guest: true },
      },
      {
        path: "olamide",
        name: "Olamide",
        component: Olamide,
        meta: { guest: true },
      },
      {
        path: "onwumah",
        name: "Onwumah",
        component: Onwumah,
        meta: { guest: true },
      },
      {
        path: "samson",
        name: "Samson",
        component: Samson,
        meta: { guest: true },
      },
      {
        path: "uche",
        name: "Uche",
        component: Uche,
        meta: { guest: true },
      },
      {
        path: "umana",
        name: "Umana",
        component: Umana,
        meta: { guest: true },
      },
    ],
  },
  {
    path: "/shop",
    name: "Shop",
    component: Shop,
    meta: {
      guest: true,
      title: "EJSC — Shop",
    },
    children: [
      {
        path: "/",
        component: AllItems,
        meta: { guest: true },
      },
      {
        path: ":item_name/:item_id",
        component: OneItem,
        name: "OneItem",
        meta: { guest: true },
      },
      {
        path: "cart",
        component: Cart,
        name: "Cart",
        meta: { requiresAuth: true },
      },
      {
        path: "confirmed-payment",
        component: ShoppingConfirmed,
        name: "ShoppingConfirmed",
        meta: { guest: true },
      },
    ],
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
    meta: {
      guest: true,
      title: "EJSC — Contact",
    },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      guest: true,
      title: "EJSC — Login",
    },
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: Reset,
    meta: {
      guest: true,
      title: "EJSC — Forgot Password",
    },
  },
  {
    path: "/check-mail",
    name: "ResetSuccess",
    component: ResetSuccess,
    meta: {
      guest: true,
      title: "EJSC —",
    },
  },
  {
    path: "/password/reset/:reset_token",
    name: "ResetPassword",
    component: Update,
    meta: {
      guest: true,
      title: "EJSC — Reset Password",
    },
  },
  {
    path: "/password-updated",
    name: "PasswordUpdated",
    component: Updated,
    meta: {
      guest: true,
      title: "EJSC —",
    },
  },
  {
    path: "/signup",
    name: "Signup",
    component: Signup,
    meta: {
      guest: true,
      title: "EJSC — Onboarding",
    },
    children: [
      {
        path: "/",
        component: OnboardingName,
        name: "OnboardingName",
        meta: { guest: true },
      },
      {
        path: "contact",
        component: OnboardingContact,
        name: "OnboardingContact",
        meta: { guest: true },
      },
      {
        path: "password",
        component: OnboardingPassword,
        name: "OnboardingPassword",
        meta: { guest: true },
      },
      {
        path: "continue",
        component: OnboardingContinue,
        name: "OnboardingContinue",
        meta: { guest: true },
      },
      {
        path: "verify",
        component: OnboardingVerify,
        name: "OnboardingVerify",
        meta: { guest: true },
      },
      {
        path: "verified",
        component: OnboardingVerified,
        name: "OnboardingVerified",
        meta: { guest: true },
      },
    ],
  },
  {
    path: "/home",
    name: "HomeDashboard",
    component: Dashboard,
    meta: {
      requiresAuth: true,
      title: "EJSC — Dashboard",
    },
    children: [
      {
        path: "/",
        name: "AuthHome",
        component: HomeDashboard,
        meta: { requiresAuth: true },
      },
      {
        path: "payment",
        name: "AuthPayment",
        component: PaymentOptions,
        meta: { requiresAuth: true },
      },
      {
        path: "payment/confirmed-payment",
        name: "AuthConfirmPayment",
        component: ConfirmedPayment,
        meta: { requiresAuth: true },
      },
      {
        path: "search-result",
        name: "AuthSearchResult",
        component: SearchResult,
        meta: { requiresAuth: true },
      },
      {
        path: "load-case/:case_title/:case_id",
        name: "LoadCase",
        component: LoadCase,
        meta: { requiresAuth: true },
      },
      {
        path: "profile",
        name: "AuthProfile",
        component: Profile,
        meta: { requiresAuth: true },
      },
      {
        path: "profile/edit",
        name: "AuthEditProfile",
        component: EditProfile,
        meta: { requiresAuth: true },
      },
      {
        path: "subscriptions",
        name: "Subscriptions",
        component: Subscriptions,
        meta: { requiresAuth: true },
      },
      {
        path: "rules-of-court/:type",
        name: "RulesOfCourt",
        component: RulesOfCourt,
        meta: { requiresAuth: true },
      },
      {
        path: "latest-publications",
        name: "LatestPublications",
        component: LatestPublications,
        meta: { requiresAuth: true },
      },
    ],
  },
];

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.isBurgerOpened) {
      store.commit("burger", false);
    }
    if (store.getters.isAuthenticated) {
      next();
      return;
    }
    next("/login");
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  if (to.matched.some((record) => record.meta.guest)) {
    if (store.getters.isBurgerOpened) {
      store.commit("burger", false);
    }
    if (store.getters.isAuthenticated) {
      // next("/home");
      next();
      return;
    }
    next();
  } else {
    next();
  }
});

router.beforeResolve((to, from, next) => {
  // if(to.name)
  store.commit("loader", true);
  next()
});

router.beforeEach((to, from, next) => {
  window.document.title = to.meta && to.meta.title ? to.meta.title : 'EJSC';
  next();
})

router.afterEach(() => {
  setTimeout(() => {
    store.commit("loader", false);
  }, 1500);
});

export default router
