<template>
  <div class="block" style="">
    <div class="container">
      <section class="section my-6">
        <div class="columns has-text-primary">
          <div class="column">
            <img alt="Lawyer" src="../../../assets/mission.png" width="280">
          </div>
          <div class="column my-5">
            <h3 class="is-size-5 bold-text mt-3 has-text-info"><b>Our Mission Statement</b></h3>
            <p class="regular-text has-text-info mt-5">
              The Erudite Judgments of the Supreme Court’s mission is to provide legal assistance to the judiciary, bar and other members of the legal community and to support legal research and study needs of students, faculty and staff of various Nigeria Universities and law schools as well as other professional bodies.
            </p>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "WhoWeAre1",
  
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  @font-face {
    font-family: "MontBold";
    src: url("../../../fonts/Mont/Mont-Bold.otf");
  }

  @font-face {
    font-family: "MontRegular";
    src: url("../../../fonts/Mont/Mont-Regular.otf");
  }

  .bold-text {
    font-family: "MontBold";
  }

  .regular-text {
    font-family: "MontRegular";
  }
  .block {
    /*background-image: url("../../assets/erudite_bg.png");*/
  }
</style>
