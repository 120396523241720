<template>
  <div class="form-input-holder">
    <div class="has-text-centered">
      <img src="../../../assets/onboarding3.png" alt="Onboarding Progress" />
      <img src="../../../assets/EJSClogo2_new.png" width="65" class="mt-6" alt="EJSC Logo" />
      <h3 class="is-size-3 has-text-primary mt-5"><b>Hello there!</b></h3>
      <p class="content has-text-primary">
        Enter email address to continue registeration
      </p>
    </div>

    <div class="field mt-6">
      <label class="label email-label has-text-primary">Email</label>
      <div class="control">
        <input class="input is-rounded" type="email" v-model="form.email" />
        <span class="is-size-7" style="display: none;">Please Enter your email</span>
      </div>
    </div>

    <b-button
      type="is-primary"
      size="is-medium"
      class="is-rounded mt-6 login-button"
      expanded @click="checkUp"
      >Continue</b-button
    >

	<b-button rounded class="is-ghost has-text-primary mt-3" expanded @click="$router.go(-1)">
		<i class="fa fa-arrow-left has-text-primary"></i> &nbsp; &nbsp;Back
	</b-button>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: "OnboardingContact",
  data() {
    return {
      form: {
        firstname: null,
        lastname: null,
        email: null,
        mobile: null,
      },
      setToast: null,
    }
  },
  methods: {
    ...mapGetters(["getSignup"]),
    ...mapActions(['SignUp', 'Toaster']),
    checkUp () {
      if (!this.form.email) {
        this.closeToast();
        this.setToast = this.$buefy.toast.open({
          message: "Email address is required!",
          type: "is-warning",
          position: "is-bottom",
          duration: 3000,
        });
        return false
      } else if (!this.isValidEmail()) {
        this.closeToast();
        this.setToast = this.$buefy.toast.open({
          message: "Email address is not valid!<br />e.g: samplemail@mail.com",
          type: "is-warning",
          position: "is-bottom",
          duration: 3000,
        });
        return false
      }
      this.proceed();
    }, //end of checkUp
    isValidEmail(){
      const emailRegex = /^([\w-.]+@([\w-]+\.)+[\w-]{2,4})?$/;
      return emailRegex.test(this.form.email);
    }, //end of isValidEmail
    proceed () {
      this.$router.push({
        name: "OnboardingVerify",
        params: {
          email: this.form.email
        }
      });
    }, //end of proceed
    closeToast(){
      if (this.setToast) {
        this.setToast.close();
        this.setToast = null;
      }
    }, //end of closeToast
  },
  mounted() {
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.form-input-holder {
  margin: 0 59px;
}

input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(13, 35, 51, 0.69);
  opacity: 1; /* Firefox */
}
input {
  background: rgba(196, 196, 196, 0.17);
  border: none;
}
.login-button:hover {
  filter: drop-shadow(0 0 10px #003b56);
}

</style>
