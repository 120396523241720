<template>
  <div class="container my-6 mx-5">
    <Header />
    <div class="columns has-text-primary">
      <div class="column">
        <div class="columns is-mobile">
          <div class="column bold-text is-size-5">Profile</div>
          <div class="column has-text-right">
            <router-link to="/home/profile">
              <b-button type="is-info" class="is-rounded"
                >View Profile</b-button
              >
            </router-link>
          </div>
        </div>

        <div class="columns">
          <div class="column">
            <div class="card pb-6">
              <div class="card-content pb-6">
                <div class="columns px-5 py-2">
                  <div class="column">
                    <div class="field ">
                      <label class="label first-name-label mb-5 bold-text"
                        >First Name
                      </label>
                      <div class="control">
                        <input
                          class="input is-rounded has-text-info regular-text px-5"
                          style="background-color: rgba(196, 196, 196, 0.17); border: none;"
                          type="text"
                          v-model="profile.firstname"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="column">
                    <div class="field ">
                      <label class="label first-name-label mb-5 bold-text"
                        >Last Name
                      </label>
                      <div class="control">
                        <input
                          class="input is-rounded has-text-info regular-text px-5"
                          style="background-color: rgba(196, 196, 196, 0.17); border: none;"
                          type="text"
                          v-model="profile.lastname"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="columns px-5 py-2">
                  <div class="column">
                    <div class="field ">
                      <label class="label first-name-label mb-5 bold-text"
                        >Address
                      </label>
                      <div class="control">
                        <input
                          class="input is-rounded has-text-info regular-text px-5"
                          style="background-color: rgba(196, 196, 196, 0.17); border: none;"
                          v-model="profile.address"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="column">
                    <div class="field ">
                      <label class="label first-name-label mb-5 bold-text"
                        >Phone Number
                      </label>
                      <div class="control">
                        <input
                          class="input is-rounded has-text-info regular-text"
                          style="background-color: rgba(196, 196, 196, 0.17); border: none;"
                          v-model="profile.mobile"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="columns is-mobile">
                  <div class="column is-5">
                    <b-button
                      type="is-info"
                      class="is-rounded ml-5 submit-contact"
                      outlined
                      @click="$router.push('/home/profile')"
                    >
                      Cancel
                    </b-button>
                  </div>
                  <div class="column">
                    <b-button
                      type="is-info"
                      class="is-rounded mr-5 submit-contact"
                      @click="editProfile"
                    >
                      Save Changes
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Header from "../components/mobile/DashboardComponent/Header";

export default {
  name: "EditProfile",
  components: {
    Header,
  },
  data() {
    return {
      profile: {
        firstname: null,
        lastname: null,
        email: null,
        mobile: null,
        address: null,
        image_url: null,
      },
      isLoading: false,
      notification: {
        show: false,
        title: null,
        message: null,
        type: "info",
        loading: false,
      },
      setToast: null,
    };
  },
  methods: {
    async editProfile() {
      this.closeToast();
      this.setToast = this.$buefy.toast.open({
        message: "Updating user password . . . ",
        type: "is-success",
        position: "is-bottom",
        indefinite: true,
      });
      const config = {
        method: "post",
        url: "https://api.ejsconline.com:442/api/v_1/auth/profile",
        headers: {
          Authorization: localStorage.getItem("user_token"),
          Signature: localStorage.getItem("web_token"),
        },
        data: this.profile,
      };
      axios(config)
        .then(() => {
          this.closeToast();
          this.setToast = this.$buefy.toast.open({
            message: "User profile updated successfully!",
            type: "is-success",
            position: "is-bottom",
            duration: 3000,
          });
          this.$store.commit("setUser", this.profile);
          this.$router.push("/home/profile");
        })
        .catch((err) => {
          if (err.response && err.response.status == "401") {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: "Action failed, authorization required . . . ",
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
            this.manageLogout();
          } else if (err.response && err.response.status != "401") {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: err.response.data.error.message,
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
          } else if (err.request) {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: JSON.stringify(err.request.data),
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
          } else {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: err.message,
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
          }
        });
    }, //end of get profile
    manageLogout() {
      this.$store.dispatch("LogOut");
      this.$router.push("/login");
    }, //manageLogout
    closeToast() {
      if (this.setToast) {
        this.setToast.close();
        this.setToast = null;
      }
    }, //end of closeToast
  },
  mounted() {
    // getProfileparams
    this.profile = this.$store.getters.getUser;
    if (!this.profile || !this.profile.firstname)
      this.$router.push("/home/profile");
  },
};
</script>
<style scoped>
@font-face {
  font-family: "MontBold";
  src: url("../fonts/Mont/Mont-Bold.otf");
}

@font-face {
  font-family: "MontRegular";
  src: url("../fonts/Mont/Mont-Regular.otf");
}

@font-face {
  font-family: "MontLight";
  src: url("../fonts/Mont/Mont-Light.otf");
}

.bold-text {
  font-family: "MontBold";
}

.regular-text {
  font-family: "MontRegular";
}

.light-text {
  font-family: "MontLight";
}

.card {
  border-radius: 20px;
}

.card-content {
  padding-left: 0;
  padding-right: 0;
}
</style>
