<template>
  <div class="block" style="padding: 0 10%;">
    <div class="container">
      <section class="section mt-6">
        <div class="columns is-desktop has-text-primary">
          <div class="column">
            <img alt="Team" src="../../assets/teams/funmi.png" />
          </div>
          <div class="column is-three-fifths ml-5 pl-5">
            <h3 class="is-size-3 bold-text mt-3 pt-5"><b>OLUWAFUNMILAYO .T. AJELE (OYEYIPO)</b></h3>
            <p class="regular-text has-text-primary mt-5">
              She is the Head of Publication; Erudite Judgments of the Supreme Court (EJSC) and equally the Head of Chambers in the Law Firm of OGBAH ISAAC & CO. She graduated from Lagos State University in 2004 with LLB (Hons) and attended the Nigerian Law School Bwari, Abuja where she bagged a BL and was admitted to the Nigerian Bar in May 2007.
            </p>
            <p class="regular-text has-text-primary mt-5">
              She has extensive legal practice in the areas of Trade Marks and Intellectual Property, Property Law, Family Law, Insurance, Investment Law, Secured Credit Transaction and Corporate Law. She has a flair for Solicitors practice, Intellectual Property Law, Perfection of Legal Titles and Investment Law, to mention but a few.
            </p>
            <p class="regular-text has-text-primary mt-5">
              Funmi is married with two lovely kids.
            </p>
            <p class="regular-text has-text-primary mt-5">
              She loves travelling and cooking.
            </p>
            <p class="regular-text has-text-primary mt-3">
                Member Nigerian Bar Association <br />
                Member, African Women Lawyers Association <br />
                Member Common Wealth Lawyer Association <br />
                Welfare Secretary, NBA Ota Branch. Ogun State
            </p>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "TeamInfo",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  @font-face {
    font-family: "MontBold";
    src: url("../../fonts/Mont/Mont-Bold.otf");
  }

  @font-face {
    font-family: "MontRegular";
    src: url("../../fonts/Mont/Mont-Regular.otf");
  }

  .bold-text {
    font-family: "MontBold";
  }

  .regular-text {
    font-family: "MontRegular";
  }
</style>
