<template>
  <div class="form-input-holder">
    <div class="has-text-centered">
      <img src="../../../assets/onboarding4.png" alt="Onboarding Progress" /> <br />
      <h3 class="is-size-4 has-text-primary mt-6 pt-6 heavy-text"><b>Congratulations<br />your Account is set</b></h3>
      <img src="../../../assets/verified.png" class="my-6" alt="Verified Icon" /> <br/>
      <a href="#/login" class="is-size-4 has-text-info heavy-text mt-6 pt-6"><b>Login to Continue</b></a>
    </div>

  </div>
</template>

<script>
export default {
  name: "OnboardingVerified",
  mounted() {
    // const user =  this.$store.getters.getSignup;
    // if (!user) this.$router.go(-1);
    this.$store.commit("setSignup", null);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .form-input-holder {
    margin: 0 59px;
  }
</style>
