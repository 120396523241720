<template>
  <div class="form-input-holder">
    <div class="has-text-centered">
      <img src="../../../assets/onboarding3.png" alt="Onboarding Progress" />
      <img src="../../../assets/EJSClogo2_new.png" width="65" class="mt-6" alt="EJSC Logo" />
      <h3 class="is-size-4 has-text-primary mt-5 heavy-text"><b>Verify Account</b></h3>
      <p class="content has-text-primary">
        Enter the 4-digit code sent to  your email addresss
      </p>
    </div>

    <div class=" mt-6 outer-container mb-4">
      <div class="has-text-centered inner-container">
        <input class="input partitioned-input" type="text" maxlength="4" v-model="form.otp" @keyup="checkUp" />
        <span class="is-size-7 has-text-centered" style="display: none;">
          Validation failed
        </span>
      </div>
    </div>

    <div class="has-text-centered has-text-info mt-6 " v-if="countdown > 0">
      Didn't get OTP?<br />
      <p class="has-text-info"><b>Resend code in <span>00:{{ formatTimer }}</span></b></p>
    </div>

    <b-button
      type="is-info"
      size="is-medium"
      class="is-rounded mt-6 login-button"
      v-if="countdown == 0"
      expanded @click="resendOtp"
      >Resend OTP</b-button
    >

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import axios from "axios";
export default {
  name: "OnboardingVerify",
  data() {
    return {
      countdown: 59,
      form: {
        email: null,
        otp: null,
      },
      showError: false,
      isLoading: false,
      notification: {
        show: false,
        title: null,
        message: null,
        type: "info",
        loading: false,
      },
      setToast: null,
    }
  },
  computed: {
    formatTimer: function () {
      return (this.countdown < 10) ? `0${this.countdown}` : this.countdown;
    }
  },
  methods: {
    ...mapGetters(["getSignup"]),
    ...mapActions(['SignUp', 'Toaster', 'setupLoading']),
    checkUp(){
      if (!this.form.otp) {
        this.closeToast();
        this.setToast = this.$buefy.toast.open({
          message: "OTP is required!",
          type: "is-warning",
          position: "is-bottom",
          duration: 3000,
        });
        return false
      } else if (this.form.otp.length < 4) {
        this.closeToast();
        this.setToast = this.$buefy.toast.open({
          message: "OTP Code not up to 4 digits",
          type: "is-warning",
          position: "is-bottom",
          duration: 3000,
        });
        return false
      }
      this.submit();
    }, //end of checkUp
    closeToast(){
      if (this.setToast) {
        this.setToast.close();
        this.setToast = null;
      }
    }, //end of closeToast
    async submit () {
      this.setupLoading(true);
      axios
        .post("api/v_1/confirm-otp", this.form)
        .then(() => {
          this.setupLoading(false);
          this.closeToast()
          this.setToast = this.$buefy.toast.open({
            message: "OTP confirmed . . . ",
            type: "is-success",
            position: "is-bottom",
            duration: 3000,
          });
          // console.log(res)
          this.$router.push("/signup/verified");
        })
        .catch((err) => {
          this.setupLoading(false);
          if (err.response.status == "401") {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: "Action failed, authorization required . . . ",
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
            this.manageLogout();
          }
          if (err.response) {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: err.response.data.error.message,
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
          } else if (err.request) {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: JSON.stringify(err.request.data),
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
          } else {
            this.setupLoading(false);
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: err.message,
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
          }
        });
    }, //end of submit
    resendOtp(){
      this.setupLoading(true);
      axios
        .post("api/v_1/resend-otp", this.form)
        .then((response) => {
          this.setupLoading(false);
          this.closeToast()
          this.setToast = this.$buefy.toast.open({
            message: response.data.success.message,
            type: "is-success",
            position: "is-bottom",
            duration: 3000,
          });
          // console.log(res)
          // this.$router.push("/signup/verified");
          this.countdown = 59;
        })
        .catch((err) => {
          this.setupLoading(false);
          if (err.response.status == "401") {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: "Action failed, authorization required . . . ",
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
            this.manageLogout();
          }
          if (err.response) {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: err.response.data.error.message,
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
          } else if (err.request) {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: JSON.stringify(err.request.data),
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
          } else {
            this.setupLoading(false);
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: err.message,
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
          }
        });
    }, //end of resendOtp
    manageLogout(){
      this.$store.dispatch("LogOut");
      this.$router.push("/login");
    }, //manageLogout
    timer(){
      setInterval(() => {
        if (this.countdown > 0) this.countdown--;
      }, 1000);
    }
  },
  mounted() {
    const email = this.getSignup().email;
    this.form.email = email ?? this.$route.params.email;
    if (!this.form.email) {
      this.$router.go(-1);
    }
    this.timer();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.form-input-holder {
  margin: 0 59px;
}


.partitioned-input {
  padding: 0 15px;
  letter-spacing: 50px;
  border: none;
  background-image: linear-gradient(to left, black 70%, rgba(255, 255, 255, 0) 0%);
  background-position: bottom;
  background-size: 50px 1px;
  background-repeat: repeat-x;
  background-position-x: 35px;
  max-width: 200px;
  /*width: 310px;
  min-width: 310px;*/
}
/*
#inner-container {
  left: 0;
  position: sticky;
}

#outer-container {
  width: 190px; 
  overflow: hidden;
}
*/

input {
  border: none !important;
}

</style>
