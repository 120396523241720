<template>
  <div class="container my-6 mx-5">
    <Header />
    <div class="columns has-text-primary">
      <div class="column is-8">
        <div class="mb-5 bold-text is-size-6">Select Payment Option</div>

        <div class="card" style="border-radius: 20px;">
          <div class="card-content">
            <div class="content has-text-centered py-5">
              <div
                class="card has-text-white m-6 is-hidden-touch"
                style="margin: 0 21% !important; border-radius: 20px;"
              >
                <div class="card-content has-background-primary">
                  <p class="regular-text">
                    {{
                      capitalizeFirstChar(
                        $store.getters.getSettings.plans[$route.params.id - 1]
                          .name
                      )
                    }}
                    Plan
                  </p>
                  <h1 class="is-size-2 has-text-white heavy-text">
                    <b
                      >₦{{
                        formatNumber(
                          $store.getters.getSettings.plans[$route.params.id - 1]
                            .amount
                        )
                      }}</b
                    >
                  </h1>
                </div>
              </div>

              <div class="card has-text-white is-hidden-desktop">
                <div class="card-content has-background-primary">
                  <small
                    >{{
                      capitalizeFirstChar(
                        $store.getters.getSettings.plans[$route.params.id - 1]
                          .name
                      )
                    }}
                    Plan</small
                  >
                  <h1 class="is-size-2 has-text-white">
                    <b
                      >₦{{
                        formatNumber(
                          $store.getters.getSettings.plans[$route.params.id - 1]
                            .amount
                        )
                      }}</b
                    >
                  </h1>
                </div>
              </div>

              <b-button
                type="is-primary"
                class="mx-6 mt-6 mb-3 is-hidden-touch"
                size="is-large"
                outlined
                @click="payWithPaystack"
              >
                <img class="p-6" src="../assets/paystack.png" />
              </b-button>
              <b-button
                type="is-primary"
                class="mt-6 mb-3 is-hidden-desktop"
                size="is-large"
                outlined
                @click="payWithPaystack"
              >
                PAY NOW
              </b-button>
              <!--<b-button type="is-primary" class="mx-6 my-3" size="is-large" outlined >
                                <img class="p-6" src="../assets/flutterwave.png" />
                            </b-button>
                            <b-button type="is-primary" class="mx-6 my-3 px-6" size="is-large" outlined >
                                <span class="px-5">Bank Transfer</span>
                            </b-button>-->
              <div
                class="has-text-left has-text-primary ml-6 pl-6"
                v-if="true == false"
              >
                <div class="ml-5">
                  <b>0085859490 Ejsc Inter</b> <br />
                  (May take up to 24hrs to be confirmed)
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-4 mt-6 has-text-primary">
        <div class="card mt-6" style="border-radius: 20px;">
          <div class="card-content">
            <div class="content has-text-centered py-5">
              <div class="has-text-left has-text-primary bold-text mb-6">
                Other Packages
              </div>
              <div
                class="card has-text-white has-background-primary mb-5"
                style="border: 1px solid #003B56;"
                v-for="(plan, index) in otherPlans"
                :key="index"
              >
                <div
                  class="card-content has-text-white"
                  @click="initSubscription(index + 1, plan.amount)"
                >
                  <p class="regular-text">
                    {{ capitalizeFirstChar(plan.name) }} Plan
                  </p>
                  <h1 class="is-size-2 heavy-text has-text-white">
                    <b>₦{{ formatNumber(plan.amount) }}</b>
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Header from "../components/mobile/DashboardComponent/Header";

export default {
  name: "PaymentOption",
  components: {
    Header,
  },
  data() {
    return {
      subscription: {
        plan_id: null,
        amount: null,
      },
      plan_id: null,
      amount: null,
      user: this.$store.getters.getUser,
      setToast: null,
      otherPlans: this.$store.getters.getSettings.plans.filter(
        (plan, index) => index != this.$route.params.id - 1
      ),
    };
  },
  methods: {
    payWithPaystack() {
      this.closeToast();
      this.setToast = this.$buefy.toast.open({
        message: "Redirecting to payment gateway...",
        type: "is-success",
        position: "is-bottom",
        duration: 3000,
      });
      const plan = this.plan_id;
      const handler = PaystackPop.setup({
        key: "pk_live_1f38e452463a33329d0473d8b59316248ab90402",
        email: this.user.email,
        amount: this.amount * 100,
        currency: "NGN",
        ref: Date.now(),
        callback: async function(response) {
          const reference = response.reference;
          const config = {
            method: "post",
            url:
              "https://api.ejsconline.com:442/api/v_1/auth/create-subscription",
            headers: {
              Authorization: localStorage.getItem("user_token"),
              Signature: localStorage.getItem("web_token"),
            },
            data: {
              reference,
              type: "paystack",
              plan_id: plan,
            },
          };
          axios(config)
            .then(() => {
              window.location.href = `${
                window.location.href.split("#")[0]
              }#/home/payment/confirmed-payment?plan=${plan}`;
            })
            .catch(() => {
              alert("Something went wrong with payment reference update!");
            });
        },
        onClose: function() {},
      });
      handler.openIframe();
    }, //end of payWithPaystack
    closeToast() {
      if (this.setToast) {
        this.setToast.close();
        this.setToast = null;
      }
    }, //end of closeToast
    capitalizeFirstChar(char) {
      return `${char.charAt(0).toUpperCase()}${char.slice(1)}`;
    }, //end of capitalizeFirstChar
    initSubscription(id, amount) {
      this.$router.push({
        name: "AuthPayment",
        params: { id: id, amount: amount },
        query: { s: Date.now },
      });
    }, //end of initSubscription
    formatNumber(num) {
      return `${new Intl.NumberFormat("en-US").format(num)}.00`;
    }, //end of formatNumber
  },
  mounted() {
    const plan_id = this.$route.params.id;
    const amount = this.$route.params.amount;
    if (plan_id && amount) {
      this.plan_id = plan_id;
      this.amount = amount;
    } else {
      // this.$router.push("/home");
    }
  },
};
</script>
<style scoped></style>
