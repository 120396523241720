<template>
    <section>
        <b-loading :is-full-page="true" v-model="$store.getters.getLoading" :can-cancel="false"></b-loading>
    </section>
</template>

<script>
export default {
    name: "Notification",
    props: {
        isLoading: {
            type: Boolean,
            default: true,
        },
        content: {
            type: String,
            default: "Welcome to EJSC",
        },
        contentType: {
            type: String,
            default: "is-success",
        },
        indefiniteToast: {
            type: Boolean,
            default: true,
        },
        duration: {
            type: Number,
            default: 2000,
        }
    },
    methods: {
        showToast () {
            this.$buefy.toast.open({
                indefinite: this.indefiniteToast,
                message: this.content,
                type: this.contentType,
                position: "is-bottom",
                duration: this.duration,
            })
        }, //end of showToast
    },
    mounted() {
        // this.showToast();
    },
}
</script>