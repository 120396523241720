<template>
  <div>
    <div class="is-hidden-touch" v-if="getViewPort() >= 1000">
      <div class="container my-6 mx-6">
        <Header />
        <b-progress
          v-if="!isPageReady"
          type="is-primary"
          class="mt-4"
        ></b-progress>
        <div class="columns has-text-primary" v-if="isPageReady">
          <div class="column">

            <div>
              <div class="columns ">
                <div class="column">
                  <div class="card mt-6">
                    <div class="card-content">
                      <div class="columns mb-2 py-2 separator">
                        <div class="column">
                          <p class="has-text-primary bold-text" v-if="this.$route.params.type.toLowerCase() === 'rule'">RULES OF COURT</p>
                          <p class="has-text-primary bold-text" v-else>LAWS OF THE FEDERATION</p>
                        </div>
                      </div>
                      <div
                        class="columns is-mobile px-5 py-2 separator"
                        v-for="rules in rulesOfCourt"
                        :key="rules.id"
                      >
                        <div class="column">
                          <a
                            target="_blank"
                            :href="rules.link"
                            class="has-text-primary"
                          >
                            <p class="has-text-left regular-text">
                              {{ rules.title }}
                            </p>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>

            </div>

          </div>
        </div>
      </div>
    </div>
    <div class="is-hidden-desktop" v-else>
      <RulesMobile />
    </div>
  </div>
</template>

<script>
import Header from "../components/DashboardComponent/Header";
import RulesMobile from "../pages_mobile/RulesOfCourt";
import axios from "axios";

export default {
  name: "RulesOfCourt",
  components: {
    Header,
    RulesMobile,
  },
  data() {
    return {
      profile: {
        firstname: null,
        lastname: null,
        email: null,
        mobile: null,
        address: null,
        image_url: null,
        subscription: {
          subscription_status: false,
        },
        recent_cases: null,
        recent_search: null,
        recent_activities: null,
        promotions: null,
      },
      isLoading: false,
      notification: {
        show: false,
        title: null,
        message: null,
        type: "info",
        loading: false,
      },
      setToast: null,
      isPageReady: false,
      rulesOfCourt: null,
    };
  },
  methods: {
    getViewPort() {
      return window.innerWidth;
    }, //end of getViewPort
    getRules() {
      this.closeToast();
      this.setToast = this.$buefy.toast.open({
        message: `Getting ${this.$route.params.type} please wait . . . `,
        type: "is-success",
        position: "is-bottom",
        indefinite: false,
      });

      const config = {
        method: "get",
        url: "https://api.ejsconline.com:442/api/v_1/ejsc-rules",
        headers: {
          Authorization: localStorage.getItem("user_token"),
          Signature: "2b72e1a-55d3-4afd-811f-63f24",
        },
      };
      axios(config)
        .then((res) => {
          const result = res.data.success.data;
          this.rulesOfCourt = result.filter(rule => rule.type.toLowerCase() === this.$route.params.type.toLowerCase());
          this.isPageReady = true;
        })
        .catch((err) => {
          if (
            (err.response && err.response.status == "401") ||
            err.response.data.error.message.includes("Invalid Request")
          ) {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: "Action failed, authorization required . . . ",
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
            this.manageLogout();
          } else if (err.response && err.response.status != "401") {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: err.response.data.error.message,
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
          } else if (err.request) {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: JSON.stringify(err.request.data),
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
          } else {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: err.message,
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
          }
        });
    }, //end of getRules
    manageLogout() {
      this.$store.dispatch("LogOut");
      this.$router.push("/login");
    }, //manageLogout
    closeToast() {
      if (this.setToast) {
        this.setToast.close();
        this.setToast = null;
      }
    }, //end of closeToast
  },
  mounted() {
    //call getProfileMethod
    this.getRules();
    // this.rulesOfCourt = RULES_OF_COURT.filter(rule => rule.type.toLowerCase() === this.$route.params.type.toLowerCase());
    // this.isPageReady = true;
  },
};
</script>

<style scoped>
@font-face {
  font-family: "MontBold";
  src: url("../fonts/Mont/Mont-Bold.otf");
}

@font-face {
  font-family: "MontRegular";
  src: url("../fonts/Mont/Mont-Regular.otf");
}

@font-face {
  font-family: "MontLight";
  src: url("../fonts/Mont/Mont-Light.otf");
}

.bold-text {
  font-family: "MontBold";
}

.regular-text {
  font-family: "MontRegular";
}

.light-text {
  font-family: "MontLight";
}

.card {
  border-radius: 20px;
}

.card-content {
  padding-left: 0;
  padding-right: 0;
}

.separator {
  border-bottom: 1px solid #dfe0eb;
  padding-left: 85px !important;
  padding-right: 85px !important;
  margin: 0;
}

.separator-t {
  border-bottom: 1px solid #dfe0eb;
  padding-left: 40px !important;
  padding-right: 40px !important;
  margin: 0;
}
</style>
