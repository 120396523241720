<template>
  <div class="block" style="padding: 0 10%;">
    <div class="container">
      <section class="section">
        <h2 class="is-size-2 has-text-white has-text-centered py-5 my-6">
          <b class="bold-text">{{page}}</b>
        </h2>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "GeneralHero",
  props: {
    page: {type: String, default: "About"},
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  @font-face {
    font-family: "MontBold";
    src: url("../../fonts/Mont/Mont-Bold.otf");
  }

  .bold-text {
    font-family: "MontBold";
  }

  .block {
    background-image: url("../../assets/hero_bg.png");
  }
</style>
