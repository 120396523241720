<template>
  <div class="block" style="padding: 0 10%;">
    <div class="container">
      <section class="section my-6">
        <div class="columns has-text-primary">
          <div class="column">
            <div id="content">
                <strong class="has-text-info is-size-5 bold-text">Privacy Policy</strong>
                <p class="my-4">
                    Legal Jurisprudence built the EJSC app as a Free app. 
                    The SERVICES provided by the app is bound by certain terms and conditions and is intended for use as is.
                </p>
                <p class="my-4">
                    This page is used to inform visitors regarding our policies with the collection, use, and disclosure of 
                    Personal Information if anyone decided to use our Service.
                </p>
                <p class="my-4">
                    If you choose to use our Service, then you agree to the collection and use of information in relation to 
                    this policy. The Personal Information that we collect is used for providing and improving the Service. 
                    We will not use or share your information with anyone except as described in this Privacy Policy.
                </p>
                <p class="my-4">
                    The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which is 
                    accessible on the <a href="https://portal.ejsconline.com">EJSC Portal</a> unless otherwise defined in 
                    this Privacy Policy
                </p>
                <strong class="has-text-info is-size-5 bold-text">Information Collection and Use</strong>
                <p class="my-4">
                    For a better experience, while using our Service, we may require you to provide us with certain personally 
                    identifiable information, including but not limited to Picture, Name, Contact Information and some 
                    demographic data. The information that we request will be retained on our server for ease of access.
                </p>
                <p class="my-4">
                    The app does use third party services that may collect information used to identify you.
                </p>
                <p class="my-4">
                    Link to privacy policy of third party service providers used by the app
                </p>
                <ul>
                    <li>
                        <a target="_blank" href="https://www.google.com/policies/privacy/">— Google Play Services</a>
                    </li>
                    <li>
                        <a target="_blank" href="https://www.zendesk.com/company/agreements-and-terms/privacy-policy/">— ZenDesk Chat</a>
                    </li>
                    <li>
                        <a target="_blank" href="https://paystack.com/terms/">— Paystack</a>
                    </li>
                </ul>
                <strong class="has-text-info is-size-5 bold-text">Log Data</strong>
                <p class="my-4">
                    We want to inform you that whenever you use our Service, We collect data and information 
                    on your phone called Log Data. This Log Data may include information such as your device 
                    Internet Protocol (“IP”) address, device name, operating system version, the configuration 
                    of the app when utilizing my Service, the time and date of your use of the Service, and 
                    other statistics.
                </p>
                <strong class="has-text-info is-size-5 bold-text">Cookies</strong>
                <p class="my-4">
                    Cookies are files with a small amount of data that are commonly used as anonymous unique 
                    identifiers. These are sent to your browser from the websites that you visit and are stored 
                    on your device's internal memory.
                </p>
                <p class="my-4">
                    This Service does not use these “cookies” explicitly. However, the app may use third party code 
                    and libraries that use “cookies” to collect information and improve their services. 
                    You have the option to either accept or refuse these cookies and know when a cookie is 
                    being sent to your device. If you choose to refuse our cookies, you may not be able to 
                    use some portions of this Service.
                </p>
                <strong class="has-text-info is-size-5 bold-text">Service Providers</strong>
                <p class="my-4">
                    We may employ third-party companies and individuals due to the following reasons:
                </p>
                <ul>
                    <li>— To facilitate our Service; or</li>
                    <li>— To assist us in analyzing how our Service is used.</li>
                </ul>
                <p class="my-4">
                    We want to inform users of this Service that these third parties have limited access to your 
                    Personal Information. The reason is to perform the tasks assigned to them on our behalf. 
                    However, they are obligated not to disclose or use the information for any other purpose.
                </p>
                <strong class="has-text-info is-size-5 bold-text">Security</strong>
                <p class="my-4">
                    We value your trust in providing us your Personal Information, thus we are striving to use 
                    commercially acceptable means of protecting it. But remember that no method of transmission 
                    over the internet, or method of electronic storage is 100% secure and reliable, and we 
                    cannot guarantee its absolute security.
                </p>
                <strong class="has-text-info is-size-5 bold-text">Links to Other Sites</strong>
                <p class="my-4">
                    This Service may contain links to other sites. If you click on a third-party link, you will be 
                    directed to that site. Note that these external sites are not operated by us. Therefore, we 
                    strongly advise you to review the Privacy Policy of these websites. We have no control over and 
                    assume no responsibility for the content, privacy policies, or practices of any third-party sites 
                    or services.
                </p>
                <strong class="has-text-info is-size-5 bold-text">Children's Privacy</strong>
                <p class="my-4">
                    These Services do not address anyone under the age of 13. We do not knowingly collect personally 
                    identifiable information from children under 13. In the case we discover that a child under 13 has 
                    provided us with personal information, we immediately delete this from our servers. If you are a parent 
                    or guardian and you are aware that your child has provided us with personal information, 
                    please contact us so that we will be able to do necessary actions.
                </p>
                <strong class="has-text-info is-size-5 bold-text">Changes to This Privacy Policy</strong>
                <p class="my-4">
                    We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically 
                    for any changes. We will notify you of any changes by posting the new Privacy Policy on this page.
                </p>
                </p>
                    This policy is effective as of 2021-08-05
                <p class="my-4">
                <strong class="has-text-info is-size-5 bold-text">Contact Us</strong>
                <p class="my-4">
                    If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at <a mailto="contact@ejsconline.com">contact@ejsconline.com</a>.
                </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "PrivacyPolicy",
  
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
