<template>
  <div class="block">
    <div class="is-hidden-touch" style="padding: 0 10%; margin-bottom: -2%;">
      <div class="container has-text-white">
        <section class="section">
          <div class="content columns mt-6">
            <div class="column has-text-centered">
              <router-link to="/team/isaac">
                <figure class="image is-1by1">
                  <img
                    src="../../assets/teams/isaac_1.png"
                    class="is-rounded"
                    alt="Team Member"
                  />
                </figure>
                <h5 class="is-size-6 bold-text has-text-primary mt-3">
                  <b>Isaac </b>
                </h5>
                <p class="regular-text has-text-primary">Editor-In-Chief</p>
              </router-link>
            </div>

            <div class="column has-text-centered">
              <router-link to="/team/funmi">
                <figure class="image is-1by1">
                  <img
                    src="../../assets/teams/funmi.png"
                    class="is-rounded"
                    alt="Team Member"
                  />
                </figure>
                <h5 class="is-size-6 bold-text has-text-primary mt-3">
                  <b>Oluwafunmilayo </b>
                </h5>
                <p class="regular-text has-text-primary">
                  Head of Publication/Head of Chambers, Ogbah Isaac &amp; Co
                </p>
              </router-link>
            </div>

            <div class="column has-text-centered">
              <router-link to="/team/lilian">
                <figure class="image is-1by1">
                  <img
                    src="../../assets/teams/lilian.png"
                    class="is-rounded"
                    alt="Team Member"
                  />
                </figure>
                <h5 class="is-size-6 bold-text has-text-primary mt-3">
                  <b>Nwamaka </b>
                </h5>
                <p class="regular-text has-text-primary">
                  Deputy Editor-In-Chief
                </p>
              </router-link>
            </div>
          </div>

          <div class="content columns mt-6">
            <div class="column has-text-centered">
              <router-link to="/team/onwumah">
                <figure class="image is-1by1">
                  <img
                    src="../../assets/teams/onwumah.png"
                    class="is-rounded"
                    alt="Team Member"
                  />
                </figure>
                <h5 class="is-size-6 bold-text has-text-primary mt-3">
                  <b>Chibueze </b>
                </h5>
                <p class="regular-text has-text-primary">
                  Assistant Deputy Editor-In-Chief
                </p>
              </router-link>
            </div>

            <div class="column has-text-centered">
              <router-link to="/team/umana">
                <figure class="image is-1by1">
                  <img
                    src="../../assets/teams/umana.png"
                    class="is-rounded"
                    alt="Team Member"
                  />
                </figure>
                <h5 class="is-size-6 bold-text has-text-primary mt-3">
                  <b>Kokoete</b>
                </h5>
                <p class="regular-text has-text-primary">Reportorial Jurist</p>
              </router-link>
            </div>

            <div class="column has-text-centered">
              <router-link to="/team/ibiyinka">
                <figure class="image is-1by1">
                  <img
                    src="../../assets/teams/ibiyinka.png"
                    class="is-rounded"
                    alt="Team Member"
                  />
                </figure>
                <h5 class="is-size-6 bold-text has-text-primary mt-3">
                  <b>Ibiyinka</b>
                </h5>
                <p class="regular-text has-text-primary">Reportorial Jurist</p>
              </router-link>
            </div>
          </div>

          <div class="content columns my-6">
            <div class="column has-text-centered">
              <router-link to="/team/hauwa">
                <figure class="image is-1by1">
                  <img
                    src="../../assets/teams/hauwa.png"
                    class="is-rounded"
                    alt="Team Member"
                  />
                </figure>
                <h5 class="is-size-6 bold-text has-text-primary mt-3">
                  <b>Hauwa </b>
                </h5>
                <p class="regular-text has-text-primary">Reportorial Jurists</p>
              </router-link>
            </div>

            <div class="column has-text-centered">
              <router-link to="/team/amaechi">
                <figure class="image is-1by1">
                  <img
                    src="../../assets/teams/amaechi.png"
                    class="is-rounded"
                    alt="Team Member"
                  />
                </figure>
                <h5 class="is-size-6 bold-text has-text-primary mt-3">
                  <b>Amaechi </b>
                </h5>
                <p class="regular-text has-text-primary">Reportorial Jurists</p>
              </router-link>
            </div>

            <div class="column has-text-centered">
              <router-link to="/team/dotun">
                <figure class="image is-1by1">
                  <img
                    src="../../assets/teams/dotun.png"
                    class="is-rounded"
                    alt="Team Member"
                  />
                </figure>
                <h5 class="is-size-6 bold-text has-text-primary mt-3">
                  <b>Oludotun </b>
                </h5>
                <p class="regular-text has-text-primary">Reportorial Jurists</p>
              </router-link>
            </div>
          </div>

          <div class="content columns my-6">
            <div class="column has-text-centered">
              <router-link to="/team/faith">
                <figure class="image is-1by1">
                  <img
                    src="../../assets/teams/faith.png"
                    class="is-rounded"
                    alt="Team Member"
                  />
                </figure>
                <h5 class="is-size-6 bold-text has-text-primary mt-3">
                  <b>Faith </b>
                </h5>
                <p class="regular-text has-text-primary">
                  Computer Transcriptionist
                </p>
              </router-link>
            </div>

            <div class="column has-text-centered">
              <router-link to="/team/samson">
                <figure class="image is-1by1">
                  <img
                    src="../../assets/teams/samson.png"
                    class="is-rounded"
                    alt="Team Member"
                  />
                </figure>
                <h5 class="is-size-6 bold-text has-text-primary mt-3">
                  <b>Oluwadunni </b>
                </h5>
                <p class="regular-text has-text-primary">Marketing</p>
              </router-link>
            </div>

            <div class="column has-text-centered">
              <router-link to="/team/olamide">
                <figure class="image is-1by1">
                  <img
                    src="../../assets/teams/olamide.png"
                    class="is-rounded"
                    alt="Team Member"
                  />
                </figure>
                <h5 class="is-size-6 bold-text has-text-primary mt-3">
                  <b>Olamide </b>
                </h5>
                <p class="regular-text has-text-primary">Marketing</p>
              </router-link>
            </div>
          </div>

          <div class="content columns my-6">
            <div class="column has-text-centered">
              <router-link to="/team/uche">
                <figure class="image is-1by1">
                  <img
                    src="../../assets/teams/uche.png"
                    class="is-rounded"
                    alt="Team Member"
                  />
                </figure>
                <h5 class="is-size-6 bold-text has-text-primary mt-3">
                  <b>Uchenna </b>
                </h5>
                <p class="regular-text has-text-primary">
                  Computer Transcriptionist
                </p>
              </router-link>
            </div>

            <div class="column has-text-centered">
              <router-link to="/team/ebere">
                <figure class="image is-1by1">
                  <img
                    src="../../assets/teams/ebere.png"
                    class="is-rounded"
                    alt="Team Member"
                  />
                </figure>
                <h5 class="is-size-6 bold-text has-text-primary mt-3">
                  <b>Wisdom </b>
                </h5>
                <p class="regular-text has-text-primary">
                  IT/ICT Resource manager
                </p>
              </router-link>
            </div>

            <div class="column has-text-centered">
              <router-link to="/team/feyi">
                <figure class="image is-1by1">
                  <img
                    src="../../assets/teams/feyi.png"
                    class="is-rounded"
                    alt="Team Member"
                  />
                </figure>
                <h5 class="is-size-6 bold-text has-text-primary mt-3">
                  <b>Oluwafeyijimi </b>
                </h5>
                <p class="regular-text has-text-primary">
                  Computer Transcriptionist
                </p>
              </router-link>
            </div>
          </div>

          <div class="content columns my-6">
            <div class="column has-text-centered">
              <router-link to="/team/blessing">
                <figure class="image is-1by1">
                  <img
                    src="../../assets/teams/blessing.png"
                    class="is-rounded"
                    alt="Team Member"
                  />
                </figure>
                <h5 class="is-size-6 bold-text has-text-primary mt-3">
                  <b>Blessing </b>
                </h5>
                <p class="regular-text has-text-primary">Secretary</p>
              </router-link>
            </div>

            <div class="column has-text-centered">
              <router-link to="/team/janet">
                <figure class="image is-1by1">
                  <img
                    src="../../assets/teams/janet.png"
                    class="is-rounded"
                    alt="Team Member"
                  />
                </figure>
                <h5 class="is-size-6 bold-text has-text-primary mt-3">
                  <b>Janet </b>
                </h5>
                <p class="regular-text has-text-primary">Store Manager</p>
              </router-link>
            </div>

            <div class="column has-text-centered"></div>

            <!-- 3rd column -->
          </div>
        </section>
      </div>
    </div>
    <div class="is-hidden-desktop">
      <TemasMobile />
    </div>
  </div>
</template>

<script>
import TemasMobile from "../mobile/TeamComponent/Teams";
export default {
  name: "Teams",
  components: {
    TemasMobile,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@font-face {
  font-family: "MontBold";
  src: url("../../fonts/Mont/Mont-Bold.otf");
}

@font-face {
  font-family: "MontRegular";
  src: url("../../fonts/Mont/Mont-Regular.otf");
}

.bold-text {
  font-family: "MontBold";
}

.regular-text {
  font-family: "MontRegular";
}
</style>
