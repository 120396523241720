<template>
  <div class="block" style="padding: 0 10%;">
    <div class="container">
      <section class="section mt-6">
        <div class="columns is-desktop has-text-primary">
          <div class="column">
            <img alt="Team" src="../../assets/teams/amaechi.png" />
          </div>
          <div class="column is-three-fifths ml-5 pl-5">
            <h3 class="is-size-3 bold-text mt-3 pt-5"><b>Amaechi Uchechukwu Stanley</b></h3>
            <p class="regular-text has-text-primary mt-5">
              Amaechi Uchechukwu Stanley is a legal practitioner from the University of Lagos with a Bachelor of Law Degree(LL.B) Hons. He is currently an associate at the Law Firm of Ogbah Isaac &amp; Co. and also a reportorial jurist at Legal Jurisprudence Ltd.  His area of legal practice includes Property Law, Corporate Law, Intellectual Property Law, Family Law and Human Right.
            </p>
            <p class="regular-text has-text-primary mt-3">
                He is also a member of the International Security Organization (ISO-SEC), wherein he obtained a certification in Disaster Management Specialization, Risk Management, Crime prevention through Environmental Design and Certified Security Supervisor.
            </p>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "TeamInfo",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  @font-face {
    font-family: "MontBold";
    src: url("../../fonts/Mont/Mont-Bold.otf");
  }

  @font-face {
    font-family: "MontRegular";
    src: url("../../fonts/Mont/Mont-Regular.otf");
  }

  .bold-text {
    font-family: "MontBold";
  }

  .regular-text {
    font-family: "MontRegular";
  }
</style>
