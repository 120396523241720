<template>
  <div v-if="!$store.getters.getLoading" style="background-color: #F7F8FA;">
    <div class="is-hidden-touch" v-if="getViewPort() >= 1000">
      <navbar />
      <Cta />
      <Notice />
      <WhoWeAre />
      <RecentPublications />
      <Hero />
      <Pricing />
      <Footer />
    </div>
    <div class="is-hidden-desktop" v-else>
      <NavMobile />
      <CtaMobile />
      <NoticeMobile />
      <WhoWeAreMobile />
      <RecentPublicationsMobile />
      <HeroMobile />
      <PricingMobile />
      <FooterMobile />
    </div>
  </div>
</template>

<script>
// import HelloWorld from '../components/HelloWorld'
import Navbar from "../components/GeneralComponent/Navbar";
import NavMobile from "../components/mobile/GeneralComponent/Navbar";
import Cta from "../components/HomeComponent/Cta";
import CtaMobile from "../components/mobile/HomeComponent/CtaMobile";
import Notice from "../components/HomeComponent/Notice";
import NoticeMobile from "../components/mobile/HomeComponent/NoticeMobile";
import WhoWeAre from "../components/HomeComponent/WhoWeAre";
import WhoWeAreMobile from "../components/mobile/HomeComponent/WhoWeAre";
import RecentPublications from "../components/GeneralComponent/RecentPublications";
import RecentPublicationsMobile from "../components/mobile/GeneralComponent/RecentPublications";
import Hero from "../components/HomeComponent/Hero";
import HeroMobile from "../components/mobile/HomeComponent/Hero";
import Pricing from "../components/HomeComponent/Pricing";
import PricingMobile from "../components/mobile/HomeComponent/Pricing";
import Footer from "../components/GeneralComponent/Footer";
import FooterMobile from "../components/mobile/GeneralComponent/Footer";

export default {
  name: "Home",

  components: {
    // HelloWorld,
    Navbar,
    NavMobile,
    Cta,
    CtaMobile,
    Notice,
    NoticeMobile,
    WhoWeAre,
    WhoWeAreMobile,
    RecentPublications,
    RecentPublicationsMobile,
    Hero,
    HeroMobile,
    Pricing,
    PricingMobile,
    Footer,
    FooterMobile,
  },
  methods: {
    getViewPort() {
      return (window.innerWidth);
    }, //end of getViewPort
  },
};
</script>
