<template>
  <div class="form-input-holder">
    <div class="has-text-centered">
      <img src="../../../assets/onboarding0.png" alt="Onboarding Progress" /> <br />
      <img src="../../../assets/EJSClogo2_new.png" width="65" class="mt-6" alt="EJSC Logo" />
      <h3 class="is-size-4 has-text-primary mt-5 heavy-text"><b>Hello there!</b></h3>
      <p class="content has-text-primary">
        Let's get to know you
      </p>
    </div>

    <div class="field mt-6">
      <label class="label name-label has-text-primary">First Name</label>
      <div class="control">
        <input class="input is-rounded" type="text" v-model="form.firstname" />
        <span class="is-size-7" style="display: none;">Please Enter your first name</span>
      </div>
    </div>

    <div class="field mt-5">
      <label class="label name-label has-text-primary">Last Name</label>
      <div class="control">
        <input class="input is-rounded" type="text" v-model="form.lastname" />
        <span class="is-size-7" style="display: none;">Please Enter your last name</span>
      </div>
    </div>

    <b-button
      type="is-primary"
      size="is-medium"
      class="is-rounded mt-6 login-button"
      expanded
      @click="checkUp"
      >Proceed</b-button
    >

    <b-button rounded class="is-ghost has-text-primary mt-3" expanded v-if="true == false">
      <router-link to="/signup/continue" class="has-text-primary">
        continue registration?
      </router-link>
    </b-button>

  </div>
</template>

<script>
  import { mapActions } from "vuex";

  export default {
    name: "OnboardingName",
    data() {
      return {
        form: {
          firstname: null,
          lastname: null,
        },
        showError: false,
        setToast: null,
      }
    },
    methods: {
      ...mapActions(['SignUp', 'Toaster']),
      checkUp(){
        if (!this.form.firstname && !this.form.lastname) {
          this.closeToast();
          this.setToast = this.$buefy.toast.open({
            message: "First name and Last name are both required",
            type: "is-warning",
            position: "is-bottom",
            duration: 3000,
          });
          return false;
        } else if (!this.form.lastname) {
          this.closeToast();
          this.setToast = this.$buefy.toast.open({
            message: "Last name is required",
            type: "is-warning",
            position: "is-bottom",
            duration: 3000,
          });
          return false;
        }
        else if (!this.form.firstname) {
          this.closeToast();
          this.setToast = this.$buefy.toast.open({
            message: "First name is required",
            type: "is-warning",
            position: "is-bottom",
            duration: 3000,
          });
          return false;
        }
        this.proceed();
      }, //end of checkup
      proceed () {
        this.SignUp({...this.form});
        this.$router.push({
          name: "OnboardingContact",
        });
      }, //end of proceed
      closeToast(){
        if (this.setToast) {
          this.setToast.close();
          this.setToast = null;
        }
      }, //end of closeToast
    },
    mounted() {
      this.form = {...this.$store.getters.getSignup};
    },
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.form-input-holder {
  margin: 0 59px;
}

input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(13, 35, 51, 0.69);
  opacity: 1; /* Firefox */
}
input {
  background: rgba(196, 196, 196, 0.17);
  border: none;
}
.login-button:hover {
  filter: drop-shadow(0 0 10px #003b56);
}
</style>
