<template>
  <div class="block" style="padding: 0 10%; background-color: #F8F9FD;">
    <div class="container">
      <section class="section">
        <div class="columns is-desktop has-text-primary pt-6">
          <div class="column is-three-fifth pr-6">
            <h3 class="is-size-3 bold-title has-text-info">The Erudite Judgments of the Supreme Court (EJSC)</h3>
            <p class="is-size-6 has-text-primary mt-5 light-text has-text-info has-text-justified" style="color: #7C8087;">
              Published by Legal Jurisprudence Limited emerged under the editorship of Isaac Ogbah, FICMC, 
              in January 2015. Our mission is to provide the legal community with up to date 
              judgments of the Supreme Court and to support legal research and study needs of students, 
              faculty and staff of various Nigerian Universities and law schools as well as other professional bodies.
            </p>
            <b-button rounded class="is-primary mt-4" @click="$router.push('/about')">Learn More</b-button>
          </div>
          <div class="column pl-6 ml-6">
            <img alt="Lawyer" src="../../assets/whoweare_desktop.png">
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "WhoWeAre",
  
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  @font-face {
    font-family: "MontBold";
    src: url("../../fonts/Mont/Mont-Bold.otf");
  }

  @font-face {
    font-family: "MontLight";
    src: url("../../fonts/Mont/Mont-Light.otf");
  }

  .bold-title {
    font-family: "MontBold";
  }

  .light-text {
    font-family: "MontLight";
  }

  .block {
    /*background-image: url("../../assets/erudite_bg.png");*/
  }
</style>
