<template>
  <div class="block" style="background-color: #F8F9FD; margin-top: -50px;">
    <div class="container">
      <section class="section">
        <h2 class="is-size-3 bold-text has-text-info">
          Flexible Pricing Plan
        </h2>
        <p class="light-text has-text-info">
          Choose a package that suits you below
        </p>
      </section>
      <section class="section">
        <div class="columns">
          <div class="column" v-for="(plan, index) in plans" :key="index" v-if="index < 3">
            <div class="mr-4" v-if="index === 0">
              <div class="">
                <div class="card">
                  <div class="card-content">
                    <div class="content has-text-centered py-5">
                      <p class="has-text-primary hover-text regular-text">{{ capitalizeFirstChar(plan.name) }} </p>
                      <h1 class="is-size-2 has-text-primary hover-text heavy-text">N{{ formatNumber(plan.amount) }}</h1>
                      <button class="button is-primary is-rounded is-outlined mb-4 button-hover regular-text" @click="initSubscription(1, plan.amount)">Subscribe</button>
                      <ul class="has-text-left has-text-primary hover-text" >
                        <li>12 Months / 1 Year</li>
                        <li>Full access to legal research</li>
                        <li>Cases with principles</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="mr-4" v-else-if="index === 1">
              <div class="">
                <div class="card">
                  <div class="card-content">
                    <div class="content has-text-centered py-5">
                      <p class="has-text-primary hover-text regular-text">{{ capitalizeFirstChar(plan.name) }} Plan</p>
                      <h1 class="is-size-1 has-text-primary hover-text heavy-text">N{{ formatNumber(plan.amount) }}</h1>
                      <button class="button is-primary is-rounded is-outlined mb-4 button-hover regular-text" @click="initSubscription(2, plan.amount)">Subscribe</button>
                      <ul class="has-text-left has-text-primary hover-text">
                        <li>1 Month</li>
                        <li>Full access to legal research</li>
                        <li>Cases with principles</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="" v-else-if="index === 2">
              <div class="card">
                <div class="card-content">
                  <div class="content has-text-centered py-5">
                    <p class="has-text-primary hover-text regular-text">{{ capitalizeFirstChar(plan.name) }} Plan</p>
                    <h1 class="is-size-1 has-text-primary hover-text heavy-text">N{{ formatNumber(plan.amount) }}</h1>
                    <button class="button is-primary is-rounded is-outlined mb-4 button-hover regular-text" @click="initSubscription(3, plan.amount)">Subscribe</button>
                    <ul class="has-text-left has-text-primary hover-text" >
                      <li>3 Months</li>
                      <li>Full access to legal research</li>
                      <li>Cases with principles</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div class="" v-else>
              <div class="card mt-2">
                <div class="card-content">
                  <div class="content has-text-centered py-5">
                    <p class="has-text-primary hover-text regular-text">{{ capitalizeFirstChar(plan.name) }} Plan</p>
                    <h1 class="is-size-1 has-text-primary hover-text heavy-text">N{{ formatNumber(plan.amount) }}</h1>
                    <button class="button is-primary is-rounded is-outlined mb-4 button-hover regular-text" @click="initSubscription(3, plan.amount)">Subscribe</button>
                    <ul class="has-text-left has-text-primary hover-text">
                      <li>3 Months</li>
                      <li>Full access to legal research</li>
                      <li>Cases with principles</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="columns">
          <div class="column is-one-third" v-for="(plan, index) in plans" :key="index" v-if="index >= 3">
            <div class="mt-6 mr-4" v-if="index === 3">
              <div class="">
                <div class="card">
                  <div class="card-content">
                    <div class="content has-text-centered py-5">
                      <p class="has-text-primary hover-text regular-text">{{ capitalizeFirstChar(plan.name) }} Plan</p>
                      <h1 class="is-size-2 has-text-primary hover-text heavy-text">N{{ formatNumber(plan.amount) }}</h1>
                      <button class="button is-primary is-rounded is-outlined mb-4 button-hover regular-text" @click="initSubscription(1, plan.amount)">Subscribe</button>
                      <ul class="has-text-left has-text-primary hover-text">
                        <li>12 Months / 1 Year</li>
                        <li>Full access to legal research</li>
                        <li>Cases with principles</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="mt-6 mr-4" v-else-if="index === 4">
              <div class="">
                <div class="card">
                  <div class="card-content">
                    <div class="content has-text-centered py-5">
                      <p class="has-text-primary hover-text regular-text">{{ capitalizeFirstChar(plan.name) }} Plan</p>
                      <h1 class="is-size-2 has-text-primary hover-text heavy-text">N{{ formatNumber(plan.amount) }}</h1>
                      <button class="button is-primary is-rounded is-outlined mb-4 button-hover regular-text" @click="initSubscription(1, plan.amount)">Subscribe</button>
                      <ul class="has-text-left has-text-primary hover-text">
                        <li>6 Months</li>
                        <li>Full access to legal research</li>
                        <li>Cases with principles</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="mt-6 mr-4" v-else-if="index > 4">
              <div class="">
                <div class="card">
                  <div class="card-content">
                    <div class="content has-text-centered py-5">
                      <p class="has-text-primary hover-text regular-text">{{ capitalizeFirstChar(plan.name) }} Plan</p>
                      <h1 class="is-size-2 has-text-primary hover-text heavy-text">N{{ formatNumber(plan.amount) }}</h1>
                      <button class="button is-primary is-rounded is-outlined mb-4 button-hover regular-text" @click="initSubscription(1, plan.amount)">Subscribe</button>
                      <ul class="has-text-left has-text-primary hover-text">
                        <li>6 Months</li>
                        <li>Full access to legal research</li>
                        <li>Cases with principles</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "Pricing",
  data() {
    return {
      plans: null,
    }
  },
  methods: {
    capitalizeFirstChar(char) {
      return `${char.charAt(0).toUpperCase()}${char.slice(1)}`;
    }, //end of capitalizeFirstChar
    formatNumber (num) {
      return `${new Intl.NumberFormat('en-US').format(num)}.00`;
    }, //end of formatNumber
    initSubscription(id, amount) {
      this.$router.push({
        name: "AuthPayment",
        params: { id: id, amount: amount },
      });
    }, //end of initSubscription

    adjustPackage () {
      const allPlans = this.$store.getters.getSettings.plans;
      const noPromo = allPlans.filter(plan => !plan.name.toLowerCase().includes("promo"));
      const withPromo = allPlans.filter(plan => plan.name.toLowerCase().includes("promo"));
      this.plans = [...withPromo, ...noPromo];
    }, //end of adjustPackage
  },
  mounted() {
    this.adjustPackage();
    // console.log(this.$store.getters.getSettings.plan[0].name)
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  @font-face {
    font-family: "MontBold";
    src: url("../../../fonts/Mont/Mont-Bold.otf");
  }

  @font-face {
    font-family: "MontHeavy";
    src: url("../../../fonts/Mont/Mont-Heavy.otf");
  }

  @font-face {
    font-family: "MontLight";
    src: url("../../../fonts/Mont/Mont-Light.otf");
  }

  @font-face {
    font-family: "MontRegular";
    src: url("../../../fonts/Mont/Mont-Regular.otf");
  }

  .bold-text {
    font-family: "MontBold";
  }

  .light-text {
    font-family: "MontLight";
  }

  .heavy-text {
    font-family: "MontHeavy";
  }

  .regular-text {
    font-family: "MontRegular";
  }

  .card:hover{
    background-color: #003B56 !important;
    color: white !important;
    filter: drop-shadow(0 0 10px #003B56);
  }

  .card:hover .hover-text{
    background-color: #003B56 !important;
    color: white !important;
  }

  .card:hover .button-hover{
    background-color: white !important;
    color: #003B56 !important;
  }

  .card {
    border-radius: 10px !important;
  }
</style>