<template>
  <div class="block has-background-info" style="padding: 0 ; margin-top: -40px">
    <div class="container has-text-white pb-4">
      <footer class="footer has-background-info" style="margin-bottom: 0 !important; padding-bottom: 0 !important; ">
        <div class="content columns">
          <div class="column">
            <figure class="image is-48x48" style="margin-left: 0;">
              <img
                src="../../assets/EJSClogo2_new.png"
                alt="EJSC Logo"
              />
            </figure>
            <p class="my-6 pb-6 light-text">
              All rights reserved. No part of this law <br />report may be reproduced, stored in
              a <br />retrieval system, or transmitted in any <br />form by any means, whether
              electronic, <br />mechanical, photocopy, recording, or <br />otherwise, without prior
              written <br />permission of the copyright holder.
            </p>
          </div>

          <div class="column mt-5 is-one-quarter">
            <p class="pl-3">QUICK LINKS</p>
            <div class="mt-5 pt-3">
              <b-button type="is-ghost has-text-white" class="light-text" tag="router-link" :to="{ path: '/about' }">About</b-button> <br/>
              <b-button type="is-ghost has-text-white" class="light-text" tag="router-link" :to="{ path: '/team' }">Meet our Team</b-button> <br />
              <b-button type="is-ghost has-text-white" class="light-text" tag="router-link" :to="{ path: '/login' }">Log in</b-button> <br />
              <b-button type="is-ghost has-text-white" class="light-text" tag="router-link" :to="{ path: '/signup' }">Sign up</b-button> <br />
            </div>
          </div>

          <div class="column mt-5 is-one-third">
            <p>CONTACT</p>
              <div class="mt-4 pt-5">
                <p class="light-text">
                  {{ $store.getters.getSettings.address }}
                </p>
                <p class="light-text">
                  Phone No: {{ $store.getters.getSettings.mobile.replace(/09066211036/g, '') }}
                </p>
                <p>
                  <span class="mr-3">
                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z" fill="white"/>
                      <path d="M18.771 15.5873H16.0944V25.393H12.0392V15.5873H10.1105V12.1412H12.0392V9.91119C12.0392 8.31648 12.7967 5.81934 16.1305 5.81934L19.1344 5.8319V9.17694H16.9549C16.5974 9.17694 16.0947 9.35556 16.0947 10.1163V12.1444H19.1253L18.771 15.5873Z" fill="#0D2333"/>
                    </svg>
                  </span>
                  <span class="mr-3">
                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M15 0C6.717 0 0 6.717 0 15C0 23.283 6.717 30 15 30C23.283 30 30 23.283 30 15C30 6.717 23.283 0 15 0ZM10.6412 22.6758H6.98799V11.6851H10.6412V22.6758ZM8.8147 10.1843H8.79089C7.565 10.1843 6.77216 9.34044 6.77216 8.28575C6.77216 7.20726 7.58926 6.38672 8.83896 6.38672C10.0887 6.38672 10.8577 7.20726 10.8815 8.28575C10.8815 9.34044 10.0887 10.1843 8.8147 10.1843ZM23.8138 22.6758H20.1611V16.796C20.1611 15.3184 19.6321 14.3106 18.3103 14.3106C17.3012 14.3106 16.7001 14.9904 16.436 15.6466C16.3394 15.8814 16.3158 16.2096 16.3158 16.5381V22.6758H12.6629C12.6629 22.6758 12.7107 12.7162 12.6629 11.6851H16.3158V13.2413C16.8013 12.4924 17.6699 11.4272 19.6081 11.4272C22.0116 11.4272 23.8138 12.998 23.8138 16.3737V22.6758Z" fill="white"/>
                    </svg>
                  </span>
                  <span class="mr-3">
                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M15 0C6.717 0 0 6.717 0 15C0 23.283 6.717 30 15 30C23.283 30 30 23.283 30 15C30 6.717 23.283 0 15 0ZM21.8488 11.6954C21.8555 11.843 21.8587 11.9913 21.8587 12.1404C21.8587 16.6898 18.3957 21.936 12.0628 21.9363H12.063H12.0628C10.1184 21.9363 8.3091 21.3663 6.78543 20.3897C7.05482 20.4215 7.32903 20.4373 7.60666 20.4373C9.21982 20.4373 10.7043 19.8871 11.8829 18.9635C10.3757 18.9356 9.10492 17.9402 8.66638 16.5722C8.87627 16.6125 9.0921 16.6344 9.31343 16.6344C9.62769 16.6344 9.9321 16.5921 10.2214 16.5131C8.64601 16.1977 7.45926 14.8055 7.45926 13.1383C7.45926 13.1227 7.45926 13.1087 7.45972 13.0943C7.92366 13.3523 8.45421 13.5075 9.01909 13.5249C8.09464 12.908 7.48695 11.8536 7.48695 10.659C7.48695 10.0282 7.65747 9.43726 7.95319 8.92845C9.65103 11.0117 12.1884 12.3818 15.0499 12.5258C14.9908 12.2736 14.9604 12.0108 14.9604 11.7407C14.9604 9.84009 16.5024 8.29811 18.4037 8.29811C19.3941 8.29811 20.2885 8.71674 20.9168 9.38599C21.7012 9.23126 22.4377 8.9447 23.1031 8.55034C22.8456 9.35394 22.3 10.0282 21.5891 10.4546C22.2855 10.3713 22.9493 10.1866 23.5661 9.91241C23.1054 10.603 22.5211 11.2095 21.8488 11.6954Z" fill="white"/>
                    </svg>
                  </span>
                </p>
              </div>
          </div>
          
        </div>
        <p class="light-text has-text-white has-text-left pb-1" style="border-bottom: 1px solid #313D55; min-width: 100% !important;">Copyright &copy; {{year}}  •  All Rights Reserved</p>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      year: new Date().getFullYear(),
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  @font-face {
    font-family: "MontLight";
    src: url("../../fonts/Mont/Mont-Light.otf");
  }

  .light-text {
    font-family: "MontLight";
  }
</style>
