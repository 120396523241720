<template>
  <div class="block" style="padding: 0 10%;">
    <div class="container">
      <section class="section mt-6">
        <div class="columns is-desktop has-text-primary">
          <div class="column">
            <img alt="Team" src="../../assets/teams/onwumah.png" />
          </div>
          <div class="column is-three-fifths ml-5 pl-5">
            <h3 class="is-size-3 bold-text mt-3 pt-5"><b>Chibueze Onwumah</b></h3>
            <p class="regular-text has-text-primary mt-5">
              Chibueze Onwumah is a graduate of law from the prestigious Madonna University, Okija, Anambra State with a Bachelor of Law Degree (LLB)Hons from where he proceeded to the Nigerian Law School and was successfully called to the Nigerian Bar (BL). Chibueze is currently the assistant deputy editor of Erudite Judgments of the Supreme Court and also a senior associate in the Law Firm of Ogbah Isaac &amp; Co.
            </p>
            <p class="regular-text has-text-primary mt-3">
                Chibueze  is a member of the Institute of Chartered Secretary and Administrators of Nigeria (ICSAN) and also a member of the Nigerian Bar Association. He is a seasoned lawyer whose core area of practice includes: property and conveyance law, corporate law, family law and trademark, patent and design law.
                He is happily married and blessed with a lovely son.
            </p>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "TeamInfo",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  @font-face {
    font-family: "MontBold";
    src: url("../../fonts/Mont/Mont-Bold.otf");
  }

  @font-face {
    font-family: "MontRegular";
    src: url("../../fonts/Mont/Mont-Regular.otf");
  }

  .bold-text {
    font-family: "MontBold";
  }

  .regular-text {
    font-family: "MontRegular";
  }
</style>
