<template>
    <div class="has-background-info" v-if="$store.getters.getIsApp">
        <div class="columns is-mobile">
            <div class="column has-text-centered ">
                <figure class="image is-32x32 auto-margin" @click="$router.push('/home')">
                    <img src="../../../assets/home_icon.png" />
                </figure>
            </div>
            <div class="column">
                <figure class="image is-32x32 auto-margin">
                    <img src="../../../assets/balance_icon.png" />
                </figure>
            </div>
            <div class="column has-text-centered">
                <div :class="`dropdown ${dropdown} is-up is-right`">
                    <div class="dropdown-trigger">
                        <div aria-haspopup="true" aria-controls="dropdown-menu88">
                            <figure class="image is-32x32 auto-margin">
                                <img src="../../../assets/user_icon.png" @click="toggleDropdown" />
                            </figure>
                        </div>
                    </div>
                    <div class="dropdown-menu" id="dropdown-menu88" role="menu" @click="toggleDropdown">
                        <div class="dropdown-content">
                            <div class="dropdown-content">
                                <router-link to="/" class="dropdown-item has-text-right has-text-primary">Home</router-link>
                            </div>
                            <div class="dropdown-content">
                                <router-link to="/home/profile" class="dropdown-item has-text-right has-text-primary">My Account</router-link>
                            </div>
                            <div class="dropdown-content">
                                <a to="/" class="dropdown-item has-text-right has-text-grey">How to Guide</a>
                            </div>
                            <div class="dropdown-content">
                                <router-link to="/home/latest-publications" class="dropdown-item has-text-right has-text-grey">Latest Publications</router-link>
                            </div>
                            <div class="dropdown-content">
                                <router-link to="/home/rules-of-court/rule" class="dropdown-item has-text-right">Rules of Court</router-link>
                            </div>
                            <div class="dropdown-content">
                                <router-link to="/home/rules-of-court/law" class="dropdown-item has-text-right">Laws of The Federation</router-link>
                            </div>
                            <div class="dropdown-content">
                                <router-link to="/shop" class="dropdown-item has-text-right has-text-primary">Buy a Book</router-link>
                            </div>
                            <div class="dropdown-content" @click="signOut">
                                <a to="" class="dropdown-item has-text-right has-text-primary">Logout</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import { mapActions } from "vuex";
export default {
    data() {
        return {
            dropdown: null,
        }
    },
    methods: {
        ...mapActions(["LogOut",]),
        signOut(){
            this.LogOut();
            this.$router.push("/login");
        }, //end of signOut
        toggleDropdown(){
        // console.log(this.dropdown)
        this.dropdown = (!this.dropdown) ? "is-active" : null;
        }, //end of toggleDropdown
    }
}
</script>
<style scoped>
    .auto-margin {
        margin: auto !important;
    }
</style>