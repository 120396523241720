import { render, staticRenderFns } from "./RulesOfCourt.vue?vue&type=template&id=57a8a4ed&scoped=true&"
import script from "./RulesOfCourt.vue?vue&type=script&lang=js&"
export * from "./RulesOfCourt.vue?vue&type=script&lang=js&"
import style0 from "./RulesOfCourt.vue?vue&type=style&index=0&id=57a8a4ed&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57a8a4ed",
  null
  
)

export default component.exports