<template>
  <div style="margin: 0 5px 0 30px;">
    <nav class="navbar" role="navigation" aria-label="main navigation" style="background-color: #F7F8FA;">
      <div class="navbar-brand" style="padding-top: 10px;">
        <router-link class="" to="/">
          <img src="../../../assets/EJSClogo2_new.png" alt="EJSC Logo" width="34" height="43">
        </router-link>

        <a role="button" :class="`py-2 navbar-burger ${mobileMenu}`" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample" @click="toggleMenu">
          <span aria-hidden="true" v-if="showX"></span>
          <span aria-hidden="true" v-if="showX"></span>
          <span aria-hidden="true" v-if="showX"></span>
          <svg v-if="!showX" width="28" height="16" viewBox="0 0 28 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.666687 0H27.3334V2.66667H0.666687V0ZM7.33335 6.66667H27.3334V9.33333H7.33335V6.66667ZM15.6667 13.3333H27.3334V16H15.6667V13.3333Z" fill="#003B56"/>
          </svg>
        </a>
      </div>

      <div id="navbarBasicExample has-background-primary" :class="`navbar-menu ${mobileMenu}`" style="padding: 0 !important;">
        <div class="navbar-end has-background-primary py-4" @click="toggleMenu">
          <router-link class="navbar-item has-text-white mx-2" to="/">
            Home
          </router-link>

          <router-link class="navbar-item has-text-white mx-2" to="/about">
            About
          </router-link>

          <router-link class="navbar-item has-text-white mx-2" to="/team">
            Meet our Team
          </router-link>

          <router-link class="navbar-item has-text-white mx-2" to="/shop">
            Shop
          </router-link>

          <router-link class="navbar-item has-text-white mx-2" to="/contact">
            Contact
          </router-link>

          <router-link class="navbar-item has-text-white mx-2" to="/login" v-if="!isAuth">
            Login
          </router-link>

          <router-link class="navbar-item has-text-white mx-2" to="/signup" v-if="!isAuth">
            Sign up
          </router-link>

          <router-link class="navbar-item has-text-white mx-2" to="/home" v-if="isAuth">
            Dashboard
          </router-link>

          <router-link class="navbar-item has-text-white mx-2" to="/shop/cart">
            Cart
            <sup v-if="$store.getters.getCartItems > 0" class="is-primary span-sub is-size-5">{{ $store.getters.getCartItems }}</sup>
          </router-link>

        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: "Navbar2",
  data() {
    return {
      isAuth: false,
      mobileMenu: null,
      cartItems: 0,
      showX: false,
    }
  },
  methods: {
    toggleMenu () {
      if (!this.mobileMenu) {
        this.mobileMenu = "is-active";
        this.showX = true;
      } else {
        this.mobileMenu = null;
        this.showX = false;
      }
    }, //end of toggleMenu
  },
  mounted() {
    this.isAuth = this.$store.getters.isAuthenticated;
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  .nav-menu-item {
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
  }
  
  .span-sub {
    margin-top: -10px;
    margin-left: 5px;
  }
</style>
