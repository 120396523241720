<template>
  <div class="form-input-holder">
    <div class="has-text-centered">
      <img src="../../assets/EJSClogo2_new.png" width="65" class="mt-6" alt="EJSC Logo" />
      <h3 class="is-size-3 has-text-primary mt-5 heavy-text"><b>Password reset token <br />was sent to mail successfully</b></h3>
      <img src="../../assets/verified.png" class="my-6" alt="Verified Icon" /> <br/>
      <p href="" class="is-size-3 has-text-black mt-6 pt-6 heavy-text"><b>Follow link in mail to Continue</b></p>
    </div>

  </div>
</template>

<script>
export default {
  name: "PasswordUpdated",
  mounted() {
    if (this.$store.getters.isAuthenticated) this.$router.push({ name: "AuthHome" });
    if (!this.$route.params.message) this.$router.push({ name: "ForgotPassword" });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
