<template>
  <div class="block has-background-info" style="margin-top: -100px;">
    <div class="">
      <section class="p-4 has-text-white has-text-centered">
        New updates to EJSC online ...
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "NoticeMobile",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.learn-more:hover {
  filter: drop-shadow(0 0 10px #003B56);
}

</style>
