<template>
  <div class="block" style="">
    <div class="container">
      <section class="section">
        <div class="columns is-desktop has-text-primary">
          <div class="column is-12 has-text-centered">
            <h4 class="is-size-6 mb-2 regular-text">We offer Legal practitioners ease of access to </h4>
            <h1 class="is-size-4 heavy-text">Judgments delivered by the Supreme Court of Nigeria.</h1>
            <b-button rounded class="is-primary px-5 learn-more mt-5" @click="$router.push('/signup')">GET STARTED</b-button>
          </div>
        </div>
        <div class="columns is-desktop has-text-primary">
          <div class="column is-12 has-text-centered">
            <figure class="image" style="min-width: 100% !important;" >
                <img alt="Lawyer" style="min-width: 100% !important;" src="../../../assets/cta_mobile.png" >
            </figure>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "CtaMobile",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.learn-more:hover {
  filter: drop-shadow(0 0 10px #003B56);
}

</style>
