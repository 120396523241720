<template>
  <div class="block" style="padding: 0 10%; background-color: #F8F9FD;">
    <div class="container">
      <section class="section mt-6">
        <div class="columns is-desktop has-text-primary">
          <div class="column">
            <img alt="Lawyer" src="../../assets/law_order.png">
          </div>
          <div class="column is-three-fifths mt-5 ml-5 pl-6 has-text-justified">
            <p class="regular-text has-text-info mt-4">
              A citation of Erudite Judgments of the Supreme Court (EJSC) with its volume- based classification looks like this: (2015) EJSC (Vol. 2) 1 - "2015" - (which corresponds to year of publication), Erudite Judgments of the Supreme Court (name of reporter or law report), "2" (refer to the volume) and "1" (assigned page number ).
            </p>
            <p class="regular-text has-text-info mt-6">
              A fuller citation will look like this: British Airways v. Atoyebi (2015) EJSC (Vol.2) 137. The report does not focus on any particular area of law but restricts itself to the publication of Supreme Court Judgments and Rulings. It has a provision (which is considered to be a unique innovation) for Positional Statement, the first of its kind which is a position paper that provides timely treatment of significant development in law through articles to be contributed by judges, leading scholars and legal practitioners. The Positional paper is for every conceivable legal topic.
            </p>
            <p class="regular-text has-text-info mt-6">
              Erudite Judgments of the Supreme Court (EJSC) is being published Bi-weekly, and is recognized for its most accurate reporting and unique highlighting feature to make the judgments easy to understand.
            </p>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "WhoWeAre2",
  
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  @font-face {
    font-family: "MontRegular";
    src: url("../../fonts/Mont/Mont-Regular.otf");
  }

  .regular-text {
    font-family: "MontRegular";
  }
  .block {
    /*background-image: url("../../assets/erudite_bg.png");*/
  }
</style>
