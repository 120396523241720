<template>
  <div class="block" style="padding: 0 10%;">
    <div class="container">
      <section class="section mt-6">
        <div class="columns is-desktop has-text-primary">
          <div class="column">
            <img alt="Team" src="../../assets/teams/samson.png" />
          </div>
          <div class="column is-three-fifths ml-5 pl-5">
            <h3 class="is-size-3 bold-text mt-3 pt-5"><b>Oluwadunni Samson Olugbenga (a.k.a Pastore)</b></h3>
            <p class="regular-text has-text-primary mt-5">
              Oluwadunni Samson Olugbenga (a.k.a Pastore) is the Head of Marketing, Legal Jurisprudence Limited. He is an experienced and astute marketer and joined the organisation in 2017 as a sales representative. Pastore is an indigene of Ijeda Ijesa, Oriade LGA, Osun State. He loves football, music, reading, travelling and meeting people.
            </p>
            <p class="regular-text has-text-primary">
              Tel no: 0805 582 9328, 0901 935 9643
            </p>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "TeamInfo",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  @font-face {
    font-family: "MontBold";
    src: url("../../fonts/Mont/Mont-Bold.otf");
  }

  @font-face {
    font-family: "MontRegular";
    src: url("../../fonts/Mont/Mont-Regular.otf");
  }

  .bold-text {
    font-family: "MontBold";
  }

  .regular-text {
    font-family: "MontRegular";
  }
</style>
