<template>
  <div>
    <div
      class="block is-hidden-touch"
      style="padding: 0 10%; margin-bottom: -2%;"
      v-if="getViewPort() >= 1000"
    >
      <div class="container has-text-white">
        <section class="section">
          <div class="control has-icons-right mx-6 px-6" v-if="true === false">
            <input
              class="input is-primary has-text-primary is-rounded mx-6"
              type="text"
              placeholder="Search Books"
            />
            <span class="icon is-right">
              <i class="fas fa-search has-text-primary pr-6"></i>
            </span>
          </div>
          <div class="columns my-6">
            <div class="column is-4">
              <h5 class="has-text-info is-size-5 bold-text">
                <span class="has-text-left">
                  Category
                </span>
                <span class="has-text-right ">
                  <i class="ml-6 pl-6 fas fa-angle-down"></i>
                </span>
              </h5>
              <p
                class="has-text-primary is-clickable regular-text pt-1"
                @click="categorizeProduct('all')"
              >
                All Books
              </p>
              <p
                class="has-text-primary is-clickable py-2 regular-text"
                v-for="(category, index) in categories"
                :key="index"
                @click="categorizeProduct(category.id)"
              >
                {{ category.name }} ({{ category.product_counts }})
              </p>
            </div>
            <div class="column is-8">
              <div class="columns">
                <!--prodA-->
                <div
                  class="column has-text-left mx-3"
                  v-for="product in productsA"
                  :key="product.id"
                >
                  <router-link
                    :to="{
                      name: 'OneItem',
                      params: {
                        product: product,
                        item_name: product.name,
                        item_id: product.id,
                      },
                    }"
                  >
                    <figure class="image">
                      <img :src="product.image" :alt="product.category_name" />
                    </figure>
                    <p class="has-text-left has-text-primary mt-3">
                      <span class="bold-text">{{ product.name }}</span
                      ><br />
                      <span class="heavy-text is-size-4"
                        >₦{{ formatNumber(product.unit_price) }}</span
                      >
                    </p>
                  </router-link>
                </div>
              </div>
            </div>
          </div>

          <div class="columns my-6">
            <div class="column is-4">
              <!--cat rem-->
            </div>
            <div class="column is-8">
              <div class="columns">
                <!--prodB-->
                <div
                  class="column has-text-left mx-3"
                  v-for="product in productsB"
                  :key="product.id"
                >
                  <router-link
                    :to="{
                      name: 'OneItem',
                      params: {
                        product: product,
                        item_name: product.name,
                        item_id: product.id,
                      },
                    }"
                  >
                    <figure class="image">
                      <img :src="product.image" :alt="product.category_name" />
                    </figure>
                    <p class="has-text-left has-text-primary mt-3">
                      <span class="bold-text">{{ product.name }}</span
                      ><br />
                      <span class="heavy-text is-size-4"
                        >₦{{ formatNumber(product.unit_price) }}</span
                      >
                    </p>
                  </router-link>
                </div>
              </div>
            </div>
          </div>

          <div class="columns my-6">
            <div class="column is-4">
              <!--cat rem-->
            </div>
            <div class="column is-8">
              <div class="columns">
                <!--prodC-->
                <div
                  class="column has-text-left mx-3"
                  v-for="product in productsC"
                  :key="product.id"
                >
                  <router-link
                    :to="{
                      name: 'OneItem',
                      params: {
                        product: product,
                        item_name: product.name,
                        item_id: product.id,
                      },
                    }"
                  >
                    <figure class="image">
                      <img :src="product.image" :alt="product.category_name" />
                    </figure>
                    <p class="has-text-left has-text-primary mt-3">
                      <span class="bold-text">{{ product.name }}</span
                      ><br />
                      <span class="heavy-text is-size-4"
                        >₦{{ formatNumber(product.unit_price) }}</span
                      >
                    </p>
                  </router-link>
                </div>
              </div>
            </div>
          </div>

          <div class="columns my-6">
            <div class="column is-4">
              <!--cat rem-->
            </div>
            <div class="column is-8">
              <div class="columns">
                <!--prodD-->
                <div
                  class="column has-text-left mx-3"
                  v-for="product in productsD"
                  :key="product.id"
                >
                  <router-link
                    :to="{
                      name: 'OneItem',
                      params: {
                        product: product,
                        item_name: product.name,
                        item_id: product.id,
                      },
                    }"
                  >
                    <figure class="image">
                      <img :src="product.image" :alt="product.category_name" />
                    </figure>
                    <p class="has-text-left has-text-primary mt-3">
                      <span class="bold-text">{{ product.name }}</span
                      ><br />
                      <span class="heavy-text is-size-4"
                        >₦{{ formatNumber(product.unit_price) }}</span
                      >
                    </p>
                  </router-link>
                </div>
              </div>
            </div>
          </div>

          <div class="columns my-6 py-6">
            <div class="column is-4">
              <!--cat rem-->
            </div>
            <div class="column is-8 mx-6 px-6">
              <div class="buttons mx-6 px-6">
                <button
                  :class="
                    `button is-primary is-inverted mx-1 ${
                      presentPage == 1 ? 'btn-disabled' : ''
                    }`
                  "
                  :disabled="presentPage == 1 ? true : false"
                  @click="managePagination(presentPage - 1)"
                >
                  <span class="icon">
                    <i class="fas fa-arrow-left"></i>
                  </span>
                  <span>Prev</span>
                </button>
                <button
                  :class="
                    `button is-primary is-inverted ${
                      nums == presentPage ? 'btn-current-page' : ''
                    }`
                  "
                  v-for="nums in totalPages"
                  @click="managePagination(nums)"
                >
                  {{ nums }}
                </button>
                <button
                  :class="
                    `button is-primary is-inverted mx-1 ${
                      presentPage == totalPages ? 'btn-disabled' : ''
                    }`
                  "
                  :disabled="presentPage == totalPages ? true : false"
                  @click="managePagination(presentPage + 1)"
                >
                  <span>Next</span>
                  <span class="icon">
                    <i class="fas fa-arrow-right"></i>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <div class="is-hidden-desktop" v-else>
      <AllItemsMobile />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import AllItemsMobile from "../mobile/ShopComponent/AllItems";

export default {
  name: "AllItems",
  components: {
    AllItemsMobile,
  },
  data() {
    return {
      nextPage: null,
      previousPage: null,
      currentPage: null,
      categories: [],
      allProducts: [],
      productsA: [],
      productsB: [],
      productsC: [],
      productsD: [],
      tempImages: [
        "../../assets/book1.png",
        "../../assets/book2.png",
        "../../assets/book3.png",
      ],
      setToast: null,
      selectedCategory: null,
      totalPages: 1,
      presentPage: 1,
      gotoPage: "1",
    };
  },
  methods: {
    managePagination(pageNo) {
      if (pageNo != this.presentPage) {
        this.gotoPage = pageNo;
        this.getProducts();
      }
    }, //end of managePagination
    getProducts() {
      const config = {
        method: "get",
        url: `https://api.ejsconline.com:442/api/v_1/shop/products?page=${this.gotoPage}`,
      };
      axios(config)
        .then((response) => {
          const result = response.data;
          this.allProducts = result.data;
          this.presentPage = result.current_page;
          this.totalPages = Math.ceil(result.count / result.per_page);
          this.adjustProductColumns(this.allProducts);
        })
        .catch((err) => {
          if (err.response.status == "401") {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: "Action failed, authorization required . . . ",
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
            this.manageLogout();
          }
          if (err.response) {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: err.response.data.error.message,
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
          } else if (err.request) {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: JSON.stringify(err.request.data),
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
          } else {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: err.message,
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
          }
        });
    }, //end of getProduct
    getCategories() {
      const config = {
        method: "get",
        url: "https://api.ejsconline.com:442/api/v_1/shop/categories",
      };
      axios(config)
        .then((response) => {
          const result = response.data;
          this.categories = result.data;
        })
        .catch((err) => {
          if (err.response.status == "401") {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: "Action failed, authorization required . . . ",
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
            this.manageLogout();
          }
          if (err.response) {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: err.response.data.error.message,
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
          } else if (err.request) {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: JSON.stringify(err.request.data),
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
          } else {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: err.message,
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
          }
        });
    }, //end of getCategories

    getRandomImage() {
      const index = Math.floor(Math.random() * 3);
      return this.tempImages[index];
    }, //end of getRandomImage

    categorizeProduct(id) {
      const selected = id;
      if (selected === "all") {
        this.adjustProductColumns(this.allProducts);
      } else {
        const myProducts = this.allProducts.filter(
          (prod) => prod.category_id == selected
        );
        this.adjustProductColumns(myProducts);
      }
    }, //end of categorizeProduct

    adjustProductColumns(myProducts) {
      const total = myProducts.length;
      if (total < 3) {
        this.productsA = this.allProducts.filter(
          (prod, index) => index < total
        );
        this.productsB = [];
        this.productsC = [];
        this.productsD = [];
      } else if (total >= 3 && total < 6) {
        this.productsA = this.allProducts.filter((prod, index) => index < 3);
        this.productsB = this.allProducts.filter(
          (prod, index) => index >= 3 && index < total
        );
        this.productsC = [];
        this.productsD = [];
      } else if (total >= 6 && total < 9) {
        this.productsA = this.allProducts.filter((prod, index) => index < 3);
        this.productsB = this.allProducts.filter(
          (prod, index) => index >= 3 && index < 6
        );
        this.productsC = this.allProducts.filter(
          (prod, index) => index >= 6 && index < total
        );
        this.productsD = [];
      } else {
        this.productsA = this.allProducts.filter((prod, index) => index < 3);
        this.productsB = this.allProducts.filter(
          (prod, index) => index >= 3 && index < 6
        );
        this.productsC = this.allProducts.filter(
          (prod, index) => index >= 6 && index < 9
        );
        this.productsD = this.allProducts.filter(
          (prod, index) => index >= 9 && index < total
        );
      }
    }, //end of adjustProductColumns
    manageLogout() {
      this.$store.dispatch("LogOut");
      this.$router.push("/login");
    }, //manageLogout
    test_() {
      const config = {
        method: "get",
        url: "https://api.ejsconline.com:442/api/v_1/shop/price-list",
        headers: {
          Signature: "2b72e1a-55d3-4afd-811f-63f24",
          Authorization: localStorage.getItem("user_token"),
        },
      };
      axios(config)
        .then((res) => {
          console.log(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }, //end of test_
    closeToast() {
      if (this.setToast) {
        this.setToast.close();
        this.setToast = null;
      }
    }, //end of closeToast
    formatNumber(num) {
      return `${new Intl.NumberFormat("en-US").format(num)}.00`;
    }, //end of formatNumber
    getViewPort() {
      return (window.innerWidth);
    }, //end of getViewPort
  },
  mounted() {
    // this.test_()
    this.getCategories();
    this.getProducts();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@font-face {
  font-family: "MontBold";
  src: url("../../fonts/Mont/Mont-Bold.otf");
}

@font-face {
  font-family: "MontHeavy";
  src: url("../../fonts/Mont/Mont-Heavy.otf");
}

.bold-text {
  font-family: "MontBold";
}

.heavy-text {
  font-family: "MontHeavy";
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #003b56;
  opacity: 1; /* Firefox */
}

.btn-disabled {
  color: #dfe0eb !important;
}

.btn-current-page {
  border: 1px solid #dfe0eb !important;
}
</style>
