var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.getViewPort() >= 1000)?_c('div',{staticClass:"is-hidden-touch"},[_c('div',{staticClass:"container my-6 mx-6"},[_c('Header'),(!_vm.isPageReady)?_c('b-progress',{staticClass:"mt-4",attrs:{"type":"is-primary"}}):_vm._e(),(_vm.isPageReady)?_c('div',{staticClass:"columns has-text-primary"},[_c('div',{staticClass:"column"},[_c('h3',{staticClass:" bold-text is-size-4 has-text-primary"},[_vm._v(" Latest Publications ")]),_c('div',{staticClass:"container"},[_c('section',{staticClass:"section"},[_c('div',{staticClass:"columns mb-6"},_vm._l((_vm.allProducts),function(product){return _c('div',{key:product.id,staticClass:"column p-6 ",staticStyle:{"border":"1px solid #ECEFF4"}},[_c('div',{},[_c('router-link',{attrs:{"to":{
                        name: 'OneItem',
                        params: {
                          product: product,
                          item_name: product.name,
                          item_id: product.id,
                        },
                      }}},[_c('figure',{staticClass:"image",on:{"click":function($event){return _vm.$router.push()}}},[_c('img',{attrs:{"src":product.image,"alt":product.category_name}})]),_c('p',{staticClass:"has-text-primary bold-text has-text-left my-2"},[_vm._v(" "+_vm._s(product.name)+" "),_c('br'),_c('span',{staticClass:"regular-text",staticStyle:{"color":"#7C8087"}},[_vm._v("₦"+_vm._s(_vm.formatNumber(product.unit_price)))])])])],1)])}),0),_c('div',{staticClass:"columns has-text-centered"},[_c('div',{staticClass:"column"},[_c('b-button',{staticClass:"is-primary px-6",attrs:{"rounded":""},on:{"click":function($event){return _vm.$router.push('/shop')}}},[_vm._v("Shop More")])],1)])])])])]):_vm._e()],1)]):_c('div',{staticClass:"is-hidden-desktop"},[_c('LatestPublicationsMobile')],1)])}
var staticRenderFns = []

export { render, staticRenderFns }