<template>
  <div class="password-reset">
    <navbar />
    <AuthCard page="update" v-if="!$store.getters.getLoading" />
    <Footer />
  </div>
</template>

<script>
  // import HelloWorld from '../components/HelloWorld'
  import Navbar from '../components/GeneralComponent/Navbar'
  import AuthCard from '../components/GeneralComponent/AuthCard'
  import Footer from '../components/GeneralComponent/Footer'

  export default {
    name: 'Update',

    components: {
      // HelloWorld,
      Navbar,
      AuthCard,
      Footer,
    },
  }
</script>
