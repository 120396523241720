<template>
  <div>
    <div class="is-hidden-touch" v-if="getViewPort() >= 1000">
      <div class="container my-6 mx-6">
        <Header />
        <b-progress
          v-if="!isPageReady"
          type="is-primary"
          class="mt-4"
        ></b-progress>
        <div class="columns has-text-primary" v-if="isPageReady">
          <div class="column">
            <div class="mb-5 bold-text is-size-4" v-if="$store.getters.getUser">
              Welcome, {{ $store.getters.getUser.firstname }}
              {{ $store.getters.getUser.lastname }}
            </div>
            <div class="mb-5 bold-text is-size-4" v-else>
              Welcome, Precious Jack
            </div>

            <div v-if="$store.getters.getUser.subscription.subscription_status">
              <div class="has-text-primary">
                <p>
                  {{ $store.getters.getUser.subscription.message1 }} <br />
                  {{
                    formatSubscriptionMessage(
                      $store.getters.getUser.subscription.message2
                    )
                  }}
                </p>
              </div>

              <div class="columns">
                <div class="column is-two-thirds">
                  <div class="card mt-6">
                    <div class="card-content">
                      <div class="columns mb-2 py-2 separator">
                        <div class="column">
                          <p class="has-text-primary bold-text">Recent Cases</p>
                        </div>
                      </div>
                      <div
                        class="columns px-5 py-2 separator"
                        v-for="cases in $store.getters.getUser.recent_cases"
                        :key="cases.id"
                      >
                        <div class="column is-8">
                          <router-link
                            :to="
                              `/home/load-case/${adjustCitation(
                                cases.case_name,
                                cases.part,
                                cases.page_start,
                                cases.page_end,
                                cases.part
                              ).trim()}/${cases.PiDxpln}`
                            "
                            class="has-text-primary"
                          >
                            <p class="has-text-left regular-text">
                              {{
                                adjustCitation(
                                  cases.case_name,
                                  cases.part,
                                  cases.page_start,
                                  cases.page_end,
                                  cases.part
                                )
                              }}
                            </p>
                          </router-link>
                        </div>
                        <div class="column is-4">
                          <router-link
                            :to="
                              `/home/load-case/${cases.case_name.trim()}/${
                                cases.PiDxpln
                              }`
                            "
                            class="has-text-primary"
                          >
                            <p class="has-text-right regular-text">
                              {{ formatDate(new Date(cases.published_date)) }}
                            </p>
                          </router-link>
                        </div>
                      </div>
                      <div class="has-text-right mr-6 pr-5 my-5">
                        <b-button type="is-primary" class="is-rounded"
                          >View More</b-button
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <div class="column">
                  <div class="card mt-6">
                    <div class="card-content">
                      <div class="columns mb-2 py-2 separator-t">
                        <div class="column">
                          <p class="has-text-primary bold-text">
                            Recent Activities
                          </p>
                        </div>
                      </div>
                      <div
                        class="columns px-5 py-2 separator-t"
                        v-for="(activity, index) in $store.getters.getUser
                          .recent_activities"
                        :key="index"
                      >
                        <div class="column is-12">
                          <a :to="``" class="has-text-primary">
                            <p class="has-text-left">
                              {{ activity.description }} on
                              {{ formatDate(activity.createdAt) }}
                            </p>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="columns" style="margin-top: -50px;">
                <div class="column is-two-thirds">
                  <div class="card mt-6">
                    <div class="card-content">
                      <div class="columns mb-2 py-2 separator">
                        <div class="column">
                          <p class="has-text-primary bold-text">
                            Recent Search
                          </p>
                        </div>
                      </div>
                      <div
                        class="columns px-5 py-2 separator"
                        v-for="(search, index) in $store.getters.getUser
                          .recent_search"
                        :key="index"
                      >
                        <div class="column is-8">
                          <router-link
                            :to="`/home/search-result?query=${search.query}`"
                            class="has-text-primary"
                          >
                            <p class="has-text-left regular-text">
                              {{ search.query }}
                            </p>
                          </router-link>
                        </div>
                        <div class="column is-4">
                          <router-link
                            :to="`/home/search-result?query=${search.query}`"
                            class="has-text-primary"
                          >
                            <p class="has-text-right regular-text">
                              {{ formatDate(search.created_at) }}
                            </p>
                          </router-link>
                        </div>
                      </div>
                      <div class="has-text-right mr-6 pr-5 my-5">
                        <b-button type="is-primary" class="is-rounded"
                          >View More</b-button
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <div class="column">
                  <div class="card mt-6">
                    <div class="card-content">
                      <div class="columns mb-2 py-2 separator-t">
                        <div class="column">
                          <p class="has-text-primary bold-text">Promos</p>
                        </div>
                      </div>
                      <div
                        class="columns px-5 py-2 separator-t"
                        v-for="(promo, index) in $store.getters.getUser
                          .promotions"
                        :key="index"
                      >
                        <div class="column is-8">
                          <a :to="``" class="has-text-primary">
                            <p class="has-text-left">{{ promo }}</p>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <div class="has-text-primary">
                <p>
                  You don’t have an active subscription. <br />
                  Choose a package that suits you below;
                </p>
              </div>

              <div class="columns">
                <div class="column" v-for="(plan, index) in plans" :key="index" v-if="index < 3">
                  <div class="card mt-6">
                    <div class="card-content">
                      <div class="content has-text-centered py-5">
                        <p class="has-text-primary regular-text">
                          {{
                            capitalizeFirstChar(
                              plan.name
                            )
                          }}
                          <span v-if="!plan.name.toLowerCase().includes('promo')">Plan</span>
                        </p>
                        <h1 class="is-size-1 has-text-primary heavy-text">
                          ₦{{
                            formatNumber(
                              plan.amount
                            )
                          }}
                        </h1>
                        <b-button
                          type="is-primary"
                          class="regular-text"
                          rounded
                          outlined
                          @click="
                            initSubscription(
                              plan.id,
                              plan.amount
                            )
                          "
                          >Subscribe</b-button
                        >
                      </div>
                    </div>
                  </div>
                </div>

              </div>

              <div class="columns">
                <div class="column" v-for="(plan, index) in plans" :key="index" v-if="index >= 3 && index < 6">
                  <div class="card mt-6">
                    <div class="card-content">
                      <div class="content has-text-centered py-5">
                        <p class="has-text-primary regular-text">
                          {{
                            capitalizeFirstChar(
                              plan.name
                            )
                          }}
                          <span v-if="!plan.name.toLowerCase().includes('promo')">Plan</span>
                        </p>
                        <h1 class="is-size-1 has-text-primary heavy-text">
                          ₦{{
                            formatNumber(
                              plan.amount
                            )
                          }}
                        </h1>
                        <b-button
                          type="is-primary"
                          class="regular-text"
                          rounded
                          outlined
                          @click="
                            initSubscription(
                              plan.id,
                              plan.amount
                            )
                          "
                          >Subscribe</b-button
                        >
                      </div>
                    </div>
                  </div>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="is-hidden-desktop" v-else>
      <HomeMobile />
    </div>
  </div>
</template>

<script>
import Header from "../components/DashboardComponent/Header";
import HomeMobile from "../pages_mobile/Home";
import axios from "axios";
import { mapActions } from "vuex";

export default {
  name: "HomeDashboard",
  components: {
    Header,
    HomeMobile,
  },
  data() {
    return {
      profile: {
        firstname: null,
        lastname: null,
        email: null,
        mobile: null,
        address: null,
        image_url: null,
        subscription: {
          subscription_status: false,
        },
        recent_cases: null,
        recent_search: null,
        recent_activities: null,
        promotions: null,
      },
      isLoading: false,
      notification: {
        show: false,
        title: null,
        message: null,
        type: "info",
        loading: false,
      },
      setToast: null,
      isPageReady: false,
      plans: null,
    };
  },
  methods: {
    ...mapActions([
      "SetSubscription",
      "SetSubscriptionStatus",
      "setupLoading",
      "MakeUser",
    ]),
    getViewPort() {
      return window.innerWidth;
    }, //end of getViewPort
    initSubscription(id, amount) {
      this.$router.push({
        name: "AuthPayment",
        params: { id: id, amount: amount },
      });
    }, //end of initSubscription
    getProfile() {
      this.closeToast();
      this.setToast = this.$buefy.toast.open({
        message: "Setting up user dashboard please wait . . . ",
        type: "is-success",
        position: "is-bottom",
        indefinite: false,
      });

      const config = {
        method: "get",
        url: "https://api.ejsconline.com:442/api/v_1/auth/profile",
        headers: {
          Authorization: localStorage.getItem("user_token"),
          Signature: localStorage.getItem("web_token"),
        },
      };
      axios(config)
        .then((res) => {
          this.MakeUser(res.data.success.data);
          const result = res.data.success;
          localStorage.setItem(
            "sub_message",
            `${result.data.subscription.message1}. ${result.data.subscription.message2}`
          );
          this.SetSubscription(
            `${result.data.subscription.message1}. ${result.data.subscription.message2}`
          );
          this.SetSubscriptionStatus(result.data.subscription_status);
          this.isPageReady = true;
        })
        .catch((err) => {
          this.manageLogout();
          if (
            (err.response && err.response.status == "401") ||
            err.response.data.error.message.includes("Invalid Request")
          ) {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: "Action failed, authorization required . . . ",
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
            this.manageLogout();
          } else if (err.response && err.response.status != "401") {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: err.response.data.error.message,
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
          } else if (err.request) {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: JSON.stringify(err.request.data),
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
          } else {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: err.message,
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
          }
        });
    }, //end of get profile
    manageLogout() {
      this.$store.dispatch("LogOut");
      this.$router.push("/login");
    }, //manageLogout
    closeToast() {
      if (this.setToast) {
        this.setToast.close();
        this.setToast = null;
      }
    }, //end of closeToast
    formatDate(str) {
      const output = new Date(str);
      const splitted = output.toString().split(" ");
      // return `${splitted[0]} ${splitted[1]} ${splitted[2]}, ${splitted[3]}`;
      return `${splitted[1]} ${splitted[2]}, ${splitted[3]}`;
    }, //end of formatDate
    formatSubscriptionMessage(str) {
      const splitted = str.split(" on ");
      const initial = `${splitted[0]} on`;
      const datePart = splitted[1].split(" ");
      const result = `${initial} ${datePart[0]} ${datePart[1]} ${datePart[2]}, ${datePart[3]}.`;
      return result;
    }, //end of formatSubscriptionMessage
    capitalizeFirstChar(char) {
      return `${char.charAt(0).toUpperCase()}${char.slice(1)}`;
    }, //end of capitalizeFirstChar
    formatNumber(num) {
      return `${new Intl.NumberFormat("en-US").format(num)}.00`;
    }, //end of formatNumber
    adjustCitation(name, part, start, end, volume) {
      const caseName = name.replace(/\[/g, "(").replace(/\]/g, ")");
      return `${caseName} (Vol. ${volume}) ${start}`;
      // const splitted = name.split(" ");
      // // remove last 2
      // const caseName = splitted.filter((n, i) => i < (splitted.length - 2)).join(" ");
      // return `
      //   ${caseName}
      //   [Pt. ${part}]
      //   ${start} at ${end}`;
    }, //end of 
    adjustPackage () {
      const allPlans = this.$store.getters.getSettings.plans;
      const noPromo = allPlans.filter(plan => !plan.name.toLowerCase().includes("promo"));
      const withPromo = allPlans.filter(plan => plan.name.toLowerCase().includes("promo"));
      this.plans = [...withPromo, ...noPromo];
    }, //end of adjustPackage
  },
  mounted() {
    //call getProfileMethod
    this.getProfile();
    this.adjustPackage();
  },
};
</script>

<style scoped>
@font-face {
  font-family: "MontBold";
  src: url("../fonts/Mont/Mont-Bold.otf");
}

@font-face {
  font-family: "MontRegular";
  src: url("../fonts/Mont/Mont-Regular.otf");
}

@font-face {
  font-family: "MontLight";
  src: url("../fonts/Mont/Mont-Light.otf");
}

.bold-text {
  font-family: "MontBold";
}

.regular-text {
  font-family: "MontRegular";
}

.light-text {
  font-family: "MontLight";
}

.card {
  border-radius: 20px;
}

.card-content {
  padding-left: 0;
  padding-right: 0;
}

.separator {
  border-bottom: 1px solid #dfe0eb;
  padding-left: 85px !important;
  padding-right: 85px !important;
  margin: 0;
}

.separator-t {
  border-bottom: 1px solid #dfe0eb;
  padding-left: 40px !important;
  padding-right: 40px !important;
  margin: 0;
}
</style>
