<template>
  <div class="block" :style="`background-image: url(${require('../../../assets/hero_mobile.png')})`">
    <div class="container">
      <section class="section">
        <h2 class="is-size-4 has-text-white py-6 has-text-centered heavy-text">
          Providing legal assistance to the judiciary, bar and other members of the legal community 
        </h2>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "Hero",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
