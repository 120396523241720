var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"block mb-6"},[_c('div',{staticClass:"container has-text-white"},[_c('section',{staticClass:"section"},[_c('div',{staticClass:"columns "},[(true === false)?_c('div',{staticClass:"column mx-4"},[_vm._m(0)]):_vm._e(),_c('div',{staticClass:"column my-4"},[_c('label',{staticClass:"label"},[_vm._v(" Category")]),_c('div',{staticClass:"select is-primary is-rounded"},[_c('select',{staticClass:"has-text-primary",on:{"change":_vm.categorizeProduct}},[_c('option',{attrs:{"value":"","disabled":"","selected":""}},[_vm._v("Select Product Category")]),_c('option',{attrs:{"value":"all"}},[_vm._v("All Product")]),_vm._l((_vm.categories),function(category,index){return _c('option',{key:index,domProps:{"value":category.id}},[_vm._v(_vm._s(category.name)+" ("+_vm._s(category.product_counts)+")")])})],2)])]),_c('div',{staticClass:"column is-8"},[_c('div',{staticClass:"columns is-mobile"},_vm._l((_vm.productsA),function(product){return _c('div',{key:product.id,staticClass:"column has-text-centered mx-3"},[_c('router-link',{attrs:{"to":{
                  name: 'OneItem',
                  params: {
                    product: product,
                    item_name: product.name,
                    item_id: product.id,
                  },
                }}},[_c('figure',{staticClass:"image"},[_c('img',{attrs:{"src":product.image,"alt":product.category_name}})]),_c('p',{staticClass:"has-text-centered has-text-primary mt-3"},[_c('span',{staticClass:"bold-text"},[_vm._v(_vm._s(product.name))]),_c('br'),_c('span',{staticClass:"heavy-text is-size-4"},[_vm._v("₦"+_vm._s(_vm.formatNumber(product.unit_price)))])])])],1)}),0),_c('div',{staticClass:"columns is-mobile"},_vm._l((_vm.productsB),function(product){return _c('div',{key:product.id,staticClass:"column has-text-centered mx-3"},[_c('router-link',{attrs:{"to":{
                  name: 'OneItem',
                  params: {
                    product: product,
                    item_name: product.name,
                    item_id: product.id,
                  },
                }}},[_c('figure',{staticClass:"image"},[_c('img',{attrs:{"src":product.image,"alt":product.category_name}})]),_c('p',{staticClass:"has-text-centered has-text-primary mt-3"},[_c('span',{staticClass:"bold-text"},[_vm._v(_vm._s(product.name))]),_c('br'),_c('span',{staticClass:"heavy-text is-size-4"},[_vm._v("₦"+_vm._s(_vm.formatNumber(product.unit_price)))])])])],1)}),0),_c('div',{staticClass:"columns is-mobile"},_vm._l((_vm.productsC),function(product){return _c('div',{key:product.id,staticClass:"column has-text-centered mx-3"},[_c('router-link',{attrs:{"to":{
                  name: 'OneItem',
                  params: {
                    product: product,
                    item_name: product.name,
                    item_id: product.id,
                  },
                }}},[_c('figure',{staticClass:"image"},[_c('img',{attrs:{"src":product.image,"alt":product.category_name}})]),_c('p',{staticClass:"has-text-centered has-text-primary mt-3"},[_c('span',{staticClass:"bold-text"},[_vm._v(_vm._s(product.name))]),_c('br'),_c('span',{staticClass:"heavy-text is-size-4"},[_vm._v("₦"+_vm._s(_vm.formatNumber(product.unit_price)))])])])],1)}),0),_c('div',{staticClass:"columns is-mobile"},_vm._l((_vm.productsD),function(product){return _c('div',{key:product.id,staticClass:"column has-text-centered mx-3"},[_c('router-link',{attrs:{"to":{
                  name: 'OneItem',
                  params: {
                    product: product,
                    item_name: product.name,
                    item_id: product.id,
                  },
                }}},[_c('figure',{staticClass:"image"},[_c('img',{attrs:{"src":product.image,"alt":product.category_name}})]),_c('p',{staticClass:"has-text-centered has-text-primary mt-3"},[_c('span',{staticClass:"bold-text"},[_vm._v(_vm._s(product.name))]),_c('br'),_c('span',{staticClass:"heavy-text is-size-4"},[_vm._v("₦"+_vm._s(_vm.formatNumber(product.unit_price)))])])])],1)}),0),_c('div',{staticClass:"columns is-mobile"},_vm._l((_vm.productsE),function(product){return _c('div',{key:product.id,staticClass:"column has-text-centered mx-3"},[_c('router-link',{attrs:{"to":{
                  name: 'OneItem',
                  params: {
                    product: product,
                    item_name: product.name,
                    item_id: product.id,
                  },
                }}},[_c('figure',{staticClass:"image"},[_c('img',{attrs:{"src":product.image,"alt":product.category_name}})]),_c('p',{staticClass:"has-text-centered has-text-primary mt-3"},[_c('span',{staticClass:"bold-text"},[_vm._v(_vm._s(product.name))]),_c('br'),_c('span',{staticClass:"heavy-text is-size-4"},[_vm._v("₦"+_vm._s(_vm.formatNumber(product.unit_price)))])])])],1)}),0)])]),_c('div',{staticClass:"columns my-6 py-6"},[_c('div',{staticClass:"column is-4"}),_c('div',{staticClass:"column is-8 mx-6 px-6"},[_c('div',{staticClass:"buttons mx-6 px-6"},[_c('button',{class:("button is-primary is-inverted mx-1 " + (_vm.presentPage == 1 ? 'btn-disabled' : '')),attrs:{"disabled":_vm.presentPage == 1 ? true : false},on:{"click":function($event){return _vm.managePagination(_vm.presentPage - 1)}}},[_vm._m(1),_c('span',[_vm._v("Prev")])]),_vm._l((_vm.totalPages),function(nums){return _c('button',{class:("button is-primary is-inverted " + (nums == _vm.presentPage ? 'btn-current-page' : '')),on:{"click":function($event){return _vm.managePagination(nums)}}},[_vm._v(" "+_vm._s(nums)+" ")])}),_c('button',{class:("button is-primary is-inverted mx-1 " + (_vm.presentPage == _vm.totalPages ? 'btn-disabled' : '')),attrs:{"disabled":_vm.presentPage == _vm.totalPages ? true : false},on:{"click":function($event){return _vm.managePagination(_vm.presentPage + 1)}}},[_c('span',[_vm._v("Next")]),_vm._m(2)])],2)])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"control has-icons-right"},[_c('input',{staticClass:"input is-primary has-text-primary is-rounded",attrs:{"type":"text","placeholder":"Search Books"}}),_c('span',{staticClass:"icon is-right"},[_c('i',{staticClass:"fas fa-search has-text-primary pr-6"})])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"icon"},[_c('i',{staticClass:"fas fa-arrow-left"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"icon"},[_c('i',{staticClass:"fas fa-arrow-right"})])}]

export { render, staticRenderFns }