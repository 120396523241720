<template>
  <div
    class="block pt-6"
    style="padding: 0 10%; background-color: #F8F9FD; margin-top: -100px;"
  >
    <div class="container mt-6">
      <section class="section">
        <h3 class="bold-title has-text-info is-size-3 has-text-centered">
          Recent Publications
        </h3>
        <div class="columns mt-6 mb-6">
          <div
            class="column p-6 "
            style="border: 1px solid #ECEFF4;"
            v-for="product in allProducts"
            :key="product.id"
          >
            <div class="">
              <router-link
                :to="{
                  name: 'OneItem',
                  params: {
                    product: product,
                    item_name: product.name,
                    item_id: product.id,
                  },
                }"
              >
                <figure class="image" @click="$router.push()">
                  <img :src="product.image" :alt="product.category_name" />
                </figure>
                <p class="has-text-primary bold-text has-text-left my-2">
                  {{ product.name }} <br />
                  <span class="regular-text" style="color: #7C8087;"
                    >₦{{ formatNumber(product.unit_price) }}</span
                  >
                </p>
              </router-link>
            </div>
          </div>
        </div>
        <div class="columns has-text-centered">
          <div class="column">
            <b-button
              rounded
              class="is-primary px-6"
              @click="$router.push('/shop')"
              >Shop More</b-button
            >
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "RecentPublications",
  data() {
    return {
      allProducts: [],
      setToast: null,
    };
  },
  methods: {
    getProducts() {
      const config = {
        method: "get",
        url:
          "https://api.ejsconline.com:442/api/v_1/shop/recent-products?size=4",
      };
      axios(config)
        .then((response) => {
          const result = response.data;
          this.allProducts = result.data;
          // this.allProducts = this.allProducts.filter((prod, index) => index < 5);
        })
        .catch((err) => {
          if (err.response.status == "401") {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: "Action failed, authorization required . . . ",
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
            this.manageLogout();
          }
          if (err.response) {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: err.response.data.error.message,
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
          } else if (err.request) {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: JSON.stringify(err.request.data),
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
          } else {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: err.message,
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
          }
        });
    }, //end of getProduct
    manageLogout() {
      this.$store.dispatch("LogOut");
      this.$router.push("/login");
    }, //manageLogout
    closeToast() {
      if (this.setToast) {
        this.setToast.close();
        this.setToast = null;
      }
    }, //end of closeToast
    formatNumber(num) {
      return `${new Intl.NumberFormat("en-US").format(num)}.00`;
    }, //end of formatNumber
  },
  mounted() {
    this.getProducts();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@font-face {
  font-family: "MontBold";
  src: url("../../fonts/Mont/Mont-Bold.otf");
}

@font-face {
  font-family: "MontHeavy";
  src: url("../../fonts/Mont/Mont-Heavy.otf");
}

.bold-title {
  font-family: "MontBold";
}

.heavy-text {
  font-family: "MontHeavy";
}
</style>
