<template>
  <div class="block" style="padding: 0 10%;">
    <div class="container">
      <section class="section mt-6">
        <div class="columns is-desktop has-text-primary">
          <div class="column">
            <img alt="Team" src="../../assets/teams/isaac_1.png" />
          </div>
          <div class="column is-three-fifths ml-5 pl-5">
            <h3 class="is-size-3 bold-text mt-3 pt-5"><b>Isaac Ogbah</b></h3>
            <p class="regular-text has-text-primary mt-5">
              Isaac Ogbah is the Editor-in-Chief of Erudite Judgments of the Supreme Court (EJSC). <br />
              Aside his involvement in EJSC he is equally the Principal Partner in the Law firm of OGBAH ISAAC &amp; CO. <br />
              He graduated from Nnamdi Azikiwe University, Awka in 1999 with LL.B(Hons) and was admitted to the Nigerian Bar in 2001. He has had extensive legal practice in seasoned law firms and with seasoned and erudite legal practitioners including but not limited to A.O. Akanle & Co. (SAN), Punuka Chambers (Idigbe & Idigbe SAN), and Abalu, Bogba &Co. from where he proceeded to establish his private law practice in June 2006.
            </p>
          </div>
        </div>

        <div class="columns is-desktop has-text-primary">
          <div class="column is-three-fifths ml-5 pl-5">
            <h3 class="is-size-3 bold-text mt-3 pt-5"><b>AREAS OF EXPERTISE</b></h3>
            <p class="regular-text has-text-primary mt-5">
              He is highly knowledgeable in the area of insurance, investment Law, Trademarks and Intellectual Property, Secured Credit Transaction, Corporative Law, International Economic Law and Comparative conflicts of Laws, Property Law practice. <br />
              He has a flair for Advocacy, Solicitors’ Practice, Perfection of Legal Titles and Debt Recovery to mention but a few.
            </p>
          </div>
          <div class="column">
            <img alt="Team" src="../../assets/teams/isaac_2.png" />
          </div>
        </div>

        <div class="columns is-desktop has-text-primary">
          <div class="column">
            <img alt="Team" src="../../assets/teams/isaac_3.png" />
          </div>
          <div class="column is-three-fifths ml-5 pl-5">
            <h3 class="is-size-3 bold-text mt-3 pt-5"><b>PROFESSIONAL MEMBERSHIPS</b></h3>
            <p class="regular-text has-text-primary mt-5">
              Member, Nigerian Bar Association <br />
              Member, Chattered Institute of Arbitrators (UK) <br />
              Member, Commonwealth Lawyers Association <br />
              Chairman Emeritus NBA, Ota Bar Fellow, Institute of Chattered Mediators and Concilliators<br />
              Member, NBA TCCP,2019 <br />
              Member, NBA, Human Rights Commitee <br />
              Member, African Bar Association <br />
              Notary Public, Federal Republic of Nigeria 2016 <br />
              Member, Section on Public Interest and Development, Nigerian Bar Association <br />
              Member, Section on Legal Practice, Nigerian Bar Association
            </p>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "TeamInfo",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  @font-face {
    font-family: "MontBold";
    src: url("../../fonts/Mont/Mont-Bold.otf");
  }

  @font-face {
    font-family: "MontRegular";
    src: url("../../fonts/Mont/Mont-Regular.otf");
  }

  .bold-text {
    font-family: "MontBold";
  }

  .regular-text {
    font-family: "MontRegular";
  }
</style>
