<template>
  <div class="dashboard_home" style="background-color: #F5F5FD;">
    <!--sidebar-->
    <Sidebar />
    <!--footer-->
    <span v-if="getViewPort() >= 1000"></span>
    <Footer class="footer-width is-hidden-desktop" v-else />
  </div>
</template>

<script>

  import Sidebar from '../components/DashboardComponent/Sidebar'
  import Footer from "../components/mobile/DashboardComponent/Footer";

  export default {
    name: 'UserAccount',

    components: {
      Sidebar,
      Footer,
    },
    methods: {
      getViewPort() {
        return (window.innerWidth);
      }, //end of getViewPort
    },
  }
</script>

<style scoped>
  .footer-width {
    position: fixed; 
    bottom: 0; 
    width: 100%; 
    width: -webkit-fill-available; 
    width: -moz-available; 
    width: stretch; 
    padding: 10px;
  }
</style>
