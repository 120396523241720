<template>
  <div class="form-input-holder">
    <div class="has-text-centered">
      <img src="../../../assets/onboarding2.png" alt="Onboarding Progress" /> <br />
      <img src="../../../assets/EJSClogo2_new.png" width="65" class="mt-6" alt="EJSC Logo" />
      <h3 class="is-size-4 has-text-primary mt-5 heavy-text"><b>Let's get you Secured</b></h3>
      <p class="content has-text-primary">
        Enter your preferred password
      </p>
    </div>

    <div class="field mt-6">
      <b-field label="Password" custom-class="has-text-primary">
        <b-input type="password"
          rounded
          custom-class="input"
          v-model="form.password"
          password-reveal>
        </b-input>
      </b-field>
    </div>

    <div class="field mt-5">
      <b-field label="Confirm Password" custom-class="has-text-primary">
        <b-input type="password"
          rounded
          custom-class="input"
          v-model="form.confirm_password"
          password-reveal>
        </b-input>
      </b-field>
    </div>

    <b-button
      type="is-primary"
      size="is-medium"
      class="is-rounded mt-6 login-button"
      expanded @click="checkUp"
      >Finish</b-button
    >

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import axios from "axios";
export default {
  name: "OnboardingPassword",
  data() {
    return {
      form: {
        firstname: null,
        lastname: null,
        email: null,
        mobile: null,
        password: null,
        confirm_password: null,
      },
      showError: false,
      isLoading: false,
      notification: {
        show: false,
        title: null,
        message: null,
        type: "info",
        loading: false,
      },
      setToast: null,
    }
  },
  methods: {
    ...mapGetters(["getSignup"]),
    ...mapActions(['SignUp', 'Toaster', 'setupLoading']),
    checkUp(){
      if (!this.form.password) {
        this.closeToast();
        this.setToast = this.$buefy.toast.open({
          message: "Password is required!",
          type: "is-warning",
          position: "is-bottom",
          duration: 3000,
        });
        return false
      } else if (!this.form.confirm_password) {
        this.closeToast();
        this.setToast = this.$buefy.toast.open({
          message: "Confirm Password is required!",
          type: "is-warning",
          position: "is-bottom",
          duration: 3000,
        });
        return false
      } else if (this.form.password !== this.form.confirm_password) {
        this.closeToast();
        this.setToast = this.$buefy.toast.open({
          message: "Password is different from confirm password!",
          type: "is-warning",
          position: "is-bottom",
          duration: 3000,
        });
        return false
      } else if (this.form.password.length < 5) {
        this.closeToast();
        this.setToast = this.$buefy.toast.open({
          message: "Password is not up to 6 characters!",
          type: "is-warning",
          position: "is-bottom",
          duration: 3000,
        });
        return false
      }
      this.proceed();
    }, //end  of checkUp
    proceed () {
      this.setupLoading(true);
      this.closeToast();
      this.setToast = this.$buefy.toast.open({
        message: "Registering user please wait . . . ",
        type: "is-success",
        position: "is-bottom",
        indefinite: true,
      });
      this.SignUp({...this.form});
      this.submit();
    }, //end of proceed 
    async submit() {
      axios
        .post("api/v_1/register", this.form)
        .then((response) => {
          // console.log(res)
          this.setupLoading(false);
          this.closeToast();
          this.setToast = this.$buefy.toast.open({
            message: `${response.data.success.message}`,
            type: "is-success",
            position: "is-bottom",
            duration: 3000,
          });
          this.$router.push({
            name: "OnboardingVerify",
          });
        })
        .catch((err) => {
          this.setupLoading(false);
          if (err.response.status == "401") {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: "Action failed, authorization required . . . ",
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
            this.manageLogout();
          }
          if (err.response) {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: err.response.data.error.message,
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
            if (
              err.response.data.error.message.toLowerCase().includes("email") ||
              err.response.data.error.message.toLowerCase().includes("phone")
            ) {
              this.$router.push({
                name: "OnboardingContact",
              })
            }
          } else if (err.request) {
            // console.log(err.request);
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: JSON.stringify(err.request.data),
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
          } else {
            // console.log(err);
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: err.message,
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
          }
        });
    }, //end of submit
    closeToast(){
      if (this.setToast) {
        this.setToast.close();
        this.setToast = null;
      }
    }, //end of closeToast
    manageLogout(){
      this.$store.dispatch("LogOut");
      this.$router.push("/login");
    }, //manageLogout
  },
  mounted() {
    this.form = {...this.getSignup()};
    if (
      !this.getSignup()
    ) this.$router.go(-1);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.form-input-holder {
  margin: 0 59px;
}

input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(13, 35, 51, 0.69);
  opacity: 1; /* Firefox */
}
input {
  background: rgba(196, 196, 196, 0.17);
  border: none;
}
.login-button:hover {
  filter: drop-shadow(0 0 10px #003b56);
}

</style>
