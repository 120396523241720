<template>
  <div>
    <div class="is-hidden-touch" v-if="getViewPort() >= 1000">
      <div class="container my-6 mx-6">
        <Header />
        <b-progress
          v-if="!isPageReady"
          type="is-primary"
          class="mt-4"
        ></b-progress>
        <div class="columns has-text-primary" v-if="isPageReady">
          <div class="column">
            <div class="mb-5 columns">
              <div class="column bold-text is-size-5">
                <b
                  >Search results for <i>"{{ searchQuery }}"</i></b
                >
              </div>
              <div
                class="column has-text-right bold-text is-size-5"
                v-if="searchResult.length > 0"
              >
                <div>
                  <a
                    class="has-text-primary button"
                    @click="isImageModalActive = true"
                  >
                    <span class="icon is-small"
                      ><i class="fas fa-sliders-h" aria-hidden="true"></i
                    ></span>
                    <span class="ml-4">Filter</span>
                  </a>
                </div>
              </div>
            </div>

            <div class="columns" v-if="searchResult.length > 0">
              <div class="column">
                <div class="card pb-6" style="background-color: #f5f5fd;">
                  <div
                    class="tabs is-toggled is-boxed has-background-primary"
                  >
                    <ul style="border-bottom-color: white !important;">
                      <li
                        :class="`${all_} ${all_ ? 'tab-list' : ''}`"
                        @click="toAll"
                        v-if="true === false"
                      >
                        <a
                          :class="
                            `tab-link has-text-${
                              all_ ? 'primary' : 'white'
                            } px-6`
                          "
                          >All</a
                        >
                      </li>
                      <li
                        :class="`${principle_} ${principle_ ? 'tab-list' : ''}`"
                        @click="toPrinciples"
                      >
                        <a
                          :class="
                            `tab-link has-text-${
                              principle_ ? 'primary' : 'white'
                            } px-4`
                          "
                          >Ratios</a
                        >
                      </li>
                      <li
                        :class="`${case_} ${case_ ? 'tab-list' : ''}`"
                        @click="toCases"
                      >
                        <a
                          :class="
                            `tab-link has-text-${
                              case_ ? 'primary' : 'white'
                            } px-5`
                          "
                          >All Sections</a
                        >
                      </li>
                    </ul>
                  </div>

                  <div
                    class="columns px-5 py-2 separator"
                    v-for="(search, index) in searchResult"
                    :key="index"
                  >
                    <div class="column has-text-primary has-text-justified">
                      <div class="columns">
                        <div class="column">
                          <p class="bold-text is-size-5 my-2 is-clickable" @click="$router.push(
                              `/home/load-case/${adjustCitation(
                                search.title,
                                search.citation
                              ).trim()}/${search.id}`
                            )">{{ search.title }}</p>
                        </div>
                        <div class="column is-one-third">
                          <p class="bold-text has-text-right my-2">{{ search.citation }}</p>
                        </div>
                      </div>
                      <p
                        class="light-text is-size-6"
                        v-html="
                          /*formatResult(search.content)*/ search.content.substring(
                            0,
                            500
                          ) + '...'
                        "
                      ></p>
                      <br />
                      <div class="columns">
                        <div class="column has-text-right">
                          <button class="button is-primary is-small" @click="$router.push(
                              `/home/load-case/${adjustCitation(
                                search.title,
                                search.citation
                              ).trim()}/${search.id}`
                            )">Read more</button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="columns mt-6">
                    <div class="column has-text-right">
                      <nav
                        class="pagination is-small is-right mr-6 pr-5"
                        role="navigation"
                        aria-label="pagination"
                      >
                        <ul class="pagination-list">
                          <li>
                            <router-link
                              :to="
                                `/home/search-result?query=${filters.q}${
                                  pagination.previous_page
                                    ? '&page=' + pagination.previous_page
                                    : '&page=1'
                                }`
                              "
                              class="pagination-link"
                              aria-label="Goto page previous"
                              style="border: none;"
                            >
                              <span class="icon is-right">
                                <i
                                  class="fas fa-angle-left has-text-primary"
                                ></i>
                              </span>
                            </router-link>
                          </li>
                          <h5
                            class="is-size-6 mx-3 has-text-primary heavy-text"
                          >
                            {{ pagination.from }} - {{ pagination.to }} of
                            {{ pagination.total }}
                          </h5>
                          <!--
                                                    <li><a class="pagination-link is-current has-background-primary has-text-white" aria-label="Goto page 1">1</a></li>
                                                    <li><span class="pagination-ellipsis">&hellip;</span></li>
                                                    <li><a class="pagination-link has-text-primary" aria-label="Goto page 45">45</a></li>
                                                    <li><a class="pagination-link has-text-primary" aria-label="Page 46">46</a></li>
                                                    <li><a class="pagination-link has-text-primary" aria-label="Goto page 47">47</a></li>
                                                    <li><span class="pagination-ellipsis">&hellip;</span></li>
                                                    <li><a class="pagination-link has-text-primary" aria-label="Goto page 86">86</a></li>
                                                    -->
                          <li>
                            <router-link
                              :to="
                                `/home/search-result?query=${filters.q}${
                                  pagination.next_page
                                    ? '&page=' + pagination.next_page
                                    : '&page=' + pagination.current_page
                                }`
                              "
                              class="pagination-link"
                              aria-label="Goto page next"
                              style="border: none;"
                            >
                              <span class="icon is-right">
                                <i
                                  class="fas fa-angle-right has-text-primary"
                                ></i>
                                <i
                                  class="fas fa-angle-right has-text-primary"
                                ></i>
                              </span>
                            </router-link>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>

              <b-modal v-model="isImageModalActive">
                <div class="card" style="max-width: 50%; margin: 0 auto;">
                  <div class="card-content">
                    <div class="content has-text-primary p-6">
                      <p><b>Filter</b></p>
                      <div class="control">
                        <label class="radio my-2">
                          <input
                            type="radio"
                            name="set-filter"
                            value="date"
                            v-model="selected_filter"
                          />
                          Date
                        </label>
                        <br />
                        <div class="inline">
                          <b-field label="Start Date:">
                            <b-datepicker
                              v-model="start_date"
                              placeholder="Click to select..."
                              icon="calendar-today"
                              locale="en-CA"
                              trap-focus
                            >
                            </b-datepicker>
                          </b-field>
                        </div>
                        <div class="inline">
                          <b-field label="End Date:">
                            <b-datepicker
                              v-model="end_date"
                              placeholder="Click to select..."
                              icon="calendar-today"
                              locale="en-CA"
                              trap-focus
                            >
                            </b-datepicker>
                          </b-field>
                        </div>
                        <!--<label class="radio my-2">
                                                    <input type="radio" name="set-filter" value="alphabet" v-model="selected_filter">
                                                    Alphabet
                                                </label> <br />-->
                        <label class="radio my-2">
                          <input
                            type="radio"
                            value="asc"
                            name="set-filter"
                            v-model="selected_filter"
                          />
                          Ascending
                        </label>
                        <br />
                        <label class="radio my-2">
                          <input
                            type="radio"
                            value="desc"
                            name="set-filter"
                            v-model="selected_filter"
                          />
                          Descending
                        </label>
                        <br />
                        <label class="radio my-2">
                          <input
                            type="radio"
                            name="set-filter"
                            value="recent"
                            v-model="selected_filter"
                          />
                          Recent
                        </label>
                        <br />
                        <b-button
                          type="is-primary"
                          class="is-rounded mt-3"
                          @click="updateFilter"
                          >Save Changes</b-button
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </b-modal>
            </div>

            <div class="columns" v-else>
              <div class="column">
                <div class="card pb-6">
                  <div class="card-content has-text-primary is-size-4 p-6">
                    No result for <i>"{{ searchQuery }}"</i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="is-hidden-desktop" v-else>
      <SearchResultMobile />
    </div>
  </div>
</template>

<script>
// <mark /> bG #C89931
import Header from "../components/DashboardComponent/Header";
import SearchResultMobile from "../pages_mobile/SearchResult";
import axios from "axios";

export default {
  name: "SearchResult",
  components: {
    Header,
    SearchResultMobile,
  },
  data() {
    return {
      isImageModalActive: false,
      searchResult: [],
      searchQuery: null,
      totalResult: null,
      currentPage: null,
      nextPage: null,
      lastPage: null,
      firstPage: null,
      prevPage: null,
      isLoading: false,
      notification: {
        show: false,
        title: null,
        message: null,
        type: "info",
        loading: false,
      },
      setToast: null,
      filters: {
        q: this.$route.query.query,
        // m: 'best_fields',
        m: this.$route.query.query_type == "exact" ? "phrase" : "best_fields",
        s: "default",
        t: "principle",
        pg: this.$route.query.page ?? "1",
        s_dt: "1999-05-12",
        e_dt: this.todaysDate(),
      },
      all_: null,
      case_: null,
      principle_: "is-active",
      pagination: {
        total: 0,
        per_page: 10,
        current_page: 1,
        last_page: null,
        from: 0,
        to: 0,
        previous_page: null,
        next_page: null,
      },
      isPageReady: false,
      start_date: null,
      end_date: null,
      selected_filter: null,
    };
  },
  methods: {
    getViewPort() {
      return window.innerWidth;
    }, //end of getViewPort
    todaysDate() {
      const d = new Date();
      const month = (d.getMonth().toString().length < 2) ? `0${d.getMonth()}` : d.getMonth();
      const day = (d.getDate().toString().length < 2) ? `0${d.getDate()}` : d.getDate();
      return `${d.getFullYear()}-${month}-${day}`;
    }, //end of todaysDate()
    arrangeResult() {}, //end of arrangeResult

    performSearch() {
      this.isPageReady = false;
      this.closeToast();
      this.setToast = this.$buefy.toast.open({
        message: `Searching for ${this.filters.q} please wait . . . `,
        type: "is-success",
        position: "is-bottom",
        indefinite: false,
      });
      const config = {
        method: "get",
        url: "https://api.ejsconline.com:442/api/v_1/auth/search",
        headers: {
          Authorization: localStorage.getItem("user_token"),
          Signature: localStorage.getItem("web_token"),
        },
        params: { ...this.filters },
      };
      axios(config)
        .then((res) => {
          setTimeout(() => {
            this.isPageReady = true;
          }, 1000);
          this.closeToast();
          const result = res.data;
          const lastPage_ = Math.ceil(result.count / result.per_page);
          this.pagination = {
            total: result.count,
            per_page: result.per_page,
            current_page: result.current_page,
            last_page: lastPage_,
            from: (result.current_page - 1) * result.per_page + 1,
            to:
              result.count < result.current_page * result.per_page
                ? result.count
                : result.current_page * result.per_page,
            previous_page:
              result.current_page > 1 ? result.current_page - 1 : null,
            next_page:
              result.current_page < lastPage_ ? result.current_page + 1 : null,
          };
          this.searchResult = [...result.data];
          if (this.searchResult.length === 0) {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: `No result for ${this.filters.q} . . . `,
              type: "is-warning",
              position: "is-bottom",
              indefinite: false,
            });
          }
          this.isPageReady = true;
        })
        .catch((err) => {
          setTimeout(() => {
            this.isPageReady = true;
          }, 1000);
          if (err.response && err.response.status == "401") {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: "Action failed, authorization required . . . ",
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
            this.manageLogout();
          } else if (err.response && err.response.status != "401") {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: err.response.data.error.message,
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
          } else if (err.request) {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: JSON.stringify(err.request.data),
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
          } else {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: err.message,
              type: "is-danger",
              position: "is-bottom",
              duration: 3000,
            });
          }
        });
    }, //end of performSearch
    manageLogout() {
      this.$store.dispatch("LogOut");
      this.$router.push("/login");
    }, //manageLogout
    closeToast() {
      if (this.setToast) {
        this.setToast.close();
        this.setToast = null;
      }
    }, //end of closeToast
    toAll() {
      if (!this.case_) {
        this.all_ = "is_active";
        this.case_ = null;
        (this.principle_ = null),
          (this.filters = {
            q: this.$route.query.query,
            m: "phrase",
            // m: 'best_fields',
            s: "default",
            t: "case",
            pg: "1",
            s_dt: "1999-05-12",
            e_dt: this.todaysDate(),
          });
        this.performSearch();
      }
    }, //end of toAll
    toCases() {
      if (!this.case_) {
        this.all_ = null;
        this.case_ = "is-active";
        (this.principle_ = null),
          (this.filters = {
            q: this.$route.query.query,
            m: "phrase",
            // m: 'best_fields',
            s: "default",
            t: "case",
            pg: "1",
            s_dt: "1999-05-12",
            e_dt: this.todaysDate(),
          });
        this.performSearch();
      }
    }, //end of toCases
    toPrinciples() {
      if (!this.principle_) {
        this.all_ = null;
        this.principle_ = "is-active";
        (this.case_ = null),
          (this.filters = {
            q: this.$route.query.query,
            m: "phrase",
            // m: 'best_fields',
            s: "default",
            t: "principle",
            pg: "1",
            s_dt: "1999-05-12",
            e_dt: this.todaysDate(),
          });
        this.performSearch();
      }
    }, //end of toPrinciples
    updateFilter() {
      this.isImageModalActive = false;
      switch (this.selected_filter) {
        case "date":
          if (!this.start_date && !this.end_date) {
            this.closeToast();
            this.setToast = this.$buefy.toast.open({
              message: "Select start and end date to filter by date",
              type: "is-warning",
              position: "is-bottom",
              duration: 3000,
            });
            return false;
          }
          this.filters.s_dt = new Intl.DateTimeFormat("en-CA").format(
            this.start_date
          );
          this.filters.e_dt = new Intl.DateTimeFormat("en-CA").format(
            this.end_date
          );
          this.performSearch();
          break;
        case "alphabet":
          this.filters.s = "asc";
          this.performSearch();
          break;
        case "asc":
          this.filters.s = "asc";
          this.performSearch();
          break;
        case "desc":
          this.filters.s = "desc";
          this.performSearch();
          break;
        case "recent":
          const dateInstance = new Date();
          this.filters.s_dt = `${dateInstance.getFullYear() -
            10}-${dateInstance.getMonth()}-${dateInstance.getDate()}`;
          this.filters.e_dt = this.todaysDate();
          this.performSearch();
          break;
        default:
          this.closeToast();
          this.setToast = this.$buefy.toast.open({
            message: "Select a filter parameter to continue",
            type: "is-danger",
            position: "is-bottom",
            duration: 3000,
          });
          break;
      }
    }, //end of updateFilter
    formatResult(content) {
      const splitted = content.replace(/.\n/g, " ").split(". ");
      return splitted
        .filter((par) => par.includes("background-color"))
        .join(". ");
    }, //end of formatResult()
    adjustCitation(content, citation) {
      const splitted = content.split(/\[/g);
      return `${splitted[0]} ${citation}`;
    }, //end of adjustCitation
  },
  mounted() {
    const mQuery = this.$route.query.query;
    if (!mQuery) this.$router.push({ name: "AuthHome" });
    this.searchQuery = mQuery;
    this.performSearch();
  },
};
</script>
<style scoped>
@font-face {
  font-family: "MontBold";
  src: url("../fonts/Mont/Mont-Bold.otf");
}

@font-face {
  font-family: "MontHeavy";
  src: url("../fonts/Mont/Mont-Heavy.otf");
}

@font-face {
  font-family: "MontRegular";
  src: url("../fonts/Mont/Mont-Regular.otf");
}

@font-face {
  font-family: "MontLight";
  src: url("../fonts/Mont/Mont-Light.otf");
}

.heavy-text {
  font-family: "MontHeavy";
}

.bold-text {
  font-family: "MontBold";
}

.regular-text {
  font-family: "MontRegular";
}

.light-text {
  font-family: "MontLight";
}

.card {
  border-radius: 20px;
}

.card-content {
  padding-left: 0;
  padding-right: 0;
}
.separator {
  border-bottom: 0.5px solid #003b56;
  padding-left: 45px !important;
  padding-right: 45px !important;
  margin: 0;
}

.tab-list {
  background-color: white !important;
}

.tab-link {
  border-radius: 0 !important;
  border-color: transparent white transparent transparent !important;
  margin: 5px 0;
}
</style>
