<template>
  <div class="block mt-6">
    <div class="container">
      <section class="section">
        <h2 class="is-size-3 has-text-white has-text-centered py-5 my-6">
          <b class="bold-text">{{page}}</b>
        </h2>
      </section>
      <div class="columns my-4" style="padding-right: 10%;">
        <div class="column has-text-primary has-text-right bold-text is-size-4 is-clickable">
          <div @click="$router.go(-1)">
            <i class="fa fa-arrow-left mr-4"></i> Back
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GeneralHero",
  props: {
    page: {type: String, default: "About"},
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  @font-face {
    font-family: "MontBold";
    src: url("../../../fonts/Mont/Mont-Bold.otf");
  }

  .bold-text {
    font-family: "MontBold";
  }

  .section {
    background-image: url("../../../assets/hero_bg_mobile.png");
  }
</style>
