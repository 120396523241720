var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"block pt-6",staticStyle:{"margin-top":"-50px"}},[_c('div',{staticClass:"container"},[_c('section',{staticClass:"section"},[_c('h3',{staticClass:"bold-title has-text-info has-text-centered is-size-3"},[_vm._v(" Related Publications ")]),_c('div',{staticClass:"columns mt-6 mb-3"},_vm._l((_vm.allProducts),function(product){return _c('div',{key:product.id,staticClass:"column mx-2"},[_c('div',{staticClass:"mx"},[_c('router-link',{attrs:{"to":{
                name: 'OneItem',
                params: {
                  product: product,
                  item_name: product.name,
                  item_id: product.id,
                },
              }}},[_c('figure',{staticClass:"image",on:{"click":function($event){return _vm.$router.push()}}},[_c('img',{attrs:{"src":product.image,"alt":product.category_name}})]),_c('p',{staticClass:"has-text-primary has-text-centered my-2"},[_vm._v(" "+_vm._s(product.name)+" "),_c('br'),_c('span',{staticClass:"heavy-text"},[_vm._v("₦"+_vm._s(_vm.formatNumber(product.unit_price)))])])])],1)])}),0)])])])}
var staticRenderFns = []

export { render, staticRenderFns }